import React from 'react'
import PropTypes from 'prop-types'
import { Card, CircularProgress, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Amount from 'components/Amount'
import Pupils from 'components/Pupils'

const useStyles = makeStyles((theme) => ({
  results: {
    marginTop: theme.spacing(3),
  },
  item: {
    padding: theme.spacing(3),
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      '&:not(:last-of-type)': {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
    [theme.breakpoints.up('sm')]: {
      '&:not(:last-of-type)': {
        borderRight: `1px solid ${theme.palette.divider}`,
      },
    },
  },
  valueContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

const SearchSummaryBanner = (props) => {
  const { loading, enrollments, payments, ...rest } = props
  const classes = useStyles()

  const getEstimatedStudentCount = (enrollments) => {
    return enrollments
      ? enrollments.reduce((acc, cur) => {
          return cur && cur.estStudentCount ? acc + cur.estStudentCount : acc
        }, 0)
      : 0
  }

  const getEstimatedTeacherCount = (enrollments) => {
    return enrollments
      ? enrollments.reduce((acc, cur) => {
          return cur && cur.estTeacherCount ? acc + cur.estTeacherCount : acc
        }, 0)
      : 0
  }

  const getAmountOfPayments = (payments) => {
    return payments
      ? payments.reduce((acc, cur) => {
          return cur && cur.amount ? acc + cur.amount : acc
        }, 0)
      : 0
  }

  return (
    <Card {...rest}>
      <Grid alignItems="center" container justifyContent="space-between">
        <Grid className={classes.item} item md={3} sm={6} xs={12}>
          <Typography component="h2" gutterBottom variant="overline">
            Antall påmeldinger
          </Typography>
          <div className={classes.valueContainer}>
            {loading ? (
              <CircularProgress size={20} />
            ) : (
              <Typography variant="h3">{enrollments ? enrollments.length : 0}</Typography>
            )}
          </div>
        </Grid>
        <Grid className={classes.item} item md={3} sm={6} xs={12}>
          <Typography component="h2" gutterBottom variant="overline">
            Antall påmeldte elever
          </Typography>
          <div className={classes.valueContainer}>
            {loading ? (
              <CircularProgress size={20} />
            ) : (
              <Typography variant="h3">
                <Pupils>{getEstimatedStudentCount(enrollments)}</Pupils>
              </Typography>
            )}
          </div>
        </Grid>
        <Grid className={classes.item} item md={3} sm={6} xs={12}>
          <Typography component="h2" gutterBottom variant="overline">
            Antall påmeldte lærere
          </Typography>
          <div className={classes.valueContainer}>
            {loading ? (
              <CircularProgress size={20} />
            ) : (
              <Typography variant="h3">
                <Pupils>{getEstimatedTeacherCount(enrollments)}</Pupils>
              </Typography>
            )}
          </div>
        </Grid>
        <Grid className={classes.item} item md={3} sm={6} xs={12}>
          <Typography component="h2" gutterBottom variant="overline">
            Innsamlet beløp
          </Typography>
          <div className={classes.valueContainer}>
            {loading ? (
              <CircularProgress size={20} />
            ) : (
              <Typography variant="h3">
                <Amount>{getAmountOfPayments(payments)}</Amount>
              </Typography>
            )}
          </div>
        </Grid>
      </Grid>
    </Card>
  )
}

SearchSummaryBanner.propTypes = {
  loading: PropTypes.bool.isRequired,
  enrollments: PropTypes.array,
  payments: PropTypes.array,
}

export default SearchSummaryBanner
