import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import ContactRole from 'components/ContactRole'
import PropTypes from 'prop-types'
import React from 'react'
import getInitials from 'utils/getInitials'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(-1),
      marginRight: theme.spacing(-1),
    },
  },
  header: {
    paddingBottom: 0,
  },
  content: {
    paddingTop: 0,
  },
}))

const LogMainContactInfo = (props) => {
  const classes = useStyles()
  const { mainContact, className, ...rest } = props

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        className={classes.header}
        title="Hovedkontakt fra siste påmelding"
        titleTypographyProps={{
          variant: 'overline',
        }}
      />
      <CardContent className={classes.content}>
        <List>
          <ListItem disableGutters>
            <ListItemAvatar>
              <Avatar alt="Author" className={classes.avatar} src={mainContact.profileImage}>
                {getInitials(mainContact.fullName)}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={`${mainContact.fullName}`}
              primaryTypographyProps={{ variant: 'h6' }}
              secondary={<ContactRole>{mainContact.type}</ContactRole>}
            />
            <ListItemText
              primary={`${mainContact.phoneNumber}`}
              // primaryTypographyProps={{ variant: 'h6' }}
              // secondaryTypographyProps={{ variant: 'h6' }}
              secondary={`${mainContact.email}`}
            />
          </ListItem>
        </List>
      </CardContent>
    </Card>
  )
}

LogMainContactInfo.propTypes = {
  className: PropTypes.string,
  mainContact: PropTypes.object.isRequired,
}

export default LogMainContactInfo
