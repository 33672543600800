import React from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { Grid, CircularProgress } from '@material-ui/core'
import getIdFromSlug from '../../../utils/getIDfromSlug'
import ContactEdit from './ContactEdit'
import ErrorMessage from '../../../components/ErrorMessage'

const GET_CONTACT = gql`
  query ($id: ID!) {
    contact(id: $id) {
      id
      slug
      firstName
      lastName
      fullName
      phoneNumber
      email
      type
      title
    }
  }
`

const Edit = (props) => {
  const { slug } = props
  const id = getIdFromSlug(slug)
  const { data, loading, error, refetch } = useQuery(GET_CONTACT, {
    variables: { id: id },
    fetchPolicy: 'no-cache',
  })

  if (loading) return <CircularProgress color="secondary" />
  if (error) return <ErrorMessage>{error.message}</ErrorMessage>

  return (
    <Grid container spacing={3}>
      <Grid item lg={7} md={6} xl={3} xs={12}>
        <ContactEdit contact={data.contact} refetch={refetch} />
      </Grid>
    </Grid>
  )
}

Edit.propTypes = {
  slug: PropTypes.string,
}

export default Edit
