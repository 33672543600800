import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { gql } from 'apollo-boost'
import { makeStyles, useTheme } from '@material-ui/styles'
import { useMutation } from '@apollo/react-hooks'
import { Link as RouterLink } from '@reach/router'
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  colors,
  ListItemIcon,
  MenuItem,
  Divider,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  useMediaQuery,
  DialogContentText,
  DialogActions,
} from '@material-ui/core'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import VisibilityIcon from '@material-ui/icons/Visibility'
import EditIcon from '@material-ui/icons/Edit'
import getInitials from 'utils/getInitials'
import ContactForm from 'components/Forms/ContactForm'
import ContactRole from 'components/ContactRole'
import GenericMoreButton from 'components/GenericMoreButton'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'

const useStyles = makeStyles((theme) => ({
  root: {},
  header: {
    paddingBottom: 0,
  },
  content: {
    paddingTop: 0,
  },
  actions: {
    backgroundColor: colors.grey[50],
  },
  manageButton: {
    width: '100%',
  },
}))

const REMOVE_CONTACT_FROM_ENROLLMENT = gql`
  mutation ($enrollmentID: ID!, $contactID: ID!) {
    removeContactFromEnrollment(enrollmentID: $enrollmentID, contactID: $contactID) {
      message
    }
  }
`

const MOVE_CONTACT_ON_ENROLLMENT = gql`
  mutation ($input: UpdateEnrollmentInput!) {
    updateEnrollment(input: $input) {
      id
      contacts {
        id
      }
    }
  }
`

const Contacts = (props) => {
  const { contacts, className, addMember, enrollmentID, refetch, ...rest } = props
  const classes = useStyles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const [removeContactFromEnrollment] = useMutation(REMOVE_CONTACT_FROM_ENROLLMENT)
  const [moveContactOnEnrollment] = useMutation(MOVE_CONTACT_ON_ENROLLMENT)

  const [openEdit, setOpenEdit] = useState(false)
  const [openConfirm, setOpenConfirm] = useState(false)
  const [editingMemberId, setEditingMemberId] = useState(null)

  const handleEditOpen = () => {
    setOpenEdit(true)
  }

  const handleEditClose = () => {
    setOpenEdit(false)
    refetch()
  }

  const handleOpen = (id) => {
    setEditingMemberId(id)
    setOpenConfirm(true)
  }

  const handleClose = () => {
    setEditingMemberId(null)
    setOpenConfirm(false)
  }

  const handleRemove = async (id) => {
    try {
      await removeContactFromEnrollment({
        variables: { enrollmentID: enrollmentID, contactID: id },
      })
    } catch {}
    setEditingMemberId(null)
    setOpenConfirm(false)
    refetch()
  }

  const handleMove = async (id) => {
    const contactsIds = contacts.map((contact) => contact.id)
    const updatedContacts = [id].concat(contactsIds.filter((contact) => contact !== id))
    console.log(updatedContacts)
    try {
      await moveContactOnEnrollment({
        variables: {
          input: {
            id: enrollmentID,
            contacts: updatedContacts,
          },
        },
      })
    } catch {
      console.log('error')
    }
    refetch()
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title="Kontaktpersoner" />
      <Divider />
      <CardContent className={classes.content}>
        <List>
          {contacts.map((member) => (
            <ListItem disableGutters key={member.id}>
              <ListItemAvatar>
                <Avatar alt="Author" className={classes.avatar} src="">
                  {getInitials(member.fullName)}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={`${member.fullName}`}
                primaryTypographyProps={{ variant: 'h6' }}
                secondary={<ContactRole>{member.type}</ContactRole>}
              />
              <GenericMoreButton>
                <Link underline="none" component={RouterLink} to={`/contacts/${member.slug}`}>
                  <MenuItem>
                    <ListItemIcon>
                      <VisibilityIcon />
                    </ListItemIcon>
                    <ListItemText primary="Se" />
                  </MenuItem>
                </Link>
                <MenuItem
                  onClick={() => {
                    handleMove(member.id)
                  }}
                >
                  <ListItemIcon>
                    <ArrowUpwardIcon />
                  </ListItemIcon>
                  <ListItemText primary="Sett som hovedkontakt" />
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    // handleRemove(member.id)
                    handleOpen(member.id)
                  }}
                >
                  <ListItemIcon>
                    <RemoveCircleIcon />
                  </ListItemIcon>
                  <ListItemText primary="Fjern" />
                </MenuItem>
                <Link underline="none" component={RouterLink} to={`/contacts/${member.slug}/edit`}>
                  <MenuItem>
                    <ListItemIcon>
                      <EditIcon />
                    </ListItemIcon>
                    <ListItemText primary="Rediger" />
                  </MenuItem>
                </Link>
              </GenericMoreButton>
            </ListItem>
          ))}
        </List>
      </CardContent>
      <CardActions className={classes.actions}>
        <Button className={classes.manageButton} onClick={handleEditOpen}>
          Legg til kontaktperson
        </Button>
      </CardActions>

      <Dialog
        fullScreen={fullScreen}
        open={openEdit}
        onClose={handleEditClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle disableTypography id="form-dialog-title">
          <Typography variant="h4">Legg til kontaktperson</Typography>
        </DialogTitle>
        <DialogContent dividers>
          <ContactForm
            enrollmentID={enrollmentID}
            onSubmitSuccess={handleEditClose}
            handleCancel={handleEditClose}
          ></ContactForm>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openConfirm}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Fjern kontaktperson fra påmelding?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" autoFocus>
            Avbryt
          </Button>
          <Button
            onClick={() => {
              handleRemove(editingMemberId)
            }}
            color="primary"
          >
            Fjern
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  )
}

Contacts.propTypes = {
  className: PropTypes.string,
  contacts: PropTypes.array.isRequired,
  enrollmentID: PropTypes.string.isRequired,
  refetch: PropTypes.func,
}

export default Contacts
