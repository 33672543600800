import React, { useEffect, useRef, Fragment } from 'react'
import PropTypes from 'prop-types'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { makeStyles } from '@material-ui/styles'
import { format, parseISO } from 'date-fns'
import { Divider, Typography } from '@material-ui/core'
import ConversationMessage from './ConversationMessage'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: 'relative',
    overflow: 'hidden',
    background: '#fff',
    marginLeft: theme.spacing(-3),
    marginRight: theme.spacing(-3),
  },
  inner: {
    padding: theme.spacing(3),
  },
  dividerPadding: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  dividerYear: {
    display: 'block',
    marginTop: '-10px',
    background: '#fff',
    maxWidth: '57px',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
  },
}))

const ConversationMessages = (props) => {
  const { messages, handleUpdate, handleDelete } = props
  const classes = useStyles()
  const messagesEndRef = useRef(null)
  let prevYear = false

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView()
  }

  const checkYear = (createdAt) => {
    if (prevYear !== format(parseISO(createdAt), 'yyyy')) {
      prevYear = format(parseISO(createdAt), 'yyyy')
      return true
    }
  }

  useEffect(scrollToBottom, [messages])

  return (
    <PerfectScrollbar className={classes.root}>
      <div className={classes.inner}>
        {messages.map((message) =>
          checkYear(message.createdAt) ? (
            <Fragment key={message.id}>
              <div className={classes.dividerPadding}>
                <Divider></Divider>
                <Typography className={classes.dividerYear} display="block" variant="h5">
                  {prevYear}
                </Typography>
              </div>
              <ConversationMessage
                key={message.id}
                message={message}
                handleUpdate={handleUpdate}
                handleDelete={handleDelete}
              />
            </Fragment>
          ) : (
            <ConversationMessage
              key={message.id}
              message={message}
              handleUpdate={handleUpdate}
              handleDelete={handleDelete}
            />
          )
        )}
      </div>
      <div ref={messagesEndRef} />
    </PerfectScrollbar>
  )
}

ConversationMessages.propTypes = {
  messages: PropTypes.array.isRequired,
  handleUpdate: PropTypes.func,
  handleDelete: PropTypes.func,
}

export default ConversationMessages
