import React from 'react'
import PropTypes from 'prop-types'
import { Box, Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  error: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    padding: theme.spacing(4, 4),
  },
}))

const ErrorMessage = ({ children }) => {
  const classes = useStyles()
  return (
    <Box className={classes.error}>
      <Paper className={classes.paper}>
        <Typography component="p">{children}</Typography>
      </Paper>
    </Box>
  )
}

ErrorMessage.propTypes = {
  children: PropTypes.string.isRequired,
}

export default ErrorMessage
