import {
  Avatar,
  colors,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Divider,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Link as RouterLink } from '@reach/router'
import FormattedDate from 'components/FormattedDate'
import React from 'react'
import getInitials from 'utils/getInitials'

const useStyles = makeStyles((theme) => ({
  content: {
    paddingTop: 0,
  },
  cell: {
    whiteSpace: 'nowrap',
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1),
    backgroundColor: colors.purple[400],
  },
  link: {
    textDecoration: 'none',
    color: colors.black,
    '&:hover': {
      textDecoration: 'none',
    },
  },
}))

const SchoolsActivityTable = ({ schools, columns, title }) => {
  const classes = useStyles()

  return (
    <Card className={classes.root}>
      <CardHeader title={title || 'Kontaktet skoler'} />
      <Divider />
      <CardContent className={classes.content}>
        <Table>
          <TableHead style={{ backgroundColor: 'white' }}>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column}>{column}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody style={{ padding: 0 }}>
            {schools?.map((school) => {
              return (
                <TableRow hover key={school.orgNr}>
                  <TableCell className={classes.cell}>
                    <Link
                      color="inherit"
                      component={RouterLink}
                      className={classes.link}
                      to={`/schools/${school.slug}`}
                    >
                      <div className={classes.nameCell}>
                        <Avatar className={classes.avatar} src={school.avatar}>
                          {getInitials(school.name)}
                        </Avatar>
                        <div>
                          <Typography variant="h6">{school.name}</Typography>
                          <div>{school.email}</div>
                        </div>
                      </div>
                    </Link>
                  </TableCell>
                  <TableCell className={classes.cell}>
                    {school.latestContactAt && (
                      <FormattedDate showTime={true}>{school.latestContactAt}</FormattedDate>
                    )}
                  </TableCell>
                  <TableCell className={classes.cell}>
                    {school.latestLabelUpdateAt && (
                      <FormattedDate showTime={true}>{school.latestLabelUpdateAt}</FormattedDate>
                    )}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}

export default SchoolsActivityTable
