import React, { useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import LabelRow from 'components/LabelRow'

const LabelsTable = (props) => {
  const {
    labels,
    columns,
    addNew: dialogOpen,
    handleAdd,
    handleAddCancel,
    handleUpdate,
    handleDelete,
  } = props
  const [editableLabel, setEditableLabel] = useState(null)

  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column}>{column}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {labels?.map((label) => {
            return (
              <LabelRow
                key={label.slug}
                label={label}
                handleUpdate={handleUpdate}
                handleDelete={handleDelete}
              />
            )
          })}
        </TableBody>
      </Table>
      <Dialog onClose={handleAddCancel} open={dialogOpen}>
        <DialogTitle onClose={handleAddCancel}>Legg til ny etikett</DialogTitle>
        <DialogContent dividers>
          <TextField
            margin="dense"
            autoFocus
            fullWidth
            value={editableLabel}
            onChange={(event) => setEditableLabel(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            type="button"
            onClick={() => {
              handleAddCancel()
              setEditableLabel(null)
            }}
          >
            Avbryt
          </Button>
          <Button
            onClick={async () => {
              await handleAdd(editableLabel)
            }}
            disabled={!editableLabel || editableLabel.trim().length === 0}
            color="primary"
          >
            Lagre
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

LabelsTable.propTypes = {
  labels: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  addNew: PropTypes.bool,
  handleAdd: PropTypes.func,
  handleAddCancel: PropTypes.func,
  handleUpdate: PropTypes.func,
  handleDelete: PropTypes.func,
}

export default LabelsTable
