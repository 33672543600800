import React from 'react'
import PropTypes from 'prop-types'
import { format, parseISO } from 'date-fns'

const FormattedDate = ({ children }) => {
  const isoDate = parseISO(children)
  const formattedDate = format(isoDate, 'dd.MM.yyyy HH:mm')

  return <>{formattedDate}</>
}

FormattedDate.propTypes = {
  children: PropTypes.string.isRequired,
  showTime: PropTypes.bool,
}

export default FormattedDate
