import React from 'react'
import { navigate } from '@reach/router'
import { makeStyles } from '@material-ui/styles'
import { Typography } from '@material-ui/core'
import qs from 'qs'
import { Page } from 'components'
import Results from './Results'
import SearchBar from './SearchBar'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
}))

const Users = (props) => {
  const { location } = props

  const classes = useStyles()

  const handleFilter = (values) => {
    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true }) || {}
    const newParams = {
      ...queryParams,
      ...values,
      page: 0,
    }
    const newSearchString = qs.stringify(newParams)
    navigate(`/users?${newSearchString}`)
  }

  const handleSearch = (value) => {
    navigate(`/users/?search=${value}`)
  }

  const handleReset = () => {
    navigate(`/users`)
  }

  return (
    <Page className={classes.root} title="Frivillige i OD">
      <Typography component="h1" variant="h3" gutterBottom>
        Frivillige i OD
      </Typography>
      <SearchBar
        location={location}
        onFilter={handleFilter}
        onSearch={handleSearch}
        handleReset={handleReset}
      />
      <Results className={classes.results} location={location} multiSelectable />
    </Page>
  )
}

export default Users
