import { useQuery } from '@apollo/react-hooks'
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  Box,
  Grid,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { gql } from 'apollo-boost'
import clsx from 'clsx'
import EnrollmentsTable from 'components/EnrollmentsTable'
import ErrorMessage from 'components/ErrorMessage'
import ResultsTablePagination from 'components/ResultsTablePagination'
import SkeletonTable from 'components/SkeletonTable'
import fileDownload from 'js-file-download'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { jsonToCsv, jsonToXlsx } from 'utils/exportUtils'
import constants from 'utils/constants'
import { useQueryParams } from 'utils/hooks'
import { assignInfoToItems } from 'utils/enrollmentsHelper'
import ExportMenuList from 'components/ExportMenuList'

const GET_ENROLLMENTS = gql`
  query ($filter: EnrollmentFilterInput!) {
    enrollmentsSearch(filter: $filter) {
      items {
        id
        slug
        status
        types
        comments
        createdAt
        estStudentCount
        estTeacherCount
        district {
          id
        }
        payment {
          id
          amount
          pupils
          paymentMethod
        }
        school {
          id
          name
          orgNr
          NSRId
          district {
            id
            title
          }
          details {
            postAddress {
              address
              zipCode
              place
            }
            visitAddress {
              address
              zipCode
              place
            }
          }
          email
          types
          status
          slug
        }
        project {
          id
          title
        }
        contacts {
          id
          firstName
          lastName
          email
          phoneNumber
          type
        }
      }
      total
    }
  }
`

const useStyles = makeStyles((theme) => ({
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 700,
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: 'flex-end',
  },
  box: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
}))

const Results = (props) => {
  const { className, location, ...rest } = props
  const classes = useStyles()
  const [loadingExport, setLoadingExport] = useState(false)
  const [exportPage, setExportPage] = useState(1)
  const { queryVariables, page, searchString, rowsPerPage } = useQueryParams(location)

  // Request data
  const { data, loading, error, client, refetch } = useQuery(GET_ENROLLMENTS, {
    variables: {
      filter: {
        sort: {
          order: 'desc',
          property: 'createdAt',
        },
        ...queryVariables,
      },
    },
  })

  // SkeletonView
  if (loading)
    return (
      <SkeletonTable
        searchString={searchString}
        rowsPerPage={rowsPerPage}
        columns={constants.columns.enrollments}
      />
    )

  if (error) return <ErrorMessage>{error.message}</ErrorMessage>

  const enrollments = data.enrollmentsSearch.items
  const { total } = data.enrollmentsSearch

  const handleExport = async (exportType) => {
    setLoadingExport(true)
    let items = []
    for (let i = 0; i < Math.ceil(total / rowsPerPage); i++) {
      const response = await client.query({
        query: GET_ENROLLMENTS,
        variables: {
          filter: {
            ...queryVariables,
            page: i,
            rowsPerPage,
            sort: {
              order: 'desc',
              property: 'createdAt',
            },
          },
        },
        fetchPolicy: 'network-only',
      })

      if (response?.data?.enrollmentsSearch?.items) {
        items = items.concat(response.data.enrollmentsSearch.items)
      }
      setExportPage(i + 1)
    }

    const updatedItems = assignInfoToItems(items)

    if (exportType === constants.exportTypes.csv) {
      const columns = [
        'school.NSRId',
        'school.orgNr',
        'school.name',
        'school.district.title',
        'school.email',
        'school.details.postAddress.address',
        'school.details.postAddress.zipCode',
        'school.details.postAddress.place',
        'school.details.visitAddress.address',
        'school.details.visitAddress.zipCode',
        'school.details.visitAddress.place',
        'status',
        'type',
        'createdAt',
        'estStudentCount',
        'estTeacherCount',
        'payment.amount',
        'payment.pupils',
        'contact.firstName',
        'contact.lastName',
        'contact.email',
        // 'additionalContactEmails',
        'additionalStudentContactEmails',
        'additionalTeacherContactEmails',
        'comments',
      ]

      const csv = await jsonToCsv(updatedItems, columns)
      fileDownload(csv, 'enrollments.csv', 'text/csv')
    } else if (exportType === constants.exportTypes.xls) {
      const columns = [
        {
          label: 'school.NSRId',
          value: (row) => (row.school && row.school.NSRId ? row.school.NSRId || '' : ''),
        },
        {
          label: 'school.orgNr',
          value: (row) => (row.school && row.school.orgNr ? row.school.orgNr || '' : ''),
        },
        {
          label: 'school.name',
          value: (row) => (row.school && row.school.name ? row.school.name || '' : ''),
        },
        {
          label: 'school.district.title',
          value: (row) =>
            row.school && row.school.district && row.school.district.title
              ? row.school.district.title || ''
              : '',
        },
        {
          label: 'school.email',
          value: (row) => (row.school && row.school.email ? row.school.email || '' : ''),
        },
        {
          label: 'school.details.visitAddress.address',
          value: (row) =>
            row.school &&
            row.school.details &&
            row.school.details.visitAddress &&
            row.school.details.visitAddress.address
              ? row.school.details.visitAddress.address || ''
              : '',
        },
        {
          label: 'school.details.visitAddress.zipCode',
          value: (row) =>
            row.school &&
            row.school.details &&
            row.school.details.visitAddress &&
            row.school.details.visitAddress.zipCode
              ? row.school.details.visitAddress.zipCode || ''
              : '',
        },
        {
          label: 'school.details.visitAddress.place',
          value: (row) =>
            row.school &&
            row.school.details &&
            row.school.details.visitAddress &&
            row.school.details.visitAddress.place
              ? row.school.details.visitAddress.place || ''
              : '',
        },
        {
          label: 'school.details.postAddress.address',
          value: (row) =>
            row.school &&
            row.school.details &&
            row.school.details.postAddress &&
            row.school.details.postAddress.address
              ? row.school.details.postAddress.address || ''
              : '',
        },
        {
          label: 'school.details.postAddress.zipCode',
          value: (row) =>
            row.school &&
            row.school.details &&
            row.school.details.postAddress &&
            row.school.details.postAddress.zipCode
              ? row.school.details.postAddress.zipCode || ''
              : '',
        },
        {
          label: 'school.details.postAddress.place',
          value: (row) =>
            row.school &&
            row.school.details &&
            row.school.details.postAddress &&
            row.school.details.postAddress.place
              ? row.school.details.postAddress.place || ''
              : '',
        },
        { label: 'status', value: 'status' },
        { label: 'type', value: 'type' },
        { label: 'createdAt', value: 'createdAt' },
        { label: 'estStudentCount', value: 'estStudentCount' },
        { label: 'estTeacherCount', value: 'estTeacherCount' },
        {
          label: 'payment.amount',
          value: (row) => (row.payment && row.payment.amount > 0 ? row.payment.amount || '' : ''),
        },
        {
          label: 'payment.pupils',
          value: (row) => (row.payment && row.payment.pupils > 0 ? row.payment.pupils || '' : ''),
        },
        {
          label: 'contact.firstName',
          value: (row) => (row.contact && row.contact.firstName ? row.contact.firstName || '' : ''),
        },
        {
          label: 'contact.lastName',
          value: (row) => (row.contact && row.contact.lastName ? row.contact.lastName || '' : ''),
        },
        {
          label: 'contact.email',
          value: (row) => (row.contact && row.contact.email ? row.contact.email || '' : ''),
        },
        // { label: 'additionalContactEmails', value: 'additionalContactEmails' },
        { label: 'additionalStudentContactEmails', value: 'additionalStudentContactEmails' },
        { label: 'additionalTeacherContactEmails', value: 'additionalTeacherContactEmails' },
        { label: 'comments', value: 'comments' },
      ]

      const settings = {
        sheetName: 'enrollments',
        fileName: 'enrollments',
        extraLength: 3,
        writeOptions: {},
      }

      jsonToXlsx(columns, updatedItems, settings, true)
    }

    setLoadingExport(false)
    setExportPage(1)
  }

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Typography color="textSecondary" gutterBottom variant="body2">
        {total} resultater. Side {page + 1} av {Math.ceil(total / rowsPerPage)}
      </Typography>
      <Card>
        <CardHeader
          title={searchString === '' ? 'Alle påmeldinger' : `Søk på ${searchString}`}
          action={
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="stretch"
              spacing={2}
            >
              <Grid item>
                <Box visibility={loadingExport ? 'visible' : 'hidden'} className={classes.box}>
                  <Typography color="textSecondary" variant="body2">
                    Lastet ned {exportPage} av {Math.ceil(total / rowsPerPage)} side
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <ExportMenuList
                  onSelect={handleExport}
                  loadingExport={loadingExport}
                ></ExportMenuList>
              </Grid>
            </Grid>
          }
        />
        <Divider />
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <EnrollmentsTable
                enrollments={enrollments}
                multiSelectable={true}
                refetch={refetch}
              />
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <ResultsTablePagination
            location={location}
            page={page}
            total={total}
            rowsPerPage={rowsPerPage}
            queryVariables={queryVariables}
            client={client}
            query={GET_ENROLLMENTS}
          />
        </CardActions>
      </Card>
    </div>
  )
}

Results.propTypes = {
  className: PropTypes.string,
}

Results.defaultProps = {}

export default Results
