import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { Button, Drawer, FormControlLabel, Radio, Typography } from '@material-ui/core'
import { Formik, Field } from 'formik'
import { RadioGroup } from 'formik-material-ui'
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  drawer: {
    width: 420,
    maxWidth: '100%',
  },
  header: {
    padding: theme.spacing(2, 1),
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
  content: {
    padding: theme.spacing(0, 3),
    flexGrow: 1,
  },
  contentSection: {
    padding: theme.spacing(2, 0),
  },
  contentSectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  contentSectionContent: {},
  formGroup: {
    padding: theme.spacing(2, 0),
  },
  fieldGroup: {
    display: 'flex',
    alignItems: 'center',
  },
  field: {
    marginTop: 0,
    marginBottom: 0,
  },
  flexGrow: {
    flexGrow: 1,
  },
  addButton: {
    marginLeft: theme.spacing(1),
  },
  tags: {
    marginTop: theme.spacing(1),
  },
  minAmount: {
    marginRight: theme.spacing(3),
  },
  maxAmount: {
    marginLeft: theme.spacing(3),
  },
  radioGroup: {},
  actions: {
    padding: theme.spacing(3),
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}))

const Filter = (props) => {
  const { open, onClose, onFilter, className, ...rest } = props

  const classes = useStyles()

  const initialValues = {
    status: '',
    labels: [],
  }

  const handleClear = () => {
    // setValues({ ...initialValues })
    // onFilter && onFilter(values)
  }

  return (
    <Drawer
      anchor="right"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant="temporary"
    >
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, actions) => {
          // Manipulate the labels to just be a set of ids
          const labels = values.labels.map((item) => item.id)
          onFilter && onFilter({ ...values, labels })
          actions.setSubmitting(false)
          onClose()
        }}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit} {...rest} className={clsx(classes.root, className)}>
            <div className={classes.header}>
              <Button onClick={onClose} size="small">
                <CloseIcon className={classes.buttonIcon} />
                Lukk
              </Button>
            </div>
            <div className={classes.content}>
              <div className={classes.contentSection}>
                <div className={classes.contentSectionContent}>
                  <div className={classes.formGroup}>
                    <Typography component="p" gutterBottom variant="overline">
                      Type
                    </Typography>
                    <div className={classes.fieldGroup}>
                      <Field
                        className={classes.radioGroup}
                        name="type"
                        label="Type"
                        component={RadioGroup}
                      >
                        <FormControlLabel
                          control={<Radio color="primary" />}
                          label="Alle"
                          value=""
                        />
                        <FormControlLabel
                          control={<Radio color="primary" />}
                          label="Lærer"
                          value="teacher"
                        />
                        <FormControlLabel
                          control={<Radio color="primary" />}
                          label="Elev"
                          value="student"
                        />
                      </Field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.actions}>
              <Button fullWidth onClick={handleClear} variant="contained">
                <DeleteIcon className={classes.buttonIcon} />
                Tilbakestill
              </Button>
              <Button color="primary" fullWidth type="submit" variant="contained">
                Bruk filtre
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </Drawer>
  )
}

Filter.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  onFilter: PropTypes.func,
  open: PropTypes.bool.isRequired,
}

export default Filter
