import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Link,
  List,
  ListItem,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'
import React from 'react'
import { Link as RouterLink } from '@reach/router'

const useStyles = makeStyles((theme) => ({
  content: {
    paddingTop: 0,
  },
  listItem: {
    padding: theme.spacing(2, 0),
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
}))

const UserInfo = (props) => {
  const { user } = props
  const classes = useStyles()

  return (
    <Card className={classes.root}>
      <CardHeader title="Kontaktinformasjon" />
      <Divider />
      <CardContent className={classes.content}>
        <List>
          <ListItem className={classes.listItem} disableGutters divider>
            <Typography variant="subtitle2">E-post</Typography>
            <Link
              color="textPrimary"
              variant="h6"
              underline="always"
              href={`mailto:${user?.email}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {user?.email}
            </Link>
          </ListItem>

          {user.firstName && (
            <ListItem className={classes.listItem} disableGutters divider>
              <Typography variant="subtitle2">Fornavn</Typography>
              <Typography variant="h6">{user.firstName}</Typography>
            </ListItem>
          )}

          {user.lastName && (
            <ListItem className={classes.listItem} disableGutters divider>
              <Typography variant="subtitle2">Etternavn</Typography>
              <Typography variant="h6">{user.lastName}</Typography>
            </ListItem>
          )}

          {user.mobile && (
            <ListItem className={classes.listItem} disableGutters divider>
              <Typography variant="subtitle2">Telefonnummer</Typography>
              <Link
                variant="h6"
                color="textPrimary"
                underline="always"
                href={`tel:${user.mobile}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {user.mobile}
              </Link>
            </ListItem>
          )}

          {user.address && (
            <ListItem className={classes.listItem} disableGutters divider>
              <Typography variant="subtitle2">Adresse</Typography>
              <Typography variant="h6">
                {`${user.address.streetAddress}${user.address.streetAddress ? ',' : ''} ${
                  user.address.zipCode
                } ${user.address.city}`}
              </Typography>
            </ListItem>
          )}
          {user.additionalUserInfo && (
            <>
              <ListItem className={classes.listItem} disableGutters divider>
                <Typography variant="subtitle2">Skole</Typography>
                <Typography variant="h6">{user.additionalUserInfo?.school}</Typography>
              </ListItem>
              <ListItem className={classes.listItem} disableGutters divider>
                <Typography variant="subtitle2">Klasse</Typography>
                <Typography variant="h6">{user.additionalUserInfo?.grade}</Typography>
              </ListItem>
              <ListItem className={classes.listItem} disableGutters divider>
                <Typography variant="subtitle2">Linje</Typography>
                <Typography variant="h6">{user.additionalUserInfo?.line}</Typography>
              </ListItem>
              <ListItem className={classes.listItem} disableGutters divider>
                <Typography variant="subtitle2">Rolle i OD</Typography>
                <Typography variant="h6">{user.additionalUserInfo?.title}</Typography>
              </ListItem>
              <ListItem className={classes.listItem} disableGutters divider>
                <Typography variant="subtitle2">Kommentarer</Typography>
                <Typography variant="h6">{user.additionalUserInfo?.comments}</Typography>
              </ListItem>
            </>
          )}
          {user.districts &&
            user.districts.map((district) => (
              <ListItem className={classes.listItem} disableGutters divider>
                <Typography variant="subtitle2">Distrikt</Typography>
                <Link
                  variant="h6"
                  color="textPrimary"
                  underline="always"
                  component={RouterLink}
                  to={`/districts/${district.slug}`}
                >
                  {district.title}
                </Link>
              </ListItem>
            ))}
        </List>
      </CardContent>
    </Card>
  )
}

UserInfo.propTypes = {
  user: PropTypes.object.isRequired,
}

export default UserInfo
