import { useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  colors,
  Divider,
  Grid,
  LinearProgress,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { makeStyles } from '@material-ui/styles'
import { Link as RouterLink } from '@reach/router'
import { gql } from 'apollo-boost'
import { Page } from 'components'
import EnrollmentStatusLabelLabel from 'components/EnrollmentStatusLabel'
import ErrorMessage from 'components/ErrorMessage'
import FormattedDate from 'components/FormattedDate'
import React from 'react'
import getInitials from 'utils/getInitials'
import constants from 'utils/constants'
import SearchSummaryBanner from 'components/SearchSummaryBanner'
import MiniSummaryBanner from 'components/MiniSummaryBanner'

const GET_PROJECTS_AND_DISTRICTS = gql`
  query {
    projects {
      id
      year
      title
      status
      slug
      payments {
        id
        amount
      }
    }
    districts {
      id
      title
      slug
      numberOfSchools
    }
  }
`

const GET_ENROLLMENTS = gql`
  query ($filter: EnrollmentFilterInput!) {
    enrollmentsSearch(filter: $filter) {
      items {
        id
        slug
        estStudentCount
        estTeacherCount
        schoolName
        status
        types
        district {
          id
          title
        }
        createdAt
      }
      total
    }
  }
`

const GET_LOGS = gql`
  query {
    logsSearch(
      filter: { rowsPerPage: 200, types: [call], sort: { property: "createdAt", order: desc } }
    ) {
      items {
        id
        type
        userFullName
        user {
          id
          slug
        }
        createdAt
        school {
          id
          name
        }
      }
    }
  }
`

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    flexGrow: 1,
    padding: 0,
  },
  results: {
    marginTop: theme.spacing(3),
  },
  input: {
    backgroundColor: theme.palette.white,
  },
  item: {
    padding: theme.spacing(3),
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      '&:not(:last-of-type)': {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
    [theme.breakpoints.up('sm')]: {
      '&:not(:last-of-type)': {
        borderRight: `1px solid ${theme.palette.divider}`,
      },
    },
  },
  valueContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  label: {
    marginLeft: theme.spacing(1),
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1),
    backgroundColor: colors.teal['A700'],
  },
  enrollmentAvatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1),
    backgroundColor: colors.orange[400],
  },
  link: {
    color: colors.black,
  },
  actions: {
    justifyContent: 'flex-end',
  },
  arrowForwardIcon: {
    marginLeft: theme.spacing(1),
  },
}))

const Dashboard = (props) => {
  const classes = useStyles()
  const [currentProjectId, setCurrentProjectId] = useState(null)

  let queryVariables = {
    projects: [currentProjectId],
    page: 0,
    rowsPerPage: 9999,
    statuses: [
      constants.enrollmentStatus.pending,
      constants.enrollmentStatus.approved,
      constants.enrollmentStatus.material_sent,
      constants.enrollmentStatus.accounting_provided,
    ],
    sort: {
      order: 'desc',
      property: 'createdAt',
    },
  }

  const handleProjectChange = (value) => {
    setCurrentProjectId(value)
  }

  const getEnrollmentsPerDistrict = (district, enrollments) => {
    return district && enrollments
      ? enrollments.filter((e) => {
          return e.district && e.district.id === district.id
        })
      : []
  }

  const { data, loading, error } = useQuery(GET_PROJECTS_AND_DISTRICTS)
  const {
    data: logs,
    loading: logsLoading,
    error: logsError,
  } = useQuery(GET_LOGS, {
    variables: {
      filter: queryVariables,
    },
  })

  if (data && data.projects && data.projects.length > 0 && !currentProjectId) {
    let openProjects = data.projects.filter((p) => p.status === constants.projectStatuses.open)

    if (openProjects && openProjects.length > 1) {
      // sort open projects in ascending order if we are not yet in March
      if (new Date().getMonth() < 2) {
        openProjects.sort(function (a, b) {
          return new Date(a.year) - new Date(b.year)
        })
      } else {
        openProjects.sort(function (a, b) {
          return new Date(b.year) - new Date(a.year)
        })
      }
    }

    setCurrentProjectId(openProjects[0].id)
  }

  const {
    data: enrollments,
    loading: enrollmentsLoading,
    error: enrollmentsError,
  } = useQuery(GET_ENROLLMENTS, {
    variables: { filter: queryVariables },
    skip: !currentProjectId,
  })

  if (loading || enrollmentsLoading) return <LinearProgress color="secondary" />
  if (error || enrollmentsError) return <ErrorMessage>{error.message}</ErrorMessage>

  const currentProject = data.projects.find((p) => p.id === currentProjectId)

  return (
    <Page className={classes.root} title="Dashboard">
      <Grid container spacing={2} display="flex" alignItems="center">
        <Grid item xs>
          <Typography component="h1" variant="h3" gutterBottom>
            Dashboard
          </Typography>
        </Grid>
        <Grid item xs>
          <TextField
            margin="dense"
            fullWidth
            className={classes.input}
            variant="outlined"
            name="type"
            select
            value={currentProjectId}
            onChange={(event) => {
              handleProjectChange(event.target.value)
            }}
          >
            {data?.projects?.map((project, index) => (
              <MenuItem key={project.id} value={project.id}>
                {project.title}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>

      <Grid container spacing={3} className={classes.results}>
        <Grid item xs={12}>
          <SearchSummaryBanner
            loading={enrollmentsLoading}
            enrollments={enrollments?.enrollmentsSearch?.items}
            payments={currentProject?.payments}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Card>
            <CardHeader title="Påmeldinger per distrikt" subheader="Alle påmeldingsstatuser" />
            <Divider />
            <CardContent className={classes.content}>
              <List disablePadding>
                {data?.districts?.map((district, i) => (
                  <ListItem divider={i < data?.districts?.length - 1} key={district.id}>
                    <ListItemAvatar>
                      <Avatar className={classes.avatar} src={district.avatar}>
                        {getInitials(district.title)}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      style={{ minWidth: '30%' }}
                      primary={
                        <Link
                          color="inherit"
                          component={RouterLink}
                          className={classes.link}
                          to={`/districts/${district.slug}`}
                        >
                          {district.title}
                        </Link>
                      }
                      secondary={`${district.numberOfSchools} skoler`}
                    />
                    <MiniSummaryBanner
                      loading={enrollmentsLoading}
                      enrollments={getEnrollmentsPerDistrict(
                        district,
                        enrollments?.enrollmentsSearch?.items
                      )}
                    />
                  </ListItem>
                ))}
              </List>
            </CardContent>
            <Divider />
            <CardActions className={classes.actions}>
              <Button
                color="primary"
                component={RouterLink}
                size="small"
                to="/enrollments"
                variant="text"
              >
                Se alle påmeldinger
                <ArrowForwardIcon className={classes.arrowForwardIcon} />
              </Button>
            </CardActions>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Card>
            <CardHeader title="Siste påmeldinger" subheader="Alle påmeldingsstatuser" />
            <Divider />
            <CardContent className={classes.content}>
              <List disablePadding>
                {enrollments?.enrollmentsSearch?.items
                  ?.slice(0, data?.districts?.length || 21)
                  .map((enrollment, i) => (
                    <ListItem divider={i < data?.districts?.length - 1} key={enrollment.id}>
                      <ListItemAvatar>
                        <Avatar className={classes.enrollmentAvatar} src={enrollment.avatar}>
                          {getInitials(enrollment.schoolName)}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Link
                            color="inherit"
                            component={RouterLink}
                            className={classes.link}
                            to={`/enrollments/${enrollment.slug}`}
                          >
                            {enrollment.schoolName}
                          </Link>
                        }
                        secondary={enrollment.district?.title}
                      />
                      <Typography variant="subtitle2">
                        <FormattedDate>{enrollment.createdAt}</FormattedDate>
                      </Typography>
                      <EnrollmentStatusLabelLabel>{enrollment.status}</EnrollmentStatusLabelLabel>
                    </ListItem>
                  ))}
              </List>
            </CardContent>
            <Divider />
            <CardActions className={classes.actions}>
              <Button
                color="primary"
                component={RouterLink}
                size="small"
                to="/enrollments"
                variant="text"
              >
                Se alle påmeldinger
                <ArrowForwardIcon className={classes.arrowForwardIcon} />
              </Button>
            </CardActions>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Card>
            <CardHeader title="Flest kontaktet" subheader="Antall loggførte samtaler (siste 200)" />
            <Divider />

            <CardContent className={classes.content}>
              <List disablePadding>
                {logs?.logsSearch?.items &&
                  Object.entries(
                    logs.logsSearch.items.reduce((users, item) => {
                      if (item.userFullName && item.user) {
                        if (users[item.user.slug]) {
                          users[item.user.slug].count += 1
                        } else {
                          users[item.user.slug] = {
                            userFullName: item.userFullName,
                            count: 1,
                            createdAt: item.createdAt,
                          }
                        }
                      }
                      return users
                    }, {})
                  )

                    .sort(([keyA, logA], [keyB, logB]) => logB.count - logA.count)
                    .map(([key, log], i) => (
                      <ListItem divider={i < data?.districts?.length - 1} key={key}>
                        <ListItemAvatar>
                          <Avatar className={classes.enrollmentAvatar}>
                            {getInitials(log.userFullName)}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Link
                              color="inherit"
                              component={RouterLink}
                              className={classes.link}
                              to={`/users/${key}`}
                            >
                              {log.userFullName}
                            </Link>
                          }
                          // secondary={'ETT ELLER ANNET'}
                        />
                        <Typography variant="subtitle2">{log.count}</Typography>
                        {/* <Typography variant="subtitle2">
                        Siste samtale:
                        <FormattedDate>{log.createdAt}</FormattedDate>
                      </Typography> */}
                        {/* <EnrollmentStatusLabelLabel>{log.type}</EnrollmentStatusLabelLabel> */}
                      </ListItem>
                    ))}
              </List>
            </CardContent>
            {/* <Divider /> */}
            {/* <CardActions className={classes.actions}>
              <Button
                color="primary"
                component={RouterLink}
                size="small"
                to="/enrollments"
                variant="text"
              >
                Se alle påmeldinger
                <ArrowForwardIcon className={classes.arrowForwardIcon} />
              </Button>
            </CardActions> */}
          </Card>
        </Grid>
      </Grid>
    </Page>
  )
}

export default Dashboard
