import React from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { Grid, CircularProgress } from '@material-ui/core'
import getIdFromSlug from 'utils/getIDfromSlug'
import UserInfo from './UserInfo'
import ErrorMessage from 'components/ErrorMessage'

const GET_USER = gql`
  query ($id: ID!) {
    user(id: $id) {
      id
      slug
      firstName
      nickName
      lastName
      fullName
      email
      mobile
      profileImage
      locale
      role
      status
      address {
        streetAddress
        zipCode
        city
      }
      additionalUserInfo {
        school
        grade
        line
        title
        description
        birthDate
        comments
      }
      districts {
        id
        title
        slug
      }
    }
  }
`

const Summary = (props) => {
  const { slug } = props
  const id = getIdFromSlug(slug)
  const { data, loading, error } = useQuery(GET_USER, { variables: { id: id } })

  if (loading) return <CircularProgress color="secondary" />
  if (error) return <ErrorMessage>{error.message}</ErrorMessage>

  return (
    <Grid container spacing={3}>
      <Grid item lg={7} md={6} xl={3} xs={12}>
        <UserInfo user={data.user} />
      </Grid>
    </Grid>
  )
}

Summary.propTypes = {
  slug: PropTypes.string,
}

export default Summary
