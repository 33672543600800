import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { Paper, Button, Input } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { Formik } from 'formik'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  search: {
    flexGrow: 1,
    height: 36,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center',
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.icon,
  },
  searchInput: {
    flexGrow: 1,
  },
  searchButton: {
    marginLeft: theme.spacing(2),
  },
}))

const Search = props => {
  const { onSearch, className, searchString, handleReset, ...rest } = props

  const classes = useStyles()

  return (
    <Formik
      initialValues={{
        searchString: searchString || '',
      }}
      onSubmit={(values, actions) => {
        onSearch(values.searchString)
        actions.setSubmitting(false)
      }}
    >
      {props => (
        <form onSubmit={props.handleSubmit} {...rest} className={clsx(classes.root, className)}>
          <Paper className={classes.search} elevation={1}>
            <SearchIcon className={classes.searchIcon} />
            <Input
              className={classes.searchInput}
              disableUnderline
              placeholder="Søk"
              type="text"
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values.searchString}
              name="searchString"
            />
          </Paper>
          <Button
            color="primary"
            className={classes.searchButton}
            type="submit"
            size="medium"
            variant="contained"
          >
            Søk
          </Button>
          {searchString && (
            <Button
              className={classes.searchButton}
              onClick={() => {
                props.setFieldValue('searchString', '')
                handleReset()
              }}
              type="button"
              size="medium"
            >
              Tilbakestill
            </Button>
          )}
        </form>
      )}
    </Formik>
  )
}

Search.propTypes = {
  className: PropTypes.string,
  onSearch: PropTypes.func,
  handleReset: PropTypes.func,
}

export default Search
