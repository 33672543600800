import { useQuery } from '@apollo/react-hooks'
import { Card, CardActions, CardContent, CardHeader, Divider, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { gql } from 'apollo-boost'
import ErrorMessage from 'components/ErrorMessage'
import ResultsTablePagination from 'components/ResultsTablePagination'
import SchoolsTable from 'components/SchoolsTable'
import SkeletonTable from 'components/SkeletonTable'
import PropTypes from 'prop-types'
import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import constants from 'utils/constants'
import { useQueryParams } from 'utils/hooks'

const GET_MY_SCHOOLS = gql`
  query {
    me {
      id
      schools {
        id
        name
        orgNr
        NSRId
        details {
          visitAddress {
            place
          }
        }
        district {
          id
          title
        }
        email
        types
        status
        slug
        labels {
          id
          title
        }
        enrollments {
          id
          status
          types
          project {
            id
            title
            year
            status
          }
          createdAt
        }
        latestContactAt
        latestLabelUpdateAt
      }
    }
  }
`

const useStyles = makeStyles((theme) => ({
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 700,
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: 'flex-end',
  },
}))

const Schools = (props) => {
  const { location } = props
  const classes = useStyles()
  const { queryVariables, page, searchString, rowsPerPage } = useQueryParams(location)
  const { data, loading, error, client } = useQuery(GET_MY_SCHOOLS, {
    variables: { ...queryVariables },
  })

  // SkeletonView
  if (loading)
    return (
      <SkeletonTable
        searchString={searchString}
        rowsPerPage={rowsPerPage}
        columns={constants.columns.mySchools}
      />
    )

  if (error) return <ErrorMessage>{error.message}</ErrorMessage>

  const { schools } = data.me
  const total = schools.length

  return (
    <div className={classes.root}>
      <Typography color="textSecondary" gutterBottom variant="body2">
        {total} resultater. Side {page + 1} av {Math.ceil(total / rowsPerPage)}
      </Typography>
      <Card>
        <CardHeader title={searchString === '' ? 'Skoler' : `Søk på ${searchString}`} />
        <Divider />
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <SchoolsTable
                schools={schools}
                columns={constants.columns.mySchools}
                omitResponsibleColumn={true}
              />
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <ResultsTablePagination
            location={location}
            page={page}
            total={total}
            rowsPerPage={rowsPerPage}
            queryVariables={queryVariables}
            client={client}
            query={GET_MY_SCHOOLS}
          />
        </CardActions>
      </Card>
    </div>
  )
}

Schools.propTypes = {
  location: PropTypes.object,
}

export default Schools
