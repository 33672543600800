import { useMutation, useQuery } from '@apollo/react-hooks'
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  DialogActions,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { gql } from 'apollo-boost'
import ErrorMessage from 'components/ErrorMessage'
import { Form, Formik } from 'formik'
import React from 'react'
import { object, string } from 'yup'

const GET_USER = gql`
  query {
    districts {
      id
      title
      slug
    }
    me {
      id
      firstName
      lastName
      fullName
      email
      mobile
      role
      profileImage
      address {
        streetAddress
        zipCode
        city
      }
      additionalUserInfo {
        school
        grade
        line
        title
        description
        birthDate
        comments
      }
      districts {
        id
        title
        slug
      }
    }
  }
`

const UPDATE_USER = gql`
  mutation(
    $id: ID!
    $firstName: String
    $nickName: String
    $lastName: String
    $fullName: String
    $email: String
    $mobile: String
    $profileImage: String
    $districts: [DistrictInput]
    $address: UserAddressInput
    $additionalUserInfo: UserAdditionalInfoInput
  ) {
    updateUser(
      input: {
        id: $id
        firstName: $firstName
        nickName: $nickName
        lastName: $lastName
        fullName: $fullName
        email: $email
        mobile: $mobile
        profileImage: $profileImage
        districts: $districts
        address: $address
        additionalUserInfo: $additionalUserInfo
      }
    ) {
      firstName
      lastName
    }
  }
`

const userFormValidationSchema = object().shape({
  firstName: string().required('Du må fylle inn fornavn'),
  lastName: string().required('Du må fylle inn etternavn'),
  email: string()
    .email('Ugyldig e-postadresse')
    .required('Du må fylle inn e-post'),
  mobile: string(),
  role: string(),
  profileImage: string(),
  address: object().shape({
    streetAddress: string(),
    zipCode: string(),
    city: string(),
  }),
  additionalUserInfo: object().shape({
    school: string(),
    grade: string(),
    line: string(),
    title: string(),
    comments: string(),
  }),
})

const UserForm = () => {
  const [open, setOpen] = React.useState(false)
  const { data, loading, error, refetch } = useQuery(GET_USER)
  const [updateUser] = useMutation(UPDATE_USER)

  if (loading) return <CircularProgress color="primary" />
  if (error) return <ErrorMessage>{error.message}</ErrorMessage>

  const districts = data.districts.map(item => {
    delete item.__typename
    return item
  })

  const userDistricts = data.me.districts.map(item => {
    delete item.__typename
    return item
  })

  const initialValues = {
    firstName: data.me.firstName || '',
    lastName: data.me.lastName || '',
    email: data.me.email || '',
    mobile: data.me.mobile || '',
    role: data.me.role || '',
    profileImage: data.me.profileImage || '',
    districts: userDistricts || [],
    address: {
      streetAddress: data.me.address?.streetAddress || '',
      zipCode: data.me.address?.zipCode || '',
      city: data.me.address?.city || '',
    },
    additionalUserInfo: {
      school: data.me.additionalUserInfo?.school || '',
      grade: data.me.additionalUserInfo?.grade || '',
      line: data.me.additionalUserInfo?.line || '',
      title: data.me.additionalUserInfo?.title || '',
      description: data.me.additionalUserInfo?.description || '',
      birthDate: data.me.additionalUserInfo?.birthDate || '',
      comments: data.me.additionalUserInfo?.comments || '',
    },
  }

  return (
    <Formik
      validationSchema={userFormValidationSchema}
      initialValues={initialValues}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true)
        try {
          await updateUser({
            variables: {
              id: data.me.id,
              ...values,
              fullName: values.firstName.trim() + ' ' + values.lastName.trim(),
            },
          })
          await refetch()
          actions.setSubmitting(false)
        } catch (e) {
          actions.setSubmitting(false)
          actions.setStatus(e.message || 'Ukjent feil.')
        }
      }}
    >
      {props => (
        <Form>
          <Grid container spacing={3}>
            <Grid item lg={7} md={6} xl={3} xs={12}>
              <Card>
                <CardHeader title="Kontaktinformasjon" />
                <Divider />
                <CardContent>
                  <TextField
                    margin="dense"
                    error={props.touched.firstName && !!props.errors.firstName}
                    helperText={
                      props.touched.firstName && props.errors.firstName
                        ? props.errors.firstName
                        : ' '
                    }
                    required
                    fullWidth
                    label="Fornavn"
                    variant="outlined"
                    placeholder="Fyll inn fornavn"
                    type="text"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.firstName}
                    name="firstName"
                  />

                  <TextField
                    margin="dense"
                    error={props.touched.lastName && !!props.errors.lastName}
                    helperText={
                      props.touched.lastName && props.errors.lastName ? props.errors.lastName : ' '
                    }
                    required
                    fullWidth
                    label="Etternavn"
                    variant="outlined"
                    placeholder="Fyll inn etternavn"
                    type="text"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.lastName}
                    name="lastName"
                  />

                  <TextField
                    margin="dense"
                    error={props.touched.email && !!props.errors.email}
                    helperText={
                      props.touched.email && props.errors.email ? props.errors.email : ' '
                    }
                    required
                    fullWidth
                    label="E-post"
                    variant="outlined"
                    placeholder="Fyll inn e-post"
                    type="email"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.email}
                    name="email"
                  />
                  <TextField
                    margin="dense"
                    error={props.touched.mobile && !!props.errors.mobile}
                    helperText={
                      props.touched.mobile && props.errors.mobile ? props.errors.mobile : ' '
                    }
                    fullWidth
                    label="Mobilnummer"
                    variant="outlined"
                    placeholder="Fyll inn mobilnummer"
                    type="text"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.mobile}
                    name="mobile"
                  />

                  <TextField
                    margin="dense"
                    error={
                      props.touched.address?.streetAddress && !!props.errors.address?.streetAddress
                    }
                    helperText={
                      props.touched.address?.streetAddress && props.errors.address?.streetAddress
                        ? props.errors.address?.streetAddress
                        : ' '
                    }
                    fullWidth
                    label="Gateadresse"
                    variant="outlined"
                    placeholder="Fyll inn gateadresse"
                    type="text"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.address?.streetAddress}
                    name="address.streetAddress"
                  />
                  <Grid container spacing={3}>
                    <Grid item xs>
                      <TextField
                        margin="dense"
                        error={props.touched.address?.zipCode && !!props.errors.address?.zipCode}
                        helperText={
                          props.touched.address?.zipCode && props.errors.address?.zipCode
                            ? props.errors.address?.zipCode
                            : ' '
                        }
                        fullWidth
                        label="Postnummer"
                        variant="outlined"
                        placeholder="Fyll inn postnummer"
                        type="text"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.address?.zipCode}
                        name="address.zipCode"
                      />
                    </Grid>
                    <Grid item xs>
                      <TextField
                        margin="dense"
                        error={props.touched.address?.city && !!props.errors.address?.city}
                        helperText={
                          props.touched.address?.city && props.errors.address?.city
                            ? props.errors.address?.city
                            : ' '
                        }
                        fullWidth
                        label="Sted"
                        variant="outlined"
                        placeholder="Fyll inn sted"
                        type="text"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.address?.city}
                        name="address.city"
                      />
                    </Grid>
                  </Grid>

                  <Divider />

                  <Box mt={3} mb={2}>
                    <Typography component="h5" variant="h5">
                      Distrikt
                    </Typography>
                  </Box>
                  <Grid container spacing={3}>
                    <Grid item xs></Grid>
                  </Grid>
                  <Autocomplete
                    open={open}
                    onOpen={() => {
                      setOpen(true)
                    }}
                    onClose={() => {
                      setOpen(false)
                    }}
                    multiple
                    defaultValue={props.values.districts}
                    onChange={(_, value) => props.setFieldValue('districts', value)}
                    getOptionSelected={(option, value) => option.title === value.title}
                    getOptionLabel={option => option.title}
                    options={districts}
                    loading={loading}
                    renderInput={params => (
                      <TextField
                        {...params}
                        autoComplete="off"
                        margin="dense"
                        label="Distrikt"
                        fullWidth
                        variant="outlined"
                        error={props.touched.districts && !!props.errors.districts}
                        helperText={
                          props.touched.districts && props.errors.districts
                            ? props.errors.districts
                            : ' '
                        }
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loading ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                  <Divider />

                  <Box mt={3} mb={2}>
                    <Typography component="h5" variant="h5">
                      Ekstra informasjon
                    </Typography>
                  </Box>

                  <TextField
                    margin="dense"
                    error={
                      props.touched.additionalUserInfo?.title &&
                      !!props.errors.additionalUserInfo?.title
                    }
                    helperText={
                      props.touched.additionalUserInfo?.title &&
                      props.errors.additionalUserInfo?.title
                        ? props.errors.additionalUserInfo?.title
                        : ' '
                    }
                    fullWidth
                    label="Rolle i OD"
                    variant="outlined"
                    placeholder="Fyll inn rolle"
                    type="text"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.additionalUserInfo?.title}
                    name="additionalUserInfo.title"
                  />
                  <TextField
                    multiline={true}
                    rows={3}
                    margin="dense"
                    error={
                      props.touched.additionalUserInfo?.comments &&
                      !!props.errors.additionalUserInfo?.comments
                    }
                    helperText={
                      props.touched.additionalUserInfo?.comments &&
                      props.errors.additionalUserInfo?.comments
                        ? props.errors.additionalUserInfo?.comments
                        : ' '
                    }
                    fullWidth
                    label="Kommentarer"
                    variant="outlined"
                    placeholder="Fyll inn kommentarer"
                    type="textarea"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.additionalUserInfo?.comments}
                    name="additionalUserInfo.comments"
                  />
                  <Grid container spacing={3}>
                    <Grid item xs>
                      <TextField
                        margin="dense"
                        error={
                          props.touched.additionalUserInfo?.school &&
                          !!props.errors.additionalUserInfo?.school
                        }
                        helperText={
                          props.touched.additionalUserInfo?.school &&
                          props.errors.additionalUserInfo?.school
                            ? props.errors.additionalUserInfo?.school
                            : ' '
                        }
                        fullWidth
                        label="Skole"
                        variant="outlined"
                        placeholder="Fyll inn skole"
                        type="text"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.additionalUserInfo?.school}
                        name="additionalUserInfo.school"
                      />
                    </Grid>
                    <Grid item xs>
                      <TextField
                        margin="dense"
                        error={
                          props.touched.additionalUserInfo?.grade &&
                          !!props.errors.additionalUserInfo?.grade
                        }
                        helperText={
                          props.touched.additionalUserInfo?.grade &&
                          props.errors.additionalUserInfo?.grade
                            ? props.errors.additionalUserInfo?.grade
                            : ' '
                        }
                        fullWidth
                        label="Klasse"
                        variant="outlined"
                        placeholder="Fyll inn klasse"
                        type="text"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.additionalUserInfo?.grade}
                        name="additionalUserInfo.grade"
                      />
                    </Grid>
                    <Grid item xs>
                      <TextField
                        margin="dense"
                        error={
                          props.touched.additionalUserInfo?.line &&
                          !!props.errors.additionalUserInfo?.line
                        }
                        helperText={
                          props.touched.additionalUserInfo?.line &&
                          props.errors.additionalUserInfo?.line
                            ? props.errors.additionalUserInfo?.line
                            : ' '
                        }
                        fullWidth
                        label="Linje"
                        variant="outlined"
                        placeholder="Fyll inn linje"
                        type="text"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.additionalUserInfo?.line}
                        name="additionalUserInfo.line"
                      />
                    </Grid>
                  </Grid>

                  {!!props.status && (
                    <Typography color="error">Noe gikk galt: {props.status}</Typography>
                  )}

                  <DialogActions>
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      disabled={!props.isValid || props.isSubmitting}
                      type="submit"
                      startIcon={props.isSubmitting && <CircularProgress size={18} />}
                    >
                      {props.isSubmitting ? 'Lagrer...' : 'Lagre'}
                    </Button>
                  </DialogActions>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default UserForm
