import React, { useState } from 'react'
import { Link as RouterLink } from '@reach/router'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import {
  Tabs,
  Tab,
  Divider,
  colors,
  LinearProgress,
  Typography,
  Breadcrumbs,
  Link,
  Grid,
  Button,
} from '@material-ui/core'
import FilterListIcon from '@material-ui/icons/FilterList'
import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import getIdFromSlug from '../../utils/getIDfromSlug'
import { Page } from 'components'
import ErrorMessage from '../../components/ErrorMessage'
import SchoolFilter from './SchoolFilter'
import EnrollmentFilter from './EnrollmentFilter'
import { navigate } from '@reach/router'
import qs from 'qs'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  tabs: {
    marginTop: theme.spacing(3),
  },
  divider: {
    backgroundColor: colors.grey[300],
    marginBottom: theme.spacing(3),
  },
  breadcrumb: {
    marginBottom: theme.spacing(2),
  },
}))

const GET_DISTRICT = gql`
  query ($id: ID!) {
    district(id: $id) {
      id
      title
      slug
    }
  }
`

const DistrictDetails = (props) => {
  const { slug, children, location } = props
  const id = getIdFromSlug(slug)
  const classes = useStyles()
  const initialValuesForSchoolsFilter = {
    labels: [],
    projects: [],
    enrollmentStatuses: [],
    enrollmentTypes: [],
    types: [],
  }
  const initialValuesForEnrollmentsFilter = {
    projects: [],
    statuses: [],
    types: [],
  }

  const [openSchoolFilter, setOpenSchoolFilter] = useState(false)
  const [openEnrollmentFilter, setOpenEnrollmentFilter] = useState(false)
  const [schoolFilterInitialValues, setSchoolFilterInitialValues] = useState(
    initialValuesForSchoolsFilter
  )
  const [enrollmentFilterInitialValues, setEnrollmentFilterInitialValues] = useState(
    initialValuesForEnrollmentsFilter
  )
  const { data, loading, error } = useQuery(GET_DISTRICT, { variables: { id: id } })

  const handleSchoolFilter = (values) => {
    setSchoolFilterInitialValues(values)

    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true }) || {}
    const newParams = {
      ...queryParams,
      ...values,
      page: 0,
    }
    const newSearchString = qs.stringify(newParams)
    navigate(`/districts/${slug}/schools?${newSearchString}`)
  }

  const handleEnrollmentFilter = (values) => {
    setEnrollmentFilterInitialValues(values)

    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true }) || {}
    const newParams = {
      ...queryParams,
      ...values,
      page: 0,
    }
    const newSearchString = qs.stringify(newParams)
    navigate(`/districts/${slug}/enrollments?${newSearchString}`)
  }

  const handleSchoolReset = () => {
    setSchoolFilterInitialValues(initialValuesForSchoolsFilter)
    navigate(`/districts/${slug}/schools`)
  }

  const handleEnrollmentReset = () => {
    setEnrollmentFilterInitialValues(initialValuesForEnrollmentsFilter)
    navigate(`/districts/${slug}/enrollments`)
  }

  const handleSchoolFilterOpen = () => {
    setOpenSchoolFilter(true)
  }

  const handleSchoolFilterClose = () => {
    setOpenSchoolFilter(false)
  }

  const handleEnrollmentFilterOpen = () => {
    setOpenEnrollmentFilter(true)
  }

  const handleEnrollmentFilterClose = () => {
    setOpenEnrollmentFilter(false)
  }

  const tabs = [
    { value: 'summary', label: 'Oversikt' },
    { value: 'schools', label: 'Skoler' },
    { value: 'enrollments', label: 'Påmeldinger' },
  ]

  const pathArray = location.pathname.split('/')
  const lastPartOfPath = pathArray[pathArray.length - 1]

  const currentTab =
    tabs.map((item) => item.value).indexOf(lastPartOfPath) === -1 ? 'summary' : lastPartOfPath

  if (loading) return <LinearProgress color="secondary" />
  if (error) return <ErrorMessage>{error.message}</ErrorMessage>

  const { title } = data.district

  return (
    <Page className={classes.root} title={`Distrikter - ${title}`}>
      <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
        <Link component={RouterLink} variant="subtitle1" color="textPrimary" to="/">
          Hjem
        </Link>
        <Link component={RouterLink} variant="subtitle1" color="textPrimary" to="/districts">
          Distrikt
        </Link>
      </Breadcrumbs>
      <Typography component="h1" variant="h3">
        {title}
      </Typography>
      <Grid container alignItems="center" justify="space-between">
        <Grid item>
          <Tabs
            className={classes.tabs}
            scrollButtons="auto"
            value={currentTab}
            variant="scrollable"
          >
            {tabs.map((tab) => (
              <Tab
                key={tab.value}
                label={tab.label}
                value={tab.value}
                component={RouterLink}
                to={tab.value}
              />
            ))}
          </Tabs>
        </Grid>
        {currentTab !== 'summary' && (
          <Grid item>
            <Button
              onClick={
                currentTab === 'schools' ? handleSchoolFilterOpen : handleEnrollmentFilterOpen
              }
              className={classes.filterButton}
              color="primary"
              size="small"
              variant="outlined"
            >
              <FilterListIcon className={classes.filterIcon} /> Vis filtre
            </Button>
            {currentTab === 'schools' && (
              <SchoolFilter
                onClose={handleSchoolFilterClose}
                onFilter={handleSchoolFilter}
                onReset={handleSchoolReset}
                open={openSchoolFilter}
                initialValues={schoolFilterInitialValues}
              />
            )}
            {currentTab === 'enrollments' && (
              <EnrollmentFilter
                onClose={handleEnrollmentFilterClose}
                onFilter={handleEnrollmentFilter}
                onReset={handleEnrollmentReset}
                open={openEnrollmentFilter}
                initialValues={enrollmentFilterInitialValues}
              />
            )}
          </Grid>
        )}
      </Grid>

      <Divider className={classes.divider} />
      {children}
    </Page>
  )
}

DistrictDetails.propTypes = {
  slug: PropTypes.string,
  // children: PropTypes.arrayOf(PropTypes.element),
}

export default DistrictDetails
