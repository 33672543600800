import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography, Button } from '@material-ui/core'
import constants from 'utils/constants'
import { getEnrollmentTypeString } from 'utils/enrollmentsHelper'
import { makeStyles } from '@material-ui/styles'
import Tooltip from '@material-ui/core/Tooltip'
import Zoom from '@material-ui/core/Zoom'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'flex-end',
    width: 'auto',
  },
}))

const MiniSummaryBanner = (props) => {
  const { loading, enrollments, ...rest } = props
  const classes = useStyles()

  const getAmountOfEnrollments = (enrollments, type) => {
    return enrollments && type
      ? enrollments.reduce((acc, cur) => {
          return cur && cur.types && cur.types.length > 0 && cur.types.includes(type)
            ? acc + 1
            : acc
        }, 0)
      : enrollments
      ? enrollments.length
      : 0
  }

  const getEstimatedPersonnelCount = (enrollments) => {
    return enrollments
      ? enrollments.reduce((acc, cur) => {
          return cur && cur.estTeacherCount ? acc + cur.estStudentCount + cur.estTeacherCount : acc
        }, 0)
      : 0
  }

  const getEstimatedStudentCount = (enrollments) => {
    return enrollments
      ? enrollments.reduce((acc, cur) => {
          return cur && cur.estStudentCount ? acc + cur.estStudentCount : acc
        }, 0)
      : 0
  }

  const getEstimatedTeacherCount = (enrollments) => {
    return enrollments
      ? enrollments.reduce((acc, cur) => {
          return cur && cur.estTeacherCount ? acc + cur.estTeacherCount : acc
        }, 0)
      : 0
  }

  const getEnrollmentsString = (enrollments) => {
    let str = `${getAmountOfEnrollments(enrollments)} påmeldinger`

    const odDayEnrollments = getAmountOfEnrollments(enrollments, constants.enrollmentTypes.odDay)
    const iuEnrollments = getAmountOfEnrollments(enrollments, constants.enrollmentTypes.iu)
    const odSchoolEnrollments = getAmountOfEnrollments(
      enrollments,
      constants.enrollmentTypes.odSchool
    )
    if (odDayEnrollments || iuEnrollments || odSchoolEnrollments) {
      str += ` (`
      if (odDayEnrollments > 0) {
        str += getEnrollmentTypeString([constants.enrollmentTypes.odDay]) + ': ' + odDayEnrollments
      }
      if (iuEnrollments > 0) {
        str += ', ' + getEnrollmentTypeString([constants.enrollmentTypes.iu]) + ': ' + iuEnrollments
      }
      if (odSchoolEnrollments > 0) {
        str +=
          ', ' +
          getEnrollmentTypeString([constants.enrollmentTypes.odSchool]) +
          ': ' +
          odSchoolEnrollments
      }
      str += `)`
    }
    return str
  }

  const getPersonnelString = (enrollments) => {
    const teacherCount = getEstimatedTeacherCount(enrollments)
    const studentCount = getEstimatedStudentCount(enrollments)

    let str = `${getEstimatedPersonnelCount(enrollments)} påmeldte personer`

    if (studentCount > 0 || teacherCount > 0) {
      str += ` (${studentCount} elever, ${teacherCount} lærere)`
    }

    return str
  }

  return (
    <Tooltip
      TransitionComponent={Zoom}
      title={`${getEnrollmentsString(enrollments)}. ${getPersonnelString(enrollments)}.`}
    >
      <Button>
        <Typography variant="subtitle2">{enrollments?.length}</Typography>
      </Button>
    </Tooltip>
  )
}

MiniSummaryBanner.propTypes = {
  loading: PropTypes.bool.isRequired,
  enrollments: PropTypes.array,
}

export default MiniSummaryBanner
