import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/react-hooks'
import { Link as RouterLink } from '@reach/router'
import { gql } from 'apollo-boost'
import { makeStyles } from '@material-ui/styles'
import {
  Tabs,
  Tab,
  Divider,
  colors,
  Link,
  Breadcrumbs,
  Typography,
  LinearProgress,
  Paper,
  Box,
} from '@material-ui/core'
import getIdFromSlug from '../../utils/getIDfromSlug'
import { Page } from 'components'
import Label from 'components/Label'

const GET_SCHOOL = gql`
  query ($id: ID!) {
    school(id: $id) {
      id
      slug
      name
      labels {
        id
        title
      }
      latestContactAt
      latestLabelUpdateAt
    }
  }
`

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  stickyArea: {
    backgroundColor: theme.palette.background.default,
    position: 'fixed',
    width: '97%',
    marginLeft: theme.spacing(-3),
    marginRight: theme.spacing(3),
    zIndex: 3,
  },
  innerPadding: {
    paddingTop: theme.spacing(3),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(-3),
    width: '100%',
  },
  tabs: {
    marginTop: theme.spacing(3),
  },
  divider: {
    backgroundColor: colors.grey[300],
  },
  content: {
    marginTop: theme.spacing(3),
  },
  error: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    padding: theme.spacing(4, 4),
  },
  breadcrumb: {
    marginBottom: theme.spacing(2),
  },
}))

const SchoolDetails = (props) => {
  const classes = useStyles()
  const { slug, children, location } = props
  const id = getIdFromSlug(slug)
  const { data, loading, error } = useQuery(GET_SCHOOL, { variables: { id: id } })
  const [height, setHeight] = useState(0)
  const stickyAreaRef = useRef(null)

  const tabs = [
    { value: 'summary', label: 'Oversikt' },
    { value: 'logs', label: 'Logg' },
    { value: 'enrollments', label: 'Påmeldinger' },
  ]

  const pathArray = location.pathname.split('/')
  const lastPartOfPath = pathArray[pathArray.length - 1]

  const currentTab =
    tabs.map((item) => item.value).indexOf(lastPartOfPath) === -1 ? 'summary' : lastPartOfPath

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setHeight(
      stickyAreaRef.current && stickyAreaRef.current.clientHeight > 0
        ? stickyAreaRef.current.clientHeight
        : 0
    )
  })

  if (loading) return <LinearProgress color="secondary" />
  if (error)
    return (
      <Box className={classes.error}>
        <Paper className={classes.paper}>
          <Typography component="p">Noe gikk galt. Vennligst last inn siden på nytt.</Typography>
        </Paper>
      </Box>
    )

  const { name, labels } = data.school

  return (
    <Page className={classes.root} title={`Skoler - ${name}`}>
      <div ref={stickyAreaRef} className={classes.stickyArea}>
        <div className={classes.innerPadding}>
          <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
            <Link component={RouterLink} variant="subtitle1" color="textPrimary" to="/">
              Hjem
            </Link>
            <Link component={RouterLink} variant="subtitle1" color="textPrimary" to="/schools">
              Skoler
            </Link>
          </Breadcrumbs>
          <Typography component="h1" variant="h3">
            {name}
          </Typography>
          {labels &&
            labels.map((item) => (
              <Label key={item.title} variant="outlined">
                {item.title}
              </Label>
            ))}
        </div>
        <Tabs
          className={classes.tabs}
          // onChange={handleTabsChange}
          scrollButtons="auto"
          value={currentTab}
          variant="scrollable"
        >
          {tabs.map((tab) => (
            <Tab
              key={tab.value}
              label={tab.label}
              value={tab.value}
              component={RouterLink}
              to={tab.value}
            />
          ))}
        </Tabs>
        <Divider className={classes.divider} />
      </div>
      <div style={{ marginTop: height }} />
      {children}
    </Page>
  )
}

SchoolDetails.propTypes = {
  slug: PropTypes.string,
}

export default SchoolDetails
