import React from 'react'
import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Formik, Form, FieldArray } from 'formik'
import AutoSave from 'components/AutoSave'
import { colors, CircularProgress } from '@material-ui/core'

import UsersInput from 'components/UsersInput'
import ErrorMessage from 'components/ErrorMessage'

const GET_USERS = gql`
  query {
    users {
      id
      fullName
      nickName
    }
  }
`

const useStyles = makeStyles((theme) => ({
  actions: {
    justifyContent: 'flex-end',
  },
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900],
    },
  },
  input: {
    // marginBottom: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  content: {
    width: '100%',
  },
  menu: {
    width: 200,
  },
}))

const MemberForm = (props) => {
  const { onSubmitSuccess, handleUserSelect, currentUsers } = props
  const classes = useStyles()

  const { data: usersData, loading: usersLoading, usersError } = useQuery(GET_USERS)

  if (usersLoading) return <CircularProgress />
  if (usersError) return <ErrorMessage>{usersError.message}</ErrorMessage>

  const allUsers = usersData.users

  const initialValues = {
    users: currentUsers.map((item) => item.id) || [],
  }

  return (
    <Formik
      className={classes.container}
      initialValues={initialValues}
      onSubmit={async (values, actions) => {
        if (values !== initialValues) {
          actions.setSubmitting(true)
          await handleUserSelect(values.users)
          onSubmitSuccess()

          actions.setSubmitting(false)
        }
      }}
    >
      {(props) => (
        <Form className={classes.container}>
          <div className={classes.content}>
            <FieldArray
              name="users"
              render={(arrayHelpers) => (
                <UsersInput
                  users={props.values.users}
                  allUsers={allUsers}
                  arrayHelpers={arrayHelpers}
                ></UsersInput>
              )}
            />
          </div>
        </Form>
      )}
    </Formik>
  )
}

MemberForm.propTypes = {
  districtID: PropTypes.string,
  onSubmitSuccess: PropTypes.func,
  handleUserSelect: PropTypes.func,
  currentUsers: PropTypes.array,
}

export default MemberForm
