import React from 'react'
import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Formik, Form } from 'formik'
import { string, object, mixed } from 'yup'
import {
  Typography,
  TextField,
  Button,
  colors,
  MenuItem,
  DialogActions,
  CircularProgress,
} from '@material-ui/core'
import constants from 'utils/constants'

const CREATE_CONTACT = gql`
  mutation(
    $id: ID
    $email: String
    $phoneNumber: String
    $firstName: String!
    $lastName: String!
    $type: ContactInputTypes!
    $enrollment: ID
    $title: String
  ) {
    createOrUpdateContact(
      input: {
        id: $id
        email: $email
        phoneNumber: $phoneNumber
        firstName: $firstName
        lastName: $lastName
        type: $type
        enrollment: $enrollment
        title: $title
      }
    ) {
      firstName
      lastName
    }
  }
`

const useStyles = makeStyles((theme) => ({
  actions: {
    justifyContent: 'flex-end',
  },
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900],
    },
  },
  input: {
    // marginBottom: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  menu: {
    width: 200,
  },
}))

const contactFormValidationSchema = object().shape({
  firstName: string().required('Du må fylle inn fornavn'),
  lastName: string().required('Du må fylle inn etternavn'),
  email: string().email('Ugyldig e-postadresse').required('Du må fylle inn e-post'),
  phoneNumber: string(),
  type: mixed().oneOf(['student', 'teacher'], 'Du må velge rolle').required('Du må velge rolle'),
})

const ContactForm = (props) => {
  const { enrollmentID, contact, onSubmitSuccess, handleCancel } = props

  const classes = useStyles()

  const [createOrUpdateContact] = useMutation(CREATE_CONTACT)
  let initialValues = {}
  let personID = false
  if (contact) {
    initialValues = {
      ...contact,
    }
    personID = contact.id
    // Get initial values from server
  } else {
    initialValues = {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      status: '',
      enrollment: enrollmentID,
      type: 'disabled',
      title: '',
    }
  }

  return (
    <Formik
      isInitialValid={false}
      validationSchema={contactFormValidationSchema}
      initialValues={initialValues}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true)
        try {
          await createOrUpdateContact({
            variables: {
              id: personID || undefined,
              firstName: values.firstName,
              lastName: values.lastName,
              phoneNumber: values.phoneNumber,
              email: values.email,
              type: values.type,
              enrollment: values.enrollment,
              title: values.title,
            },
          })
          actions.setSubmitting(false)
          onSubmitSuccess()
        } catch (e) {
          actions.setSubmitting(false)
          actions.setStatus(e.message || 'Ukjent feil.')
        }
      }}
    >
      {(props) => (
        <Form autoComplete="off">
          <TextField
            margin="dense"
            error={props.touched.firstName && !!props.errors.firstName}
            helperText={
              props.touched.firstName && props.errors.firstName ? props.errors.firstName : ' '
            }
            required
            autoFocus
            fullWidth
            label="Fornavn"
            variant="outlined"
            className={classes.input}
            placeholder="Fyll inn fornavn"
            type="text"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.firstName}
            name="firstName"
          />

          <TextField
            margin="dense"
            error={props.touched.lastName && !!props.errors.lastName}
            helperText={
              props.touched.lastName && props.errors.lastName ? props.errors.lastName : ' '
            }
            required
            fullWidth
            label="Etternavn"
            variant="outlined"
            className={classes.input}
            placeholder="Fyll inn etternavn"
            type="text"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.lastName}
            name="lastName"
          />

          <TextField
            margin="dense"
            error={props.touched.email && !!props.errors.email}
            helperText={props.touched.email && props.errors.email ? props.errors.email : ' '}
            required
            fullWidth
            label="E-post"
            variant="outlined"
            className={classes.input}
            placeholder="Fyll inn e-post"
            type="email"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.email}
            name="email"
          />
          <TextField
            margin="dense"
            error={props.touched.phoneNumber && !!props.errors.phoneNumber}
            helperText={
              props.touched.phoneNumber && props.errors.phoneNumber ? props.errors.phoneNumber : ' '
            }
            fullWidth
            label="Telefon"
            variant="outlined"
            className={classes.input}
            placeholder="Fyll inn telefonnummer"
            type="text"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.phoneNumber}
            name="phoneNumber"
          />

          <TextField
            margin="dense"
            error={props.touched.type && !!props.errors.type}
            helperText={props.errors.type ? props.errors.type : ' '}
            required
            fullWidth
            className={classes.input}
            variant="outlined"
            name="type"
            select
            value={props.values.type}
            onChange={props.handleChange}
          >
            <MenuItem value="disabled" disabled>
              Velg rolle *
            </MenuItem>
            <MenuItem value={constants.contactTypes.teacher}>Lærer</MenuItem>
            <MenuItem value={constants.contactTypes.student}>Elev</MenuItem>
          </TextField>

          <TextField
            margin="dense"
            error={props.touched.title && !!props.errors.title}
            helperText={props.touched.title && props.errors.title ? props.errors.title : ' '}
            required
            fullWidth
            label="Tittel"
            variant="outlined"
            className={classes.input}
            placeholder="Fyll inn tittel"
            type="text"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.title}
            name="title"
          />

          {!!props.status && <Typography color="error">Noe gikk galt: {props.status}</Typography>}

          <DialogActions>
            <Button
              disabled={props.isSubmitting}
              color="secondary"
              type="button"
              onClick={handleCancel}
            >
              Avbryt
            </Button>
            <Button
              disabled={!props.isValid || props.isSubmitting}
              color="primary"
              type="submit"
              startIcon={props.isSubmitting && <CircularProgress size={18} />}
            >
              {props.isSubmitting ? 'Lagrer...' : 'Lagre'}
            </Button>
          </DialogActions>
        </Form>
      )}
    </Formik>
  )
}

ContactForm.propTypes = {
  personData: PropTypes.any,
  onSubmitSuccess: PropTypes.func,
  handleCancel: PropTypes.func,
}

export default ContactForm
