import React, { useState } from 'react'
import { navigate } from '@reach/router'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Page } from 'components'
import SearchBar from './SearchBar'
import Results from './Results'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3),
    },
  },
  heading: {
    marginBottom: theme.spacing(2),
  },
}))

const Labels = (props) => {
  const { location } = props
  const classes = useStyles()
  const [addNew, setAddNew] = useState(false)

  const handleSearch = (value) => {
    navigate(`/labels/?search=${value}`)
  }

  const handleReset = () => {
    navigate(`/labels`)
  }

  const toggleAddNew = () => {
    setAddNew(!addNew)
  }

  return (
    <Page className={classes.root} title="Etiketter">
      <Typography component="h1" variant="h2" gutterBottom className={classes.heading}>
        Etiketter
      </Typography>
      <SearchBar
        onSearch={handleSearch}
        location={location}
        handleReset={handleReset}
        handleAddNew={toggleAddNew}
      />
      <Results location={location} addNew={addNew} toggleAddNew={toggleAddNew} />
    </Page>
  )
}

export default Labels
