import React from 'react'
import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Formik, Form, FieldArray } from 'formik'
import { object, mixed, number } from 'yup'
import {
  Typography,
  TextField,
  Button,
  colors,
  MenuItem,
  DialogActions,
  CircularProgress,
} from '@material-ui/core'
import constants from 'utils/constants'
import EnrollmentStatus from 'components/EnrollmentStatus'
import { getEnrollmentTypes } from 'utils/enrollmentsHelper'
import LabelsInput from 'components/LabelsInput'

const UPDATE_ENROLLMENT = gql`
  mutation(
    $id: ID!
    $estStudentCount: Int
    $estTeacherCount: Int
    $status: EnrollmentInputStatuses
    $types: [EnrollmentTypes]
    $comments: String
  ) {
    updateEnrollment(
      input: {
        id: $id
        estStudentCount: $estStudentCount
        estTeacherCount: $estTeacherCount
        status: $status
        types: $types
        comments: $comments
      }
    ) {
      id
    }
  }
`

const useStyles = makeStyles((theme) => ({
  actions: {
    justifyContent: 'flex-end',
  },
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900],
    },
  },
  input: {
    // marginBottom: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  menu: {
    width: 200,
  },
}))

const enrollmentFormValidationSchema = object().shape({
  estStudentCount: number().required('Du må fylle inn antall elever'),
  estTeacherCount: number().required('Du må fylle inn antall lærere'),
  status: mixed()
    .oneOf(
      [
        constants.enrollmentStatus.pending,
        constants.enrollmentStatus.approved,
        constants.enrollmentStatus.rejected,
        constants.enrollmentStatus.material_sent,
        constants.enrollmentStatus.accounting_provided,
      ],
      'Du må velge status'
    )
    .required('Du må velge status'),
})

const EnrollmentForm = (props) => {
  const { enrollment, onSubmitSuccess, handleCancel } = props
  const classes = useStyles()
  const allEnrollmentTypes = getEnrollmentTypes()
  const [updateEnrollment] = useMutation(UPDATE_ENROLLMENT)

  let initialValues = {}

  if (enrollment) {
    initialValues = {
      ...enrollment,
    }
  } else {
    initialValues = {}
  }

  return (
    <Formik
      isInitialValid={false}
      validationSchema={enrollmentFormValidationSchema}
      initialValues={initialValues}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true)
        const { id, estStudentCount, estTeacherCount, status, types, comments } = values

        try {
          await updateEnrollment({
            variables: {
              id,
              estStudentCount: parseInt(estStudentCount, 10),
              estTeacherCount: parseInt(estTeacherCount, 10),
              status,
              types,
              comments,
            },
          })
          actions.setSubmitting(false)
          onSubmitSuccess()
        } catch (e) {
          actions.setSubmitting(false)
          actions.setStatus(e.message || 'Ukjent feil.')
        }
      }}
    >
      {(props) => (
        <Form autoComplete="off">
          <TextField
            margin="dense"
            error={props.touched.estStudentCount && !!props.errors.estStudentCount}
            helperText={
              props.touched.estStudentCount && props.errors.estStudentCount
                ? props.errors.estStudentCount
                : ' '
            }
            fullWidth
            required
            autoFocus
            label="Elever"
            variant="outlined"
            className={classes.input}
            placeholder="Fyll inn antall påmeldte elever"
            type="number"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.estStudentCount}
            name="estStudentCount"
          />

          <TextField
            margin="dense"
            error={props.touched.estTeacherCount && !!props.errors.estTeacherCount}
            helperText={
              props.touched.estTeacherCount && props.errors.estTeacherCount
                ? props.errors.estTeacherCount
                : ' '
            }
            fullWidth
            required
            label="Lærere"
            variant="outlined"
            className={classes.input}
            placeholder="Fyll inn antall påmeldte lærere"
            type="number"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.estTeacherCount}
            name="estTeacherCount"
          />

          <TextField
            margin="dense"
            error={props.touched.type && !!props.errors.type}
            helperText={props.errors.type ? props.errors.type : ' '}
            required
            fullWidth
            label="Status"
            className={classes.input}
            variant="outlined"
            name="status"
            select
            value={props.values.status}
            onChange={props.handleChange}
          >
            <MenuItem value={constants.enrollmentStatus.pending}>
              <EnrollmentStatus>{constants.enrollmentStatus.pending}</EnrollmentStatus>
            </MenuItem>
            <MenuItem value={constants.enrollmentStatus.approved}>
              <EnrollmentStatus>{constants.enrollmentStatus.approved}</EnrollmentStatus>
            </MenuItem>
            <MenuItem value={constants.enrollmentStatus.rejected}>
              <EnrollmentStatus>{constants.enrollmentStatus.rejected}</EnrollmentStatus>
            </MenuItem>
            <MenuItem value={constants.enrollmentStatus.material_sent}>
              <EnrollmentStatus>{constants.enrollmentStatus.material_sent}</EnrollmentStatus>
            </MenuItem>
            <MenuItem value={constants.enrollmentStatus.accounting_provided}>
              <EnrollmentStatus>{constants.enrollmentStatus.accounting_provided}</EnrollmentStatus>
            </MenuItem>
          </TextField>

          {!!props.status && <Typography color="error">Noe gikk galt: {props.status}</Typography>}

          <FieldArray
            margin="dense"
            name="types"
            render={(arrayHelpers) => (
              <LabelsInput
                label="Type"
                tags={props.values.types}
                allTags={allEnrollmentTypes}
                arrayHelpers={arrayHelpers}
                onAdd={(tag) => arrayHelpers.push(tag)}
              />
            )}
          />

          <TextField
            margin="dense"
            style={{ marginTop: '1em' }}
            error={props.touched.comments && !!props.errors.comments}
            helperText={
              props.touched.comments && props.errors.comments ? props.errors.comments : ' '
            }
            fullWidth
            autoFocus
            label="Kommentarer"
            variant="outlined"
            className={classes.input}
            placeholder="Skriv inn kommentarer, ønsker eller spesielle behov dersom det er aktuelt."
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.comments}
            name="comments"
            type="text"
            multiline
            rows="4"
          />

          <DialogActions>
            <Button
              disabled={props.isSubmitting}
              color="secondary"
              type="button"
              onClick={handleCancel}
            >
              Avbryt
            </Button>
            <Button
              disabled={!props.isValid || props.isSubmitting}
              color="primary"
              type="submit"
              startIcon={props.isSubmitting && <CircularProgress size={18} />}
            >
              {props.isSubmitting ? 'Lagrer...' : 'Lagre'}
            </Button>
          </DialogActions>
        </Form>
      )}
    </Formik>
  )
}

EnrollmentForm.propTypes = {
  enrollment: PropTypes.any,
  onSubmitSuccess: PropTypes.func,
  handleCancel: PropTypes.func,
}

export default EnrollmentForm
