import React from 'react'
import { Editor, EditorState, RichUtils, convertToRaw } from 'draft-js'
import { IconButton, Tooltip, CircularProgress } from '@material-ui/core'
import SendIcon from '@material-ui/icons/Send'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 2),
    margin: theme.spacing(0, -3),
  },
  editor: {
    width: '100%',
    border: '1px solid #ddd',
    cursor: 'text',
    fontSize: 16,
    minHeight: 40,
    padding: 10,
    position: 'relative',
  },
}))

const RichTextArea = (props) => {
  const { handleSubmit } = props
  const classes = useStyles()

  const [editorState, setEditorState] = React.useState(EditorState.createEmpty())
  const [submitDisabled, setSubmitDisabled] = React.useState(true)
  const [isSubmitting, setIsSubmitting] = React.useState(false)

  const editor = React.useRef(null)

  function focusEditor() {
    editor.current.focus()
  }

  React.useEffect(() => {
    focusEditor()
  }, [])

  const onSubmit = async () => {
    setIsSubmitting(true)
    const ContentState = editorState.getCurrentContent()
    const raw = convertToRaw(ContentState)
    const content = JSON.stringify(raw)
    const contentRaw = ContentState.getPlainText()
    await handleSubmit({ content, contentRaw })

    setEditorState(EditorState.createEmpty())
    setSubmitDisabled(true)
    setIsSubmitting(false)
  }
  const handleChange = (editorState) => {
    setEditorState(editorState)
    setSubmitDisabled(!editorState.getCurrentContent().hasText())
  }

  const handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command)
    if (newState) {
      handleChange(newState)
      return 'handled'
    }
    return 'not-handled'
  }

  return (
    <div className={classes.root}>
      <div className={classes.editor} onClick={focusEditor}>
        <Editor
          editorState={editorState}
          onChange={handleChange}
          handleKeyCommand={handleKeyCommand}
          placeholder="Legg igjen melding med hvem du ringte og hva dere snakket om"
          ref={editor}
        />
      </div>

      {isSubmitting ? (
        <IconButton>
          <CircularProgress size={24} color="secondary" />
        </IconButton>
      ) : (
        <Tooltip title="Send">
          <IconButton onClick={onSubmit} color="primary" disabled={submitDisabled}>
            <SendIcon />
          </IconButton>
        </Tooltip>
      )}
    </div>
  )
}

export default RichTextArea
