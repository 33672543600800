import React from 'react'
import PropTypes from 'prop-types'
import { useAuth } from 'react-use-auth'
import { LinearProgress } from '@material-ui/core'
import Login from '../../views/Login'

const Authenticated = ({ as: Component, ...props }) => {
  const { isAuthenticated, isAuthenticating } = useAuth()
  

  // if (isAuthenticated() === null && isAuthenticating === false) {
  //   
  //   return <LinearProgress color="secondary" />
  // }

  //@TODO: Make PR to library to set isAuthenticating to true as initial value.
  if (isAuthenticating) {
    return <LinearProgress color="secondary" />
  }

  if (!isAuthenticated()) {
    return isAuthenticating ? <LinearProgress color="secondary" /> : <Login {...props} />
  }

  if (isAuthenticated()) {
    return <Component {...props} />
  }

  return <LinearProgress color="secondary" />
}

export default Authenticated

Authenticated.propTypes = {
  as: PropTypes.func.isRequired,
}
