import React from 'react'
import PropTypes from 'prop-types'

const Amount = ({ children }) => {
  if (children === undefined) return <>NaN</>

  return <>{Intl.NumberFormat('nb-NO', { style: 'currency', currency: 'NOK' }).format(children)}</>
}

Amount.propTypes = {
  children: PropTypes.number,
}

export default Amount
