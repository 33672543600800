import { useMutation, useQuery } from '@apollo/react-hooks'
import { CircularProgress, Grid, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { gql } from 'apollo-boost'
import ErrorMessage from 'components/ErrorMessage'
import Message from 'components/Message'
import React from 'react'
import getIdFromSlug from 'utils/getIDfromSlug'
import ConversationMessages from './ConversationMessages'
import LogMainContactInfo from './LogMainContactInfo'
import LogSchoolInfo from './LogSchoolInfo'
import { getMainContactFromEnrollments } from 'utils/enrollmentsHelper'
import Description from '../Summary/Description'
import SchoolEdit from '../Summary/SchoolEdit'

const GET_CALL_LOGS = gql`
  query ($filter: LogFilterInput!) {
    logsSearch(filter: $filter) {
      items {
        id
        type
        content
        contentRaw
        userFullName
        recipientFullName
        createdAt
        updatedAt
        user {
          id
          fullName
          email
          profileImage
          status
          auth0UserId
        }
      }
      total
    }
  }
`

const CREATE_LOG = gql`
  mutation ($input: LogInput!) {
    createLog(input: $input) {
      id
      type
      content
      contentRaw
      userFullName
    }
  }
`

const UPDATE_LOG = gql`
  mutation ($input: LogUpdateInput!) {
    updateLog(input: $input) {
      id
    }
  }
`

const DELETE_LOG = gql`
  mutation ($id: ID!) {
    deleteLog(id: $id) {
      id
    }
  }
`

const GET_SCHOOL = gql`
  query ($id: ID!) {
    school(id: $id) {
      id
      slug
      name
      types
      email
      orgNr
      active
      kid
      labels {
        id
        title
        slug
      }
      district {
        id
        title
      }
      responsible {
        id
        slug
        fullName
        email
        role
        profileImage
        additionalUserInfo {
          title
        }
      }
      enrollments {
        id
        status
        project {
          id
          title
          status
        }
        contacts {
          id
          fullName
          email
          phoneNumber
          type
        }
        createdAt
        updatedAt
      }
      details {
        url
        leader
        phone
        mobile
        pupils
        employeesMin
        employeesMax
        visitAddress {
          address
          zipCode
          place
          country
        }
        postAddress {
          address
          zipCode
          place
          country
        }
        county {
          name
        }
      }
      latestContactAt
      latestLabelUpdateAt
      description
      descriptionUpdatedAt
      descriptionUpdatedBy {
        id
        slug
        fullName
      }
    }
  }
`

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
  },
  messages: {
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(12),
    },
  },
  content: {
    paddingTop: 0,
  },
  messaging: {
    backgroundColor: 'pink',
  },
  message: {
    position: 'fixed',
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end',
    },
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-start',
    },
  },
  schoolInfo: {
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(12),
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(3),
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(-1),
      marginRight: theme.spacing(-1),
    },
  },
}))

const Logs = (props) => {
  const { slug } = props
  const id = getIdFromSlug(slug)
  const classes = useStyles()
  const [createLog] = useMutation(CREATE_LOG)
  const [updateLog] = useMutation(UPDATE_LOG)
  const [deleteLog] = useMutation(DELETE_LOG)

  const queryVariables = {
    types: ['call', 'message', 'system'],
    schools: [id],
    rowsPerPage: 1000,
    sort: {
      property: 'createdAt',
      order: 'asc',
    },
  }
  const { data, loading, error, refetch } = useQuery(GET_CALL_LOGS, {
    variables: { filter: queryVariables },
  })

  const {
    data: schoolData,
    loading: schoolLoading,
    error: schoolError,
    refetch: schoolRefetch,
  } = useQuery(GET_SCHOOL, {
    variables: { id: id },
  })

  const handleSubmit = async (message) => {
    const { content, contentRaw } = message
    await createLog({
      variables: {
        input: {
          content,
          contentRaw,
          type: 'call',
          school: id,
        },
      },
    })
    return refetch()
  }

  const handleUpdate = async (message) => {
    const { id, content, contentRaw } = message

    await updateLog({
      variables: {
        input: {
          id,
          content,
          contentRaw,
        },
      },
    })
    return refetch()
  }

  const handleDelete = async (message) => {
    const { id } = message
    await deleteLog({
      variables: {
        id,
      },
    })
    return refetch()
  }

  if (error || schoolError)
    return (
      <ErrorMessage>{error ? error.message : schoolError ? schoolError.message : ''}</ErrorMessage>
    )
  if (loading || schoolLoading) return <CircularProgress color="secondary" />

  const { items: messages } = data.logsSearch
  const { school } = schoolData
  const { enrollments } = school
  const mainContact = getMainContactFromEnrollments(enrollments)

  return (
    <Grid className={classes.root} container spacing={3}>
      <Grid item lg={5} md={9} xl={8} xs={12} className={classes.messages}>
        <ConversationMessages
          messages={messages}
          handleUpdate={handleUpdate}
          handleDelete={handleDelete}
        />
        <Message handleSubmit={handleSubmit} />
      </Grid>
      <Grid item lg={7} md={9} xl={4} xs={12} className={classes.info}>
        <Description school={school} className={classes.schoolInfo} refetch={schoolRefetch} />
        <SchoolEdit school={school} className={classes.schoolInfo} />
        <LogSchoolInfo school={school} className={classes.schoolInfo} />
        {mainContact && <LogMainContactInfo mainContact={mainContact} />}
      </Grid>
    </Grid>
  )
}

export default Logs
