import { useMutation } from '@apollo/react-hooks'
import {
  Avatar,
  CircularProgress,
  Dialog,
  DialogContent,
  Link,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  Zoom,
  colors,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Link as RouterLink } from '@reach/router'
import EnrollmentStatusLabel from 'components/EnrollmentStatusLabel'
import FormattedDate from 'components/FormattedDate'
import Label from 'components/Label'
import SchoolType from 'components/SchoolType'
import React from 'react'
import { getLatestEnrollmentStatus } from 'utils/enrollmentsHelper'
import getInitials from 'utils/getInitials'
import { getLabelsUpdatedAtString } from 'utils/labelHelper'
import { REMOVE_RESPONSIBLE_FROM_SCHOOL, UPDATE_RESPONSIBLE } from '../../views/School/Summary/'
import Responsible from '../../views/School/Summary/Responsible'

const useStyles = makeStyles((theme) => ({
  cell: {
    whiteSpace: 'nowrap',
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1),
    backgroundColor: colors.purple[400],
  },
  link: {
    textDecoration: 'none',
    color: colors.black,
    '&:hover': {
      textDecoration: 'none',
    },
  },
}))

export function getLatestEnrolledProjects(enrollments) {
  if (!enrollments || enrollments.length === 0) return 'Ikke påmeldt'

  let e = enrollments

  e.sort(function (a, b) {
    return new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
  })

  /*
  let str = ''
  for (let i = 0; i < 3; i++) {
    if (e.length > i) {
      str += e[i].project.year + ', '
    }
  }

  str = str.trim()
  str = str.substring(0, str.length - 1)
*/
  return e[0].project.year
}

const initialContextMenuState = {
  mouseX: null,
  mouseY: null,
}

const SchoolsTable = ({ schools, columns, omitResponsibleColumn, refetch }) => {
  const [contextMenuState, setContextMenuState] = React.useState(initialContextMenuState)
  const [selectedSchoolId, setSelectedSchoolId] = React.useState(null)
  const [editDialogOpen, setEditDialogOpen] = React.useState(false)
  const [isSubmitting, setIsSubmitting] = React.useState(false)

  const handleClose = () => {
    setContextMenuState(initialContextMenuState)
  }

  const handleContextClick = (event, schoolId) => {
    event.preventDefault()
    setSelectedSchoolId(schoolId)
    setContextMenuState({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    })
  }

  const handleContextMenuEdit = (e) => {
    setContextMenuState(initialContextMenuState)
    setEditDialogOpen(true)
  }

  const handleEditDialogClose = () => {
    setEditDialogOpen(false)
  }

  const [removeSchoolResponsible] = useMutation(REMOVE_RESPONSIBLE_FROM_SCHOOL)
  const [setSchoolResponsible] = useMutation(UPDATE_RESPONSIBLE)

  const handleRemove = async (id) => {
    try {
      setIsSubmitting(true)
      await removeSchoolResponsible({
        variables: { id: selectedSchoolId },
      })
    } catch {
      setIsSubmitting(false)
    }
    await refetch()
    setIsSubmitting(false)
  }

  const handleMemberSelect = async (users) => {
    try {
      setIsSubmitting(true)
      await setSchoolResponsible({ variables: { id: selectedSchoolId, responsible: users } })
    } catch {
      setIsSubmitting(false)
    }
    await refetch()
    setIsSubmitting(false)
  }

  const classes = useStyles()

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column}>{column}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {schools?.map((school) => {
            return (
              <TableRow
                hover
                key={school.orgNr}
                onContextMenu={(e) => handleContextClick(e, school.id)}
              >
                <TableCell className={classes.cell}>
                  <div className={classes.nameCell}>
                    <Avatar className={classes.avatar} src={school.avatar}>
                      {getInitials(school.name)}
                    </Avatar>
                    <div>
                      <Link
                        color="inherit"
                        component={RouterLink}
                        className={classes.link}
                        to={`/schools/${school.slug}`}
                      >
                        <Typography variant="h6">{school.name}</Typography>
                      </Link>
                      <div>{school.email}</div>
                    </div>
                  </div>
                </TableCell>
                <TableCell className={classes.cell}>
                  {school.details?.visitEditress?.place && (
                    <div>{school.details?.visitEditress?.place}</div>
                  )}
                </TableCell>
                <TableCell className={classes.cell}>{school.district.title}</TableCell>
                <TableCell className={classes.cell}>
                  {school.types?.map((item) => (
                    <Label key={item} variant="outlined">
                      <SchoolType>{item}</SchoolType>
                    </Label>
                  ))}
                </TableCell>
                {!omitResponsibleColumn && (
                  <TableCell className={classes.cell}>
                    {school.responsible && school.responsible.length > 0 && (
                      <div className={classes.nameCell}>
                        <Link
                          color="inherit"
                          component={RouterLink}
                          to={`/users/${school.responsible[0].slug}`}
                        >
                          {school.responsible[0].fullName}
                        </Link>
                      </div>
                    )}
                  </TableCell>
                )}
                <TableCell className={classes.cell}>
                  <Tooltip TransitionComponent={Zoom} title={`${getLabelsUpdatedAtString(school)}`}>
                    <div>
                      {school.labels?.map((item, index) => {
                        return (
                          item.title && (
                            <Label key={index} color={colors.purple[600]}>
                              {item.title}
                            </Label>
                          )
                        )
                      })}
                    </div>
                  </Tooltip>
                </TableCell>
                <TableCell className={classes.cell}>
                  {getLatestEnrollmentStatus(school.enrollments) && (
                    <EnrollmentStatusLabel>
                      {getLatestEnrollmentStatus(school.enrollments)}
                    </EnrollmentStatusLabel>
                  )}
                </TableCell>
                <TableCell className={classes.cell}>
                  <>
                    <div>
                      <Label variant="outlined">
                        {getLatestEnrolledProjects(school.enrollments)}
                      </Label>
                    </div>
                  </>
                </TableCell>
                <TableCell className={classes.cell}>
                  {school.latestContactAt && (
                    <FormattedDate showTime={true}>{school.latestContactAt}</FormattedDate>
                  )}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      <Menu
        open={contextMenuState.mouseY !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenuState.mouseY !== null && contextMenuState.mouseX !== null
            ? { top: contextMenuState.mouseY, left: contextMenuState.mouseX }
            : undefined
        }
      >
        <MenuItem onClick={(e) => handleContextMenuEdit(e)}>Rediger OD-ansvarlig</MenuItem>
      </Menu>
      <Dialog
        fullWidth
        maxWidth="sm"
        scroll="paper"
        onClose={handleEditDialogClose}
        open={editDialogOpen}
        className={classes.dialog}
      >
        <DialogContent>
          {isSubmitting ? (
            <CircularProgress />
          ) : (
            <Responsible
              members={
                schools.find((s) => s.id === selectedSchoolId)
                  ? schools.find((s) => s.id === selectedSchoolId).responsible
                  : []
              }
              handleRemove={handleRemove}
              handleMemberSelect={handleMemberSelect}
              handleSuccess={refetch}
            ></Responsible>
          )}
        </DialogContent>
      </Dialog>
    </>
  )
}

export default SchoolsTable
