import { TablePagination } from '@material-ui/core'
import { navigate } from '@reach/router'
import qs from 'qs'
import React from 'react'
import PropTypes from 'prop-types'

const ResultsTablePagination = ({
  location,
  page,
  total,
  queryVariables,
  client,
  query,
  rowsPerPage,
}) => {
  const handleChangePage = (event, page) => {
    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true }) || {}
    const newParams = {
      ...queryParams,
      page: page,
    }
    const newSearchString = qs.stringify(newParams)
    navigate(`?${newSearchString}`)
  }

  const handlePreload = () => {
    if (page + 1 !== Math.ceil(total / rowsPerPage)) {
      client.query({
        query,
        variables: { filter: { ...queryVariables, page: page + 1 } },
      })
    }
  }

  const handleChangeRowsPerPage = (event) => {
    const rowsPerPage = event.target.value
    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true }) || {}
    const newParams = {
      ...queryParams,
      rowsPerPage,
    }
    const newSearchString = qs.stringify(newParams)
    navigate(`?${newSearchString}`)
  }

  return (
    <TablePagination
      onMouseOver={handlePreload}
      component="div"
      count={total}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      page={page}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={[5, 10, 25, 50, 100]}
      labelDisplayedRows={(props) => {
        const { from, to, count } = props
        return `${from}-${to} av ${count}`
      }}
      labelRowsPerPage="Rader per side:"
    />
  )
}

ResultsTablePagination.propTypes = {
  location: PropTypes.object,
  page: PropTypes.number,
  total: PropTypes.number,
  queryVariables: PropTypes.object,
  client: PropTypes.object,
  query: PropTypes.object,
  rowsPerPage: PropTypes.number,
}

export default ResultsTablePagination
