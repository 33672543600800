import { Drawer, Hidden, Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import { Navigation } from 'components'
import PropTypes from 'prop-types'
import React, { Fragment, useEffect } from 'react'
import navigationConfig from './navigationConfig'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    overflowY: 'auto',
  },
  content: {
    padding: theme.spacing(2),
  },
  navigation: {
    marginTop: theme.spacing(2),
  },
}))

const NavBar = props => {
  const { openMobile, onMobileClose, className, location, ...rest } = props
  const classes = useStyles()

  useEffect(() => {
    if (openMobile) {
      onMobileClose && onMobileClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  const navbarContent = (
    <div className={classes.content}>
      <nav className={classes.navigation}>
        {navigationConfig.map(list => (
          <Navigation component="div" key={list.title} pages={list.pages} title={list.title} />
        ))}
      </nav>
    </div>
  )

  return (
    <Fragment>
      <Hidden lgUp>
        <Drawer anchor="left" onClose={onMobileClose} open={openMobile} variant="temporary">
          <div {...rest} className={clsx(classes.root, className)}>
            {navbarContent}
          </div>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Paper {...rest} className={clsx(classes.root, className)} elevation={1} square>
          {navbarContent}
        </Paper>
      </Hidden>
    </Fragment>
  )
}

NavBar.propTypes = {
  className: PropTypes.string,
  location: PropTypes.object,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
}

export default NavBar
