import React from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink } from '@reach/router'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardHeader,
  CardContent,
  List,
  ListItem,
  Link,
  Typography,
  Divider,
  CardActions,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import ContactRole from '../../../components/ContactRole'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'
import { navigate } from '@reach/router'
import { GET_CONTACTS } from '../../Contacts/Results'
import { useQueryParams } from 'utils/hooks'

const useStyles = makeStyles((theme) => ({
  content: {
    paddingTop: 0,
  },
  listItem: {
    padding: theme.spacing(2, 0),
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
}))

const DELETE_CONTACT = gql`
  mutation deleteContact($id: ID!) {
    deleteContact(id: $id) {
      id
      message
    }
  }
`

const ContactInfo = (props) => {
  const { contact, location } = props
  const { queryVariables } = useQueryParams(location || {})
  const [openConfirm, setOpenConfirm] = React.useState(false)
  const classes = useStyles()

  const [deleteContact] = useMutation(DELETE_CONTACT)

  const handleDelete = async () => {
    setOpenConfirm(false)
    try {
      await deleteContact({
        variables: { id: contact.id },
        refetchQueries: [
          {
            query: GET_CONTACTS,
            variables: { filter: queryVariables },
          },
        ],
      })
      navigate(`/contacts`)
    } catch {
      console.log('error')
    }
  }

  return (
    <Card className={classes.root}>
      <CardHeader title="Kontaktinformasjon" />
      <Divider />
      <CardContent className={classes.content}>
        <List>
          {contact.enrollment && (
            <ListItem className={classes.listItem} disableGutters divider>
              <Typography variant="subtitle2">Påmelding</Typography>
              <Link
                component={RouterLink}
                color="textPrimary"
                variant="h6"
                underline="always"
                to={`/schools/${contact?.enrollment?.school?.slug}`}
                rel="noopener noreferrer"
              >
                {contact && contact.enrollment.schoolName}
              </Link>
            </ListItem>
          )}
          <ListItem className={classes.listItem} disableGutters divider>
            <Typography variant="subtitle2">E-post</Typography>
            <Link
              color="textPrimary"
              variant="h6"
              underline="always"
              href={`mailto:${contact.email}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {contact && contact.email}
            </Link>
          </ListItem>
          {contact.firstName && (
            <ListItem className={classes.listItem} disableGutters divider>
              <Typography variant="subtitle2">Fornavn</Typography>
              <Typography variant="h6">{contact.firstName}</Typography>
            </ListItem>
          )}
          {contact.lastName && (
            <ListItem className={classes.listItem} disableGutters divider>
              <Typography variant="subtitle2">Etternavn</Typography>
              <Typography variant="h6">{contact.lastName}</Typography>
            </ListItem>
          )}
          {contact.phoneNumber && (
            <ListItem className={classes.listItem} disableGutters divider>
              <Typography variant="subtitle2">Telefonnummer</Typography>
              <Link
                variant="h6"
                color="textPrimary"
                underline="always"
                href={`tel:${contact.phoneNumber}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {contact.phoneNumber}
              </Link>
            </ListItem>
          )}
          {contact.type && (
            <ListItem className={classes.listItem} disableGutters divider>
              <Typography variant="subtitle2">Rolle</Typography>
              <Typography variant="h6">
                <ContactRole>{contact.type}</ContactRole>
              </Typography>
            </ListItem>
          )}
          {contact.title && (
            <ListItem className={classes.listItem} disableGutters divider>
              <Typography variant="subtitle2">Tittel</Typography>
              <Typography variant="h6">{contact.title}</Typography>
            </ListItem>
          )}
        </List>
      </CardContent>
      <CardActions>
        <Button component={RouterLink} to="./edit" startIcon={<EditIcon />} color="primary">
          Rediger
        </Button>
        <Button
          onClick={() => setOpenConfirm(true)}
          startIcon={<DeleteForeverIcon />}
          color="secondary"
        >
          Slett
        </Button>
      </CardActions>

      <Dialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Slett contakt?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirm(false)} color="secondary" autoFocus>
            Avbryt
          </Button>
          <Button onClick={handleDelete} color="primary">
            Slett
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  )
}

ContactInfo.propTypes = {
  contact: PropTypes.object.isRequired,
}

export default ContactInfo
