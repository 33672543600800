import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Search from 'components/Search'
import PropTypes from 'prop-types'
import qs from 'qs'
import React from 'react'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  search: {
    flexGrow: 1,
    maxWidth: 480,
    flexBasis: 480,
  },
  filterButton: {
    marginLeft: 'auto',
  },
  filterIcon: {
    marginRight: theme.spacing(1),
  },
}))

const SearchBar = props => {
  const { onSearch, location, handleReset } = props

  const searchQuery = qs.parse(location.search, { ignoreQueryPrefix: true })

  const { search } = searchQuery
  const classes = useStyles()

  return (
    <Grid className={classes.root} container spacing={3}>
      <Grid item>
        <Search
          className={classes.search}
          onSearch={onSearch}
          searchString={search}
          handleReset={handleReset}
        />
      </Grid>
    </Grid>
  )
}

SearchBar.propTypes = {
  className: PropTypes.string,
  onSearch: PropTypes.func,
  handleReset: PropTypes.func,
  location: PropTypes.shape(),
}

export default SearchBar
