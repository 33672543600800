import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
  colors,
} from '@material-ui/core'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { makeStyles } from '@material-ui/styles'
import { Link as RouterLink } from '@reach/router'
import clsx from 'clsx'
import MemberForm from 'components/Forms/MemberForm'
import GenericMoreButton from 'components/GenericMoreButton'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import getInitials from 'utils/getInitials'

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'visible',
    marginBottom: theme.spacing(3),
  },
  header: {
    paddingBottom: 0,
  },
  content: {
    paddingTop: 0,
  },
  actions: {
    backgroundColor: colors.grey[50],
  },
  manageButton: {
    width: '100%',
  },
}))

const Responsible = (props) => {
  const { members, className, handleRemove, handleMemberSelect, handleSuccess, ...rest } = props
  const classes = useStyles()

  const [openConfirm, setOpenConfirm] = useState(false)
  const [editingMemberId, setEditingMemberId] = useState(null)

  const handleOpen = (id) => {
    setEditingMemberId(id)
    setOpenConfirm(true)
  }

  const handleClose = () => {
    setEditingMemberId(null)
    setOpenConfirm(false)
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title="Ansvarlig i OD" />
      <Divider />
      <CardContent className={classes.content}>
        <List>
          {members &&
            members.map((member) => (
              <ListItem disableGutters key={member.id}>
                <ListItemAvatar>
                  <Avatar alt="Author" className={classes.avatar} src={member.profileImage}>
                    {getInitials(member.fullName)}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={`${member.fullName}`}
                  primaryTypographyProps={{ variant: 'h6' }}
                  secondary={member.additionalUserInfo?.title}
                />
                <GenericMoreButton>
                  <Link underline="none" component={RouterLink} to={`/users/${member.slug}`}>
                    <MenuItem>
                      <ListItemIcon>
                        <VisibilityIcon />
                      </ListItemIcon>
                      <ListItemText primary="Se" />
                    </MenuItem>
                  </Link>
                  <MenuItem
                    onClick={() => {
                      handleOpen(member.id)
                    }}
                  >
                    <ListItemIcon>
                      <RemoveCircleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Fjern" />
                  </MenuItem>
                </GenericMoreButton>
              </ListItem>
            ))}
        </List>
      </CardContent>

      {(!members || members.length === 0) && (
        <CardActions className={classes.actions}>
          <div className={classes.manageButton}>
            <Typography>Legg til ansvarlig i OD</Typography>
            <MemberForm
              onSubmitSuccess={handleSuccess}
              currentUsers={members}
              handleUserSelect={handleMemberSelect}
            ></MemberForm>
          </div>
        </CardActions>
      )}

      <Dialog
        open={openConfirm}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Fjern OD-ansvarlig fra skolen?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" autoFocus>
            Avbryt
          </Button>
          <Button
            onClick={() => {
              handleRemove(editingMemberId)
              handleClose()
            }}
            color="primary"
          >
            Fjern
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  )
}

Responsible.propTypes = {
  className: PropTypes.string,
  members: PropTypes.array.isRequired,
  handleRemove: PropTypes.func.isRequired,
  handleMemberSelect: PropTypes.func.isRequired,
}

export default Responsible
