import React from 'react'
import ReactDOM from 'react-dom'
import ApolloClient from 'apollo-boost'
import { navigate } from '@reach/router'
import { ApolloProvider } from 'react-apollo'
import { useAuth, AuthProvider } from 'react-use-auth'
import './index.css'
import 'react-perfect-scrollbar/dist/css/styles.css'
import App from './App'
import * as serviceWorker from './serviceWorker'

// Define api url
const uri =
  window.location.hostname === 'localhost'
    ? `http://localhost:8000/api`
    : `${window.location.origin}/api`

const ApolloApp = () => {
  const { authResult } = useAuth()

  const client = new ApolloClient({
    uri,
    request: operation => {
      operation.setContext(context => ({
        headers: {
          ...context.headers,
          Authorization: `Bearer ${authResult ? authResult.idToken : undefined}`,
        },
      }))
    },
  })

  return (
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  )
}

const AuthApp = () => (
  <AuthProvider
    navigate={navigate}
    auth0_domain="operasjon-dagsverk.eu.auth0.com"
    auth0_client_id={process.env.REACT_APP_AUTH0_CLIENT_ID}
  >
    <ApolloApp />
  </AuthProvider>
)

ReactDOM.render(<AuthApp />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
