import React from 'react'
import { useAuth } from 'react-use-auth'
import { makeStyles } from '@material-ui/styles'
import { Card, CardContent, Typography, Box, Button } from '@material-ui/core'
import LockIcon from '@material-ui/icons/Lock'
import Page from '../../components/Page'
import gradients from 'utils/gradients'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(6, 2),
  },
  card: {
    width: theme.breakpoints.values.md,
    maxWidth: '100%',
    overflow: 'unset',
    display: 'flex',
    position: 'relative',
    '& > *': {
      flexGrow: 1,
      flexBasis: '50%',
      width: '50%',
    },
  },
  content: {
    padding: theme.spacing(8, 4, 3, 4),
  },
  media: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    padding: theme.spacing(3),
    color: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  icon: {
    backgroundImage: gradients.green,
    color: theme.palette.white,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    position: 'absolute',
    top: -32,
    left: theme.spacing(3),
    height: 64,
    width: 64,
    fontSize: 32,
  },
}))

const Login = () => {
  const { login } = useAuth()
  const classes = useStyles()

  return (
    <Page className={classes.root} title="Login">
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <LockIcon className={classes.icon} />
          <Typography gutterBottom variant="h3">
            Logg inn
          </Typography>
          <Typography variant="subtitle2">
            Operasjon Dagsverk bruker Google som påloggingsmetode og du trenger en e-postadresse fra
            Operasjon Dagsverk. Trykk på knappen og fortsett til påloggingen.
          </Typography>
          <Box mt={2}>
            <Button variant="contained" color="primary" onClick={login}>
              Gå til pålogging
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Page>
  )
}

export default Login
