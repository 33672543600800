import React from 'react'
import { navigate } from '@reach/router'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import qs from 'qs'
import { Page } from 'components'
import SearchBar from './SearchBar'
import Results from './Results'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  heading: {
    marginBottom: theme.spacing(2),
  },
}))

const Enrollments = props => {
  const { location } = props

  const classes = useStyles()

  const handleFilter = values => {
    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true }) || {}
    const newParams = {
      ...queryParams,
      ...values,
      page: 0,
    }
    const newSearchString = qs.stringify(newParams)
    navigate(`/enrollments?${newSearchString}`)
    
  }
  const handleSearch = value => {
    navigate(`/enrollments/?search=${value}`)
  }

  const handleReset = () => {
    navigate(`/enrollments`)
  }

  return (
    <Page className={classes.root} title="Påmeldinger">
      <Typography component="h1" variant="h2" gutterBottom className={classes.heading}>
        Påmeldinger
      </Typography>
      <SearchBar
        onFilter={handleFilter}
        onSearch={handleSearch}
        location={location}
        handleReset={handleReset}
      />
      <Results className={classes.results} location={location} />
    </Page>
  )
}

export default Enrollments
