import React from 'react'
import PropTypes from 'prop-types'
import constants from '../../utils/constants'
import { Label } from 'components'
import { colors } from '@material-ui/core'

const EnrollmentStatusLabelLabel = ({ children }) => {
  if (children === constants.enrollmentStatus.pending)
    return <Label color={colors.grey[400]}>Avventer</Label>

  if (children === constants.enrollmentStatus.approved)
    return <Label color={colors.green[400]}>Bekreftet</Label>

  if (children === constants.enrollmentStatus.rejected)
    return <Label color={colors.deepOrange[400]}>Arkivert</Label>

  if (children === constants.enrollmentStatus.material_sent)
    return <Label color={colors.cyan[600]}>Sendt materiale</Label>
  if (children === constants.enrollmentStatus.accounting_provided)
    return <Label color={colors.cyan[600]}>Regnskap levert</Label>

  return <>Ukjent status</>
}

EnrollmentStatusLabelLabel.propTypes = {
  children: PropTypes.string.isRequired,
}

export default EnrollmentStatusLabelLabel
