import React from 'react'
import { gql } from 'apollo-boost'
import { useMutation, useQuery } from '@apollo/react-hooks'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import { CardContent, Card, CardHeader, colors, CircularProgress, Divider } from '@material-ui/core'
import { Formik, Form, FieldArray } from 'formik'

import LabelsInput from 'components/LabelsInput'
import AutoSave from 'components/AutoSave'
import ErrorMessage from 'components/ErrorMessage'

const GET_LABELS = gql`
  query {
    labels {
      id
      title
      slug
    }
  }
`

const UPDATE_LABELS = gql`
  mutation ($id: ID!, $labels: [ID]!) {
    addLabelsToSchool(id: $id, labels: $labels) {
      id
      labels {
        id
      }
    }
  }
`
const CREATE_OR_UPDATE_LABEL = gql`
  mutation ($title: String!) {
    createOrUpdateLabel(title: $title) {
      title
      id
    }
  }
`

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'visible',
  },
  content: {},
  actions: {
    justifyContent: 'flex-end',
  },
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900],
    },
  },
}))

const SchoolEdit = (props) => {
  const { school, className, ...rest } = props

  const classes = useStyles()

  const [addLabelsToSchool] = useMutation(UPDATE_LABELS)
  const [createOrUpdateLabel] = useMutation(CREATE_OR_UPDATE_LABEL)

  const { data: labelsData, loading: labelsLoading, labelsError, refetch } = useQuery(GET_LABELS)

  if (labelsLoading) return <CircularProgress />
  if (labelsError) return <ErrorMessage>{labelsError.message}</ErrorMessage>

  const allLabels = labelsData.labels

  const labels = school.labels.map((item) => item.id)
  const initialValues = {
    labels: labels || [],
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title="Etiketter" />
      <Divider />
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, actions) => {
          const labels = values.labels

          await addLabelsToSchool({ variables: { id: school.id, labels: labels } })
          refetch()
          actions.setSubmitting(false)
        }}
      >
        {(props) => (
          <Form>
            <CardContent className={classes.content}>
              <FieldArray
                name="labels"
                render={(arrayHelpers) => (
                  <LabelsInput
                    tags={props.values.labels}
                    allTags={allLabels}
                    arrayHelpers={arrayHelpers}
                    onAdd={(tag) => arrayHelpers.push(tag)}
                    handleNewTag={async (title) => {
                      const newLabelResponse = await createOrUpdateLabel({
                        variables: { title: title.trim() },
                      })
                      const newLabel = newLabelResponse.data.createOrUpdateLabel
                      arrayHelpers.push(newLabel.id)
                    }}
                  />
                )}
              />
              {props.dirty && <AutoSave values={props.values.labels} debounceMs={300} />}
            </CardContent>
          </Form>
        )}
      </Formik>
    </Card>
  )
}

SchoolEdit.displayName = 'SchoolEdit'

SchoolEdit.propTypes = {
  className: PropTypes.string,
  customer: PropTypes.any,
  onClose: PropTypes.func,
}

SchoolEdit.defaultProps = {
  onClose: () => {},
}

export default SchoolEdit
