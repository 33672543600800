import React from 'react'
import { Link as RouterLink } from '@reach/router'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import {
  Tabs,
  Tab,
  Divider,
  colors,
  LinearProgress,
  Typography,
  Breadcrumbs,
  Link,
} from '@material-ui/core'
import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import getIdFromSlug from '../../utils/getIDfromSlug'
import { Page } from 'components'
import ErrorMessage from '../../components/ErrorMessage'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  tabs: {
    marginTop: theme.spacing(3),
  },
  divider: {
    backgroundColor: colors.grey[300],
    marginBottom: theme.spacing(3),
  },
  breadcrumb: {
    marginBottom: theme.spacing(2),
  },
}))

const GET_ENROLLMENT = gql`
  query($id: ID!) {
    enrollment(id: $id) {
      id
      school {
        id
        name
      }
      project {
        id
        title
      }
    }
  }
`

const EnrollmentDetails = props => {
  const { slug, children } = props
  const id = getIdFromSlug(slug)
  const { data, loading, error } = useQuery(GET_ENROLLMENT, { variables: { id: id } })

  const classes = useStyles()

  const activeTab = 'summary'
  const tabs = [{ value: 'summary', label: 'Oversikt' }]

  if (loading) return <LinearProgress color="secondary" />
  if (error) return <ErrorMessage>{error.message}</ErrorMessage>

  const {
    school: { name },
    project: { title },
  } = data.enrollment

  return (
    <Page className={classes.root} title={`Påmelding - ${name}`}>
      <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
        <Link component={RouterLink} variant="subtitle1" color="textPrimary" to="/">
          Hjem
        </Link>
        <Link component={RouterLink} variant="subtitle1" color="textPrimary" to="/enrollments">
          Påmeldinger
        </Link>
      </Breadcrumbs>
      <Typography component="h1" variant="h3">
        Påmelding for {name} - {title}
      </Typography>
      <Tabs className={classes.tabs} scrollButtons="auto" value={activeTab} variant="scrollable">
        {tabs.map(tab => (
          <Tab key={tab.value} label={tab.label} value={tab.value} />
        ))}
      </Tabs>
      <Divider className={classes.divider} />
      {children}
    </Page>
  )
}

EnrollmentDetails.propTypes = {
  slug: PropTypes.string,
}

export default EnrollmentDetails
