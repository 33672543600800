import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  List,
  ListItem,
  Typography,
  Grid,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'
import React from 'react'
import constants from 'utils/constants'
import SchoolsActivityTable from 'components/SchoolsActivityTable'

const useStyles = makeStyles((theme) => ({
  content: {
    paddingTop: 0,
  },
  listItem: {
    padding: theme.spacing(2, 0),
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
}))

const UserStatisticsSummary = (props) => {
  const { logs } = props
  const classes = useStyles()
  const year = new Date().getFullYear()
  const currentMonth = new Date().getMonth()

  const contactedSchoolsThisMonth = logs
    .filter((l) => {
      return (
        new Date(l.createdAt) >= new Date(year, currentMonth, 1) &&
        l.type !== constants.logTypes.system
      )
    })
    .map((l) => {
      return l.school
    })
    .filter((school, i, schools) => schools.indexOf(school) === i)

  const labelUpdatesThisMonth = logs.filter((l) => {
    return new Date(l.createdAt) >= new Date(year, currentMonth, 1) &&
      l.type === constants.logTypes.system &&
      l.contentRaw.includes('oppdaterte etikettene')
      ? l.id
      : undefined
  })

  const messagesThisMonth = logs.filter((l) => {
    return new Date(l.createdAt) >= new Date(year, currentMonth, 1) &&
      l.type !== constants.logTypes.system
      ? l.id
      : undefined
  })

  const contactedSchoolsThisYear = logs
    .filter((l) => {
      return new Date(l.createdAt) >= new Date(year, 0, 1) && l.type !== constants.logTypes.system
    })
    .map((l) => {
      return l.school
    })
    .filter((school, i, schools) => schools.indexOf(school) === i)

  const labelUpdatesThisYear = logs.filter((l) => {
    return new Date(l.createdAt) >= new Date(year, 0, 1) &&
      l.type === constants.logTypes.system &&
      l.contentRaw.includes('oppdaterte etikettene')
      ? l.id
      : undefined
  })
  const messagesThisYear = logs.filter((l) => {
    return new Date(l.createdAt) >= new Date(year, 0, 1) && l.type !== constants.logTypes.system
      ? l.id
      : undefined
  })

  return (
    <Grid container spacing={3}>
      <Grid item lg={12} md={12} xl={12} xs={12}>
        <Card className={classes.root}>
          <CardHeader title="Aktivitet denne måneden" />
          <Divider />
          <CardContent className={classes.content}>
            <List>
              <ListItem className={classes.listItem} disableGutters divider>
                <Typography variant="subtitle2">Antall kontaktede skoler</Typography>
                <Typography variant="h6">{contactedSchoolsThisMonth.length}</Typography>
              </ListItem>
              <ListItem className={classes.listItem} disableGutters divider>
                <Typography variant="subtitle2">Antall etikettoppdateringer</Typography>
                <Typography variant="h6">{labelUpdatesThisMonth.length}</Typography>
              </ListItem>
              <ListItem className={classes.listItem} disableGutters divider>
                <Typography variant="subtitle2">Antall meldinger</Typography>
                <Typography variant="h6">{messagesThisMonth.length}</Typography>
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </Grid>
      {contactedSchoolsThisMonth.length > 0 && (
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <SchoolsActivityTable
            title="Skoler kontaktet denne måneden"
            schools={contactedSchoolsThisMonth}
            columns={constants.columns.contactedSchools}
          />
        </Grid>
      )}
      <Grid item lg={12} md={12} xl={12} xs={12}>
        <Card className={classes.root}>
          <CardHeader title="Aktivitet i år" />
          <Divider />
          <CardContent className={classes.content}>
            <List>
              <ListItem className={classes.listItem} disableGutters divider>
                <Typography variant="subtitle2">Antall kontaktede skoler</Typography>
                <Typography variant="h6">{contactedSchoolsThisYear.length}</Typography>
              </ListItem>
              <ListItem className={classes.listItem} disableGutters divider>
                <Typography variant="subtitle2">Antall etikettoppdateringer</Typography>
                <Typography variant="h6">{labelUpdatesThisYear.length}</Typography>
              </ListItem>
              <ListItem className={classes.listItem} disableGutters divider>
                <Typography variant="subtitle2">Antall meldinger</Typography>
                <Typography variant="h6">{messagesThisYear.length}</Typography>
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </Grid>
      {contactedSchoolsThisYear.length > 0 && (
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <SchoolsActivityTable
            title="Skoler kontaktet i år"
            schools={contactedSchoolsThisYear}
            columns={constants.columns.contactedSchools}
          />
        </Grid>
      )}
    </Grid>
  )
}

UserStatisticsSummary.propTypes = {
  logs: PropTypes.object.isRequired,
}

export default UserStatisticsSummary
