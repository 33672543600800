import { useQuery } from '@apollo/react-hooks'
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Link as RouterLink } from '@reach/router'
import { gql } from 'apollo-boost'
import ContactRole from 'components/ContactRole'
import ErrorMessage from 'components/ErrorMessage'
import ResultsTablePagination from 'components/ResultsTablePagination'
import SkeletonTable from 'components/SkeletonTable'
import PropTypes from 'prop-types'
import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import constants from 'utils/constants'
import { useQueryParams } from 'utils/hooks'
import getInitials from 'utils/getInitials'

export const GET_CONTACTS = gql`
  query ($filter: ContactFilterInput!) {
    contactsSearch(filter: $filter) {
      items {
        id
        slug
        fullName
        firstName
        lastName
        email
        phoneNumber
        type
        enrollment {
          id
          project {
            id
            title
            year
          }
          school {
            id
            name
          }
        }
      }
      total
    }
  }
`

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 700,
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1),
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: 'flex-end',
  },
  row: {
    whiteSpace: 'nowrap',
  },
}))

const Results = (props) => {
  const { location } = props
  const classes = useStyles()
  const { queryVariables, page, searchString, rowsPerPage } = useQueryParams(location)

  // Request data
  const { data, loading, error, client } = useQuery(GET_CONTACTS, {
    variables: { filter: queryVariables },
  })

  // SkeletonView
  if (loading)
    return (
      <SkeletonTable
        searchString={searchString}
        rowsPerPage={rowsPerPage}
        columns={constants.columns.contacts}
      />
    )

  if (error) return <ErrorMessage>{error.message}</ErrorMessage>

  const { items: contacts, total } = data.contactsSearch

  return (
    <div className={classes.root}>
      <Typography color="textSecondary" gutterBottom variant="body2">
        {total} Resultater. Side {page + 1} av {Math.ceil(total / rowsPerPage)}
      </Typography>
      <Card>
        <CardHeader title={searchString === '' ? 'Alle personer' : `Søk på ${searchString}`} />
        <Divider />
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    {constants.columns.contacts.map((column) => (
                      <TableCell key={column}>{column}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {contacts.map((item) => {
                    return (
                      <TableRow hover key={item.id} className={classes.row}>
                        <TableCell>
                          <div className={classes.nameCell}>
                            <Avatar className={classes.avatar} src={item.avatar}>
                              {getInitials(item.fullName)}
                            </Avatar>
                            <div>
                              <Link
                                color="inherit"
                                component={RouterLink}
                                to={`/contacts/${item.slug}`}
                                variant="h6"
                              >
                                {item.fullName}
                              </Link>
                              <div>{item.email}</div>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>{item.phoneNumber}</TableCell>
                        <TableCell>
                          <ContactRole>{item.type}</ContactRole>
                        </TableCell>
                        <TableCell>
                          {item.enrollment && item.enrollment.school && item.enrollment.school.name}
                        </TableCell>
                        <TableCell>
                          {item.enrollment &&
                            item.enrollment.project &&
                            item.enrollment.project.title}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <ResultsTablePagination
            location={location}
            page={page}
            total={total}
            rowsPerPage={rowsPerPage}
            queryVariables={queryVariables}
            client={client}
            query={GET_CONTACTS}
          />
        </CardActions>
      </Card>
    </div>
  )
}

Results.propTypes = {
  location: PropTypes.object,
}

export default Results
