import { useQuery } from '@apollo/react-hooks'
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  colors,
  Divider,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Link as RouterLink } from '@reach/router'
import { gql } from 'apollo-boost'
import clsx from 'clsx'
import ErrorMessage from 'components/ErrorMessage'
import ResultsTablePagination from 'components/ResultsTablePagination'
import SkeletonTable from 'components/SkeletonTable'
import PropTypes from 'prop-types'
import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import constants from 'utils/constants'
import { useQueryParams } from 'utils/hooks'
import getInitials from 'utils/getInitials'

const GET_DISTRICTS = gql`
  query($filter: DistrictFilterInput!) {
    districtsSearch(filter: $filter) {
      items {
        id
        slug
        title
        numberOfSchools
        numberOfEnrollments
      }
      total
    }
  }
`

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 700,
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1),
    backgroundColor: colors.teal['A700'],
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: 'flex-end',
  },
  link: {
    textDecoration: 'none',
    color: colors.black,
    '&:hover': {
      textDecoration: 'none',
    },
  },
}))

const Results = props => {
  const { className, location, ...rest } = props
  const classes = useStyles()
  const { queryVariables, page, searchString, rowsPerPage } = useQueryParams(location)

  // Request data
  const { data, loading, error, client } = useQuery(GET_DISTRICTS, {
    variables: { filter: queryVariables },
  })

  // SkeletonView
  if (loading)
    return (
      <SkeletonTable
        searchString={searchString}
        rowsPerPage={rowsPerPage}
        columns={constants.columns.districts}
      />
    )

  if (error) return <ErrorMessage>{error.message}</ErrorMessage>

  const districts = data.districtsSearch.items
  const { total } = data.districtsSearch

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Typography color="textSecondary" gutterBottom variant="body2">
        {total} resultater. Side {page + 1} av {Math.ceil(total / rowsPerPage)}
      </Typography>
      <Card>
        <CardHeader title={searchString === '' ? 'Alle påmeldinger' : `Søk på ${searchString}`} />
        <Divider />
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    {constants.columns.districts.map(column => (
                      <TableCell key={column}>{column}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {districts.map(district => {
                    return (
                      <TableRow hover key={district.id}>
                        <TableCell>
                          <Link
                            color="inherit"
                            component={RouterLink}
                            className={classes.link}
                            to={`/districts/${district.slug}`}
                          >
                            <div className={classes.nameCell}>
                              <Avatar className={classes.avatar} src={district.avatar}>
                                {getInitials(district.title)}
                              </Avatar>
                              <Typography variant="h6">{district.title}</Typography>
                            </div>
                          </Link>
                        </TableCell>
                        <TableCell>{district.numberOfSchools}</TableCell>
                        <TableCell>{district.numberOfEnrollments}</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <ResultsTablePagination
            location={location}
            page={page}
            total={total}
            rowsPerPage={rowsPerPage}
            queryVariables={queryVariables}
            client={client}
            query={GET_DISTRICTS}
          />
        </CardActions>
      </Card>
    </div>
  )
}

Results.propTypes = {
  className: PropTypes.string,
}

Results.defaultProps = {}

export default Results
