import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import Downshift from 'downshift'
import { TextField, Paper, MenuItem } from '@material-ui/core'
import AutoSave from 'components/AutoSave'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },

  formGroup: {
    position: 'relative',
  },

  paper: {
    position: 'absolute',
    zIndex: 2,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
    background: '#fff',
  },

  buttonIcon: {
    marginRight: theme.spacing(1),
  },

  fieldGroup: {
    display: 'flex',
    alignItems: 'center',
  },
  field: {
    marginTop: 0,
    marginBottom: 0,
  },
  flexGrow: {
    flexGrow: 1,
  },
  addButton: {
    marginLeft: theme.spacing(1),
  },
  users: {
    marginTop: theme.spacing(1),
  },
  chip: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}))

const UsersInput = (props) => {
  const { users, arrayHelpers, allUsers, label } = props
  const classes = useStyles()

  let usersByid = {}

  allUsers.map((item) => (usersByid[item.id] = item))
  const filteredAllUsers = allUsers.filter((item) => users.indexOf(item.id) === -1)

  const handleUserSelect = (selection, stateAndHelpers) => {
    if (selection) {
      // Add it to formik state and clear the input
      arrayHelpers.push(selection.id)
      stateAndHelpers.clearSelection()
    }
  }

  return (
    <div className={classes.formGroup}>
      <Downshift onChange={handleUserSelect} itemToString={(item) => (item ? item.fullName : '')}>
        {({
          getInputProps,
          getItemProps,
          getMenuProps,
          isOpen,
          inputValue,
          highlightedIndex,
          selectedItem,
        }) => (
          <div>
            <div className={classes.fieldGroup}>
              <TextField
                {...getInputProps()}
                className={clsx(classes.field, classes.flexGrow)}
                label={label}
                margin="dense"
                name="user"
                variant="outlined"
                onBlur={(e) => {
                  e.preventDefault()
                }}
              />
            </div>
            <div {...getMenuProps()}>
              {isOpen ? (
                <Paper className={classes.paper} square>
                  {filteredAllUsers
                    .filter(
                      (item) =>
                        !inputValue ||
                        item.fullName.toLowerCase().includes(inputValue.toLowerCase())
                    )
                    .map((item, index) => {
                      return (
                        <MenuItem
                          tabIndex={index}
                          as="button"
                          {...getItemProps({
                            key: item.id,
                            index,
                            item,
                            style: {
                              backgroundColor: highlightedIndex === index ? 'lightgray' : 'white',
                              fontWeight: selectedItem === item ? 'bold' : 'normal',
                            },
                          })}
                        >
                          {item.fullName}
                        </MenuItem>
                      )
                    })}
                </Paper>
              ) : null}
            </div>
          </div>
        )}
      </Downshift>
      <AutoSave debounceMs={0} values={users} />
    </div>
  )
}

UsersInput.propTypes = {
  users: PropTypes.array.isRequired,
  arrayHelpers: PropTypes.object.isRequired,
}

UsersInput.defaultProps = {
  label: 'Søk',
}

export default UsersInput
