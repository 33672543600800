import { useQuery } from '@apollo/react-hooks'
import {
  Button,
  Checkbox,
  Drawer,
  FormControlLabel,
  FormHelperText,
  Typography,
  FormGroup,
  CircularProgress,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import { makeStyles } from '@material-ui/styles'
import { gql } from 'apollo-boost'
import clsx from 'clsx'
import LabelsInput from 'components/LabelsInput'
import { Field, FieldArray, Formik } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import constants from 'utils/constants'
import { getEnrollmentStatuses, getEnrollmentTypes } from 'utils/enrollmentsHelper'
import SchoolType from 'components/SchoolType'
import ErrorMessage from 'components/ErrorMessage'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  drawer: {
    width: 420,
    maxWidth: '100%',
  },
  header: {
    padding: theme.spacing(2, 1),
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
  content: {
    padding: theme.spacing(0, 3),
    flexGrow: 1,
  },
  contentSection: {
    padding: theme.spacing(2, 0),
  },
  contentSectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  contentSectionContent: {},
  formGroup: {
    padding: theme.spacing(2, 0),
  },
  field: {
    marginTop: 0,
    marginBottom: 0,
  },
  flexGrow: {
    flexGrow: 1,
  },
  addButton: {
    marginLeft: theme.spacing(1),
  },
  tags: {
    marginTop: theme.spacing(1),
  },
  minAmount: {
    marginRight: theme.spacing(3),
  },
  maxAmount: {
    marginLeft: theme.spacing(3),
  },
  radioGroup: {},
  actions: {
    padding: theme.spacing(3),
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}))

const GET_LABELS = gql`
  query {
    labels {
      id
      title
      slug
    }
  }
`

const GET_PROJECTS = gql`
  query {
    projects {
      id
      title
      slug
    }
  }
`

function FormikCheckbox(props) {
  return (
    <Field name={props.name}>
      {({ field, form }) => (
        <FormControlLabel
          control={
            <Checkbox
              as="input"
              type="checkbox"
              {...props}
              checked={field?.value?.includes(props.value)}
              onChange={() => {
                if (field.value.includes(props.value)) {
                  const nextValue = field.value.filter((value) => value !== props.value)
                  form.setFieldValue(props.name, nextValue)
                } else {
                  const nextValue = field.value.concat(props.value)
                  form.setFieldValue(props.name, nextValue)
                }
              }}
            />
          }
          label={props.label}
        />
      )}
    </Field>
  )
}

const Filter = (props) => {
  const { open, onClose, onFilter, className, initialValues, onReset, ...rest } = props

  const classes = useStyles()

  const handleClear = () => {
    // setValues({ ...initialValues })
    // onFilter && onFilter(values)
    onReset()
    onClose()
  }

  const { data: labelsData, loading: labelsLoading, error: labelsError } = useQuery(GET_LABELS)
  const { data: projectsData, loading: projectsLoading, error: projectsError } = useQuery(
    GET_PROJECTS
  )

  if (labelsLoading || projectsLoading) return <CircularProgress size={20} />
  if (labelsError || projectsError)
    return (
      <ErrorMessage>
        {labelsError?.message} {projectsError?.message}
      </ErrorMessage>
    )

  const allLabels = labelsData.labels
  const allProjects = projectsData.projects
  const allEnrollmentStatuses = getEnrollmentStatuses()
  const allEnrollmentTypes = getEnrollmentTypes()

  return (
    <Drawer
      anchor="right"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant="temporary"
    >
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, actions) => {
          onFilter && onFilter({ ...values })
          actions.setSubmitting(false)
          onClose()
        }}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit} {...rest} className={clsx(classes.root, className)}>
            <div className={classes.header}>
              <Button onClick={onClose} size="small">
                <CloseIcon className={classes.buttonIcon} />
                Lukk
              </Button>
            </div>
            <div className={classes.content}>
              <div className={classes.contentSection}>
                <div className={classes.contentSectionContent}>
                  <div className={classes.formGroup}>
                    <Typography component="p" gutterBottom variant="overline">
                      Filtrer på etiketter
                    </Typography>
                    <FieldArray
                      name="labels"
                      render={(arrayHelpers) => (
                        <LabelsInput
                          label="Søk etter etiketter"
                          tags={props.values.labels}
                          allTags={allLabels}
                          arrayHelpers={arrayHelpers}
                          onAdd={(tag) => arrayHelpers.push(tag)}
                        />
                      )}
                    />
                  </div>
                  <div className={classes.formGroup}>
                    <Typography component="p" gutterBottom variant="overline">
                      Filtrer på prosjekt
                    </Typography>

                    <FieldArray
                      name="projects"
                      render={(arrayHelpers) => (
                        <LabelsInput
                          label="Søk etter prosjekt"
                          tags={props.values.projects}
                          allTags={allProjects}
                          arrayHelpers={arrayHelpers}
                          onAdd={(tag) => arrayHelpers.push(tag)}
                        />
                      )}
                    />
                    <FormHelperText>
                      Velg prosjekt før du kan filtrere på påmeldingstatus og påmeldingstype
                    </FormHelperText>
                  </div>

                  <div
                    className={classes.formGroup}
                    style={
                      props.values.projects.length === 0
                        ? { pointerEvents: 'none', opacity: '0.4' }
                        : {}
                    }
                  >
                    <Typography component="p" gutterBottom variant="overline">
                      Filtrer på påmeldingstatus
                    </Typography>

                    <FieldArray
                      name="enrollmentStatuses"
                      render={(arrayHelpers) => (
                        <LabelsInput
                          label="Søk etter påmeldingstatus"
                          tags={props.values.enrollmentStatuses}
                          allTags={allEnrollmentStatuses}
                          arrayHelpers={arrayHelpers}
                          onAdd={(tag) => {
                            arrayHelpers.push(tag)
                          }}
                        />
                      )}
                    />
                  </div>

                  <div
                    className={classes.formGroup}
                    style={
                      props.values.projects.length === 0 ||
                      props.values.enrollmentStatuses.includes(constants.enrollmentStatus.unknown)
                        ? { pointerEvents: 'none', opacity: '0.4' }
                        : {}
                    }
                  >
                    <Typography component="p" gutterBottom variant="overline">
                      Filtrer på påmeldingtype
                    </Typography>

                    <FieldArray
                      name="enrollmentTypes"
                      render={(arrayHelpers) => (
                        <LabelsInput
                          label="Søk etter påmeldingtype"
                          tags={props.values.enrollmentTypes}
                          allTags={allEnrollmentTypes}
                          arrayHelpers={arrayHelpers}
                          onAdd={(tag) => arrayHelpers.push(tag)}
                        />
                      )}
                    />
                  </div>

                  <div className={classes.formGroup}>
                    <Typography component="p" gutterBottom variant="overline">
                      Skolenivå
                    </Typography>
                    <FormGroup className={classes.fieldGroup}>
                      <FormikCheckbox
                        color="primary"
                        name="types"
                        value={constants.schoolTypes.barneskole}
                        label={<SchoolType>{constants.schoolTypes.barneskole}</SchoolType>}
                      />
                      <FormikCheckbox
                        color="primary"
                        name="types"
                        value={constants.schoolTypes.ungdomsskole}
                        label={<SchoolType>{constants.schoolTypes.ungdomsskole}</SchoolType>}
                      />
                      <FormikCheckbox
                        color="primary"
                        name="types"
                        value={constants.schoolTypes.videregaaende}
                        label={<SchoolType>{constants.schoolTypes.videregaaende}</SchoolType>}
                      />
                      <FormikCheckbox
                        color="primary"
                        name="types"
                        value={constants.schoolTypes.folkehoyskole}
                        label={<SchoolType>{constants.schoolTypes.folkehoyskole}</SchoolType>}
                      />
                    </FormGroup>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.actions}>
              <Button fullWidth onClick={handleClear} variant="contained">
                <DeleteIcon className={classes.buttonIcon} />
                Tilbakestill
              </Button>
              <Button color="primary" fullWidth type="submit" variant="contained">
                Bruk filtre
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </Drawer>
  )
}

Filter.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  onFilter: PropTypes.func,
  open: PropTypes.bool.isRequired,
}

export default Filter
