import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Link,
  Typography,
  TextField,
  DialogActions,
  CircularProgress,
} from '@material-ui/core'
import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'
import { makeStyles } from '@material-ui/styles'
import { Link as RouterLink } from '@reach/router'
import FormattedDate from 'components/FormattedDate'
import PropTypes from 'prop-types'
import React from 'react'
import { Formik, Form } from 'formik'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
  },
  mainContact: {
    marginBottom: theme.spacing(3),
  },
  header: {
    paddingBottom: 0,
  },
  content: {
    paddingTop: theme.spacing(3),
  },
  cardHeader: {
    alignItems: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    // paddingRight: theme.spacing(3),
    // paddingLeft: theme.spacing(3),
  },
  cardHeaderButton: {
    margin: 0,
  },
  description: {
    whiteSpace: 'pre-wrap',
  },
}))

const UPDATE_DESCRIPTION = gql`
  mutation ($id: ID!, $description: String) {
    addDescriptionToSchool(id: $id, description: $description) {
      id
      description
    }
  }
`

const Description = (props) => {
  const { school, className, refetch, ...rest } = props
  const [editMode, setEditMode] = React.useState(false)

  const classes = useStyles()

  return (
    <Card className={clsx(classes.mainContact, className)}>
      <CardHeader
        classes={{
          action: classes.cardHeaderButton,
        }}
        className={classes.cardHeader}
        title="Beskrivelse"
        action={
          <Button
            disabled={editMode}
            aria-label="edit"
            color="primary"
            onClick={() => setEditMode(!editMode)}
          >
            Rediger
          </Button>
        }
      />
      <Divider />
      <CardContent className={classes.content}>
        {!editMode ? (
          <>
            <Typography variant="body1" gutterBottom className={classes.description}>
              {school.description || 'Ingen beskrivelse'}
            </Typography>
            {school.descriptionUpdatedAt && school.descriptionUpdatedBy && (
              <Typography variant="body2">
                Sist oppdatert{' '}
                <FormattedDate showTime={true}>{school.descriptionUpdatedAt}</FormattedDate> av{' '}
                <Link
                  color="textPrimary"
                  variant="body2"
                  underline="always"
                  component={RouterLink}
                  to={`/users/${school.descriptionUpdatedBy.slug}`}
                >
                  {school.descriptionUpdatedBy.fullName}
                </Link>
              </Typography>
            )}
          </>
        ) : (
          <EditForm
            description={school.description}
            setEditMode={setEditMode}
            schoolId={school.id}
            refetch={refetch}
          />
        )}
      </CardContent>
    </Card>
  )
}

const EditForm = ({ description, setEditMode, refetch, schoolId }) => {
  const initialValues = { description }
  const [updateDescription] = useMutation(UPDATE_DESCRIPTION)

  const onSubmitSuccess = async () => {
    await refetch()
    setEditMode(false)
  }

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, actions) => {
          actions.setSubmitting(true)

          try {
            await updateDescription({
              variables: {
                id: schoolId,
                description: values.description,
              },
            })
            actions.setSubmitting(false)
            onSubmitSuccess()
          } catch (e) {
            actions.setSubmitting(false)
            actions.setStatus(e.message || 'Ukjent feil. Prøv igjen senere.')
          }
        }}
      >
        {(props) => (
          <Form autoComplete="off">
            <TextField
              helperText={
                props.touched.description && (props.errors.description || props.status)
                  ? props.errors.description || props.status
                  : ' '
              }
              margin="dense"
              fullWidth
              required
              autoFocus
              multiline
              minRows={6}
              label="Beskrivelse"
              variant="outlined"
              placeholder="Legg til en beskrivelse"
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values.description}
              name="description"
            />
            <DialogActions>
              <Button
                disabled={props.isSubmitting}
                type="button"
                onClick={() => setEditMode(false)}
              >
                Avbryt
              </Button>
              <Button
                variant="contained"
                disabled={!props.isValid || props.isSubmitting}
                color="primary"
                type="submit"
                startIcon={props.isSubmitting && <CircularProgress size={18} />}
              >
                {props.isSubmitting ? 'Lagrer...' : 'Lagre'}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </div>
  )
}

Description.propTypes = {
  className: PropTypes.string,
  school: PropTypes.object.isRequired,
}

export default Description
