import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { Typography, Avatar, colors, Button, TextField } from '@material-ui/core'
import EditIcon from '@material-ui/icons/EditOutlined'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import CheckIcon from '@material-ui/icons/CheckRounded'
import CancelIcon from '@material-ui/icons/CancelRounded'
import { useAuth } from 'react-use-auth'
import getInitials from '../../../utils/getInitials'
import RichTextMessage from 'components/RichTextMessage'
import FormattedDate from 'components/FormattedDate'
import MUIRichTextEditor from 'mui-rte'
import { convertToRaw } from 'draft-js'

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  authUser: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& $body': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  inner: {
    display: 'flex',
    maxWidth: 500,
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  body: {
    backgroundColor: colors.grey[100],
    color: theme.palette.text.primary,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1, 2),
  },
  editBody: {
    backgroundColor: colors.blueGrey[50],
    color: theme.palette.text.secondary,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1, 2),
  },
  content: {
    marginTop: theme.spacing(1),
  },
  actionIcons: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  footer: {
    marginTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  time: {
    paddingBottom: '3em',
  },
}))

function createMarkup(markup) {
  return { __html: markup }
}

const ConversationMessage = (props) => {
  const { message, handleUpdate, handleDelete } = props
  const classes = useStyles()
  const { user } = useAuth()
  const [editMode, setEditMode] = useState(false)
  const [editedState, setEditedState] = useState(null)
  const [editConfirmDisabled, setEditConfirmDisabled] = useState(true)

  const isMe = (message.user && message.user.auth0UserId) === user.sub

  const toggleEditMode = function () {
    setEditMode(!editMode)
  }

  if (message.type === 'system' || message.type === 'message') {
    return (
      <div className={classes.inner}>
        <div className={classes.content}>
          <Typography
            color="textSecondary"
            variant="body1"
            dangerouslySetInnerHTML={createMarkup(message.contentRaw)}
          ></Typography>
          <Typography className={classes.time} variant="body2">
            <FormattedDate showTime={true}>{message.createdAt}</FormattedDate>
          </Typography>
        </div>
      </div>
    )
  }

  return (
    <div
      className={clsx(classes.root, {
        [classes.authUser]: isMe,
      })}
    >
      <div className={classes.inner}>
        <Avatar className={classes.avatar} src={message.user?.profileImage}>
          {getInitials(message?.user?.fullName || message.userFullName)}
        </Avatar>
        <div>
          <div className={!editMode ? classes.body : classes.editBody}>
            <Typography color="inherit" variant="h6">
              {message?.user?.fullName || message.userFullName}
            </Typography>
            <div className={classes.content}>
              {message.recipientFullName && (
                <Typography color="inherit" variant="body1">
                  Ringt til {message.recipientFullName}.
                </Typography>
              )}
              {message.content && message.content !== '' ? (
                <div>
                  {!editMode && <RichTextMessage content={message.content}></RichTextMessage>}
                  {editMode && (
                    <MUIRichTextEditor
                      defaultValue={message.content}
                      controls={[]}
                      onChange={(state) => {
                        setEditedState(state)
                        setEditConfirmDisabled(!state.getCurrentContent().hasText() ? true : false)
                      }}
                    />
                  )}
                </div>
              ) : (
                <Typography
                  color="inherit"
                  variant="body1"
                  dangerouslySetInnerHTML={createMarkup(message.contentRaw)}
                ></Typography>
              )}
            </div>
          </div>
          <div className={classes.footer}>
            <Typography className={classes.time} variant="body2">
              <FormattedDate showTime={true}>{message.updatedAt}</FormattedDate>
            </Typography>
          </div>
        </div>
        {isMe && message.type === 'call' && (
          <div>
            {!editMode && (
              <div className={classes.actionIcons}>
                <Button startIcon={<EditIcon />} onClick={toggleEditMode} color="primary" />
                <Button
                  startIcon={<DeleteIcon />}
                  onClick={async () => {
                    await handleDelete(message)
                  }}
                  color="secondary"
                />
              </div>
            )}
            {editMode && (
              <div className={classes.actionIcons}>
                <Button
                  startIcon={<CheckIcon />}
                  onClick={async () => {
                    message.content = JSON.stringify(convertToRaw(editedState.getCurrentContent()))
                    message.contentRaw = editedState.getCurrentContent().getPlainText()
                    await handleUpdate(message)
                    toggleEditMode()
                  }}
                  disabled={editConfirmDisabled}
                  color="primary"
                />
                <Button startIcon={<CancelIcon />} onClick={toggleEditMode} color="secondary" />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

ConversationMessage.propTypes = {
  message: PropTypes.object.isRequired,
  handleUpdate: PropTypes.func,
  handleDelete: PropTypes.func,
}

export default ConversationMessage
