import React from 'react'
import PropTypes from 'prop-types'

const Pupils = ({ children }) => {
  if (children === undefined) return <>NaN</>
  if (children === 0) return <>Ukjent</>
  return <>{Intl.NumberFormat('nb-NO').format(children)}</>
}

Pupils.propTypes = {
  children: PropTypes.number,
}

export default Pupils
