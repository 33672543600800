import React from 'react'
import redraft from 'redraft'
import { Typography, Link } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    // color: '#fff',
  },
}))

// just a helper to add a <br /> after a block
const addBreaklines = (children) => children.map((child) => [child, <br />])

/**
 * Define the renderers
 */
const renderers = {
  /**
   * Those callbacks will be called recursively to render a nested structure
   */
  inline: {
    // The key passed here is just an index based on rendering order inside a block
    BOLD: (children, { key }) => <strong key={key}>{children}</strong>,
    ITALIC: (children, { key }) => <em key={key}>{children}</em>,
    UNDERLINE: (children, { key }) => <u key={key}>{children}</u>,
    CODE: (children, { key }) => <span key={key}>{children}</span>,
  },
  /**
   * Blocks receive children and depth
   * Note that children are an array of blocks with same styling,
   */
  blocks: {
    unstyled: (children) =>
      children.map((child, index) => (
        <Typography color="inherit" as="p" key={index}>
          {child}
        </Typography>
      )),
    blockquote: (children) => <blockquote>{addBreaklines(children)}</blockquote>,
    'header-one': (children) =>
      children.map((child, index) => (
        <Typography color="inherit" variant="h1" key={index}>
          {child}
        </Typography>
      )),
    'header-two': (children) =>
      children.map((child, index) => (
        <Typography color="inherit" variant="h2" key={index}>
          {child}
        </Typography>
      )),
    // You can also access the original keys of the blocks
    'code-block': (children, { keys }) => <pre key={keys[0]}>{addBreaklines(children)}</pre>,
    // or depth for nested lists
    'unordered-list-item': (children, { depth, keys }) => (
      <Typography
        color="inherit"
        component="ul"
        ml={3}
        key={keys[keys.length - 1]}
        className={`ul-level-${depth}`}
      >
        {children.map((child, index) => (
          <li key={keys[index]}>{child}</li>
        ))}
      </Typography>
    ),
    'ordered-list-item': (children, { depth, keys }) => (
      <Typography
        color="inherit"
        component="ol"
        ml={3}
        key={keys.join('|')}
        className={`ol-level-${depth}`}
      >
        {children.map((child, index) => (
          <li key={keys[index]}>{child}</li>
        ))}
      </Typography>
    ),
  },
  /**
   * Entities receive children and the entity data
   */
  entities: {
    // key is the entity key value from raw
    LINK: (children, data, { key }) => (
      <Link underline="always" color="inherit" key={key} href={data.url}>
        {children}
      </Link>
    ),
  },
}

const RichTextMessage = (message) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {message.content && redraft(JSON.parse(message.content), renderers)}
    </div>
  )
}

export default RichTextMessage
