import React from 'react'
import PropTypes from 'prop-types'
import { navigate } from '@reach/router'
import { Card, CardHeader, CardContent, Divider } from '@material-ui/core'
import EnrollmentForm from '../../../components/Forms/EnrollmentForm'

const EnrollmentEdit = props => {
  const { enrollment, refetch } = props

  const handleCancel = () => {
    navigate('./')
  }

  const onSubmitSuccess = async () => {
    await refetch()
    navigate('./')
  }

  return (
    <Card>
      <CardHeader title="Påmeldingsinfo" />
      <Divider />
      <CardContent>
        <EnrollmentForm
          enrollment={enrollment}
          handleCancel={handleCancel}
          onSubmitSuccess={onSubmitSuccess}
        />
      </CardContent>
    </Card>
  )
}

EnrollmentEdit.propTypes = {
  enrollment: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
}

export default EnrollmentEdit
