import React, { Fragment, useRef, useState, memo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Tooltip, IconButton, Menu } from '@material-ui/core'
import MoreIcon from '@material-ui/icons/MoreVert'

const useStyles = makeStyles(() => ({}))

const GenericMoreButton = props => {
  const classes = useStyles()
  const moreRef = useRef(null)
  const [openMenu, setOpenMenu] = useState(false)
  const { children, ...rest } = props
  const handleMenuOpen = () => {
    setOpenMenu(true)
  }

  const handleMenuClose = () => {
    setOpenMenu(false)
  }

  return (
    <Fragment>
      <Tooltip title="More options">
        <IconButton {...rest} onClick={handleMenuOpen} ref={moreRef} size="small">
          <MoreIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={moreRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        classes={{ paper: classes.menu }}
        onClose={handleMenuClose}
        open={openMenu}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        {children}
      </Menu>
    </Fragment>
  )
}

GenericMoreButton.propTypes = {
  className: PropTypes.string
}

export default memo(GenericMoreButton)
