export default {
  contactTypes: Object.freeze({
    teacher: 'teacher',
    student: 'student',
  }),
  userTypes: Object.freeze({
    user: 'user',
    admin: 'admin',
  }),
  userStatuses: Object.freeze({
    active: 'active',
    inactive: 'inactive',
  }),
  logTypes: Object.freeze({
    call: 'call',
    message: 'message',
    system: 'system',
  }),
  schoolTypes: Object.freeze({
    barneskole: 'barneskole',
    ungdomsskole: 'ungdomsskole',
    videregaaende: 'videregaaende',
    folkehoyskole: 'folkehoyskole',
    annet: 'annet',
    spesialskole: 'spesialskole',
  }),
  enrollmentStatus: Object.freeze({
    unknown: 'unknown',
    pending: 'pending',
    approved: 'approved',
    rejected: 'rejected',
    material_sent: 'material_sent',
    accounting_provided: 'accounting_provided',
  }),
  enrollmentTypes: Object.freeze({
    odDay: 'odDay',
    iu: 'iu',
    odSchool: 'odSchool',
  }),
  columns: Object.freeze({
    enrollments: [
      'Skole',
      'Distrikt',
      'Skolenivå',
      'Prosjekt',
      'Status',
      'Type',
      'Dato',
      'Totalsum',
      'Hovedkontakt',
    ],
    schools: [
      'Skole',
      'Sted',
      'Distrikt',
      'Skolenivå',
      'Ansvarlig i OD',
      'Etiketter',
      'Påmelding',
      'Siste påmelding',
      'Siste kontakt',
    ],
    mySchools: [
      'Skole',
      'Sted',
      'Distrikt',
      'Skolenivå',
      'Etiketter',
      'Påmelding',
      'Siste påmelding',
      'Siste kontakt',
    ],
    contactedSchools: ['Skole', 'Siste kontakt', 'Siste etikettoppdatering'],
    districts: ['Distrikt', 'Antall skoler', 'Godkjente påmeldinger'],
    contacts: ['Navn', 'Telefon', 'Type', 'Skole', 'Prosjekt'],
    users: ['Navn', 'Mobil', 'Distrikt', 'Rolle', 'Skoler', 'Verktøy'],
    activities: [
      'Navn',
      'Antall kontaktede skoler',
      'Antall meldinger',
      'Antall etikettoppdateringer',
    ],
    labels: ['Navn', 'Opprettet', 'Oppdatert', 'Verktøy'],
  }),

  exportTypes: Object.freeze({
    csv: 'csv',
    xls: 'xls',
  }),
  projectStatuses: Object.freeze({
    open: 'open',
    closed: 'closed',
  }),
  sixDaysInMs: 1000 * 60 * 60 * 24 * 6,
}
