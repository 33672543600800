import { useQuery } from '@apollo/react-hooks'
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  colors,
  Divider,
  TablePagination,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { navigate } from '@reach/router'
import { gql } from 'apollo-boost'
import ErrorMessage from 'components/ErrorMessage'
import SchoolsTable from 'components/SchoolsTable'
import SkeletonTable from 'components/SkeletonTable'
import PropTypes from 'prop-types'
import qs from 'qs'
import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import constants from 'utils/constants'
import getIdFromSlug from 'utils/getIDfromSlug'

const GET_SCHOOLS = gql`
  query ($filter: SchoolFilterInput!) {
    schoolsSearch(filter: $filter) {
      items {
        id
        name
        orgNr
        NSRId
        details {
          visitAddress {
            place
          }
        }
        district {
          id
          title
        }
        email
        types
        status
        slug
        labels {
          id
          title
        }
        responsible {
          id
          slug
          fullName
        }
        enrollments {
          id
          status
          types
          project {
            id
            title
            year
            status
          }
          createdAt
        }
        latestContactAt
        latestLabelUpdateAt
      }
      total
    }
  }
`

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 700,
  },
  cell: {
    whiteSpace: 'nowrap',
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1),
    backgroundColor: colors.orange[400],
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: 'flex-end',
  },
  link: {
    textDecoration: 'none',
    color: colors.black,
    '&:hover': {
      textDecoration: 'none',
    },
  },
  error: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    padding: theme.spacing(4, 4),
  },
}))

const DistrictSchools = (props) => {
  const { location, slug } = props
  const classes = useStyles()
  const districtId = getIdFromSlug(slug)
  let queryVariables = {}
  const searchQuery = qs.parse(location.search, { ignoreQueryPrefix: true })
  const currentPage = searchQuery.page || ''
  const page = currentPage ? parseInt(currentPage, 10) : 0
  const searchString = searchQuery.search || ''
  const rowsPerPage = searchQuery.rowsPerPage ? parseInt(searchQuery.rowsPerPage, 10) : 25

  queryVariables = {
    page,
    searchString,
    rowsPerPage,
    districts: [districtId],
  }

  if (searchQuery.labels && searchQuery.labels.length > 0) {
    queryVariables.labels = searchQuery.labels
  }

  if (searchQuery.projects && searchQuery.projects.length > 0) {
    queryVariables.projects = searchQuery.projects
  }

  if (searchQuery.enrollmentStatuses && searchQuery.enrollmentStatuses.length > 0) {
    queryVariables.enrollmentStatuses = searchQuery.enrollmentStatuses
  }

  if (searchQuery.enrollmentTypes && searchQuery.enrollmentTypes.length > 0) {
    queryVariables.enrollmentTypes = searchQuery.enrollmentTypes
  }

  if (searchQuery.types && searchQuery.types.length > 0) {
    queryVariables.types = searchQuery.types
  }

  const { data, loading, error, client } = useQuery(GET_SCHOOLS, {
    variables: { filter: queryVariables },
  })

  // SkeletonView
  if (loading)
    return (
      <SkeletonTable
        searchString={searchString}
        rowsPerPage={rowsPerPage}
        columns={constants.columns.schools}
      />
    )

  if (error) return <ErrorMessage>{error.message}</ErrorMessage>

  const { items: schools, total } = data.schoolsSearch

  const handleChangePage = (event, page) => {
    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true }) || {}
    const newParams = {
      ...queryParams,
      page: page,
    }
    const newSearchString = qs.stringify(newParams)
    navigate(`schools?${newSearchString}`)
  }

  const handleChangeRowsPerPage = (event) => {
    const rowsPerPage = event.target.value
    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true }) || {}
    const newParams = {
      ...queryParams,
      rowsPerPage,
    }
    const newSearchString = qs.stringify(newParams)
    navigate(`schools?${newSearchString}`)
  }

  const handlePreload = () => {
    if (page + 1 !== Math.ceil(total / rowsPerPage)) {
      client.query({
        query: GET_SCHOOLS,
        variables: { filter: { ...queryVariables, page: page + 1 } },
      })
    }
  }

  return (
    <div className={classes.root}>
      <Typography color="textSecondary" gutterBottom variant="body2">
        {total} resultater. Side {page + 1} av {Math.ceil(total / rowsPerPage)}
      </Typography>
      <Card>
        <CardHeader title={searchString === '' ? 'Alle skoler' : `Søk på ${searchString}`} />
        <Divider />
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <SchoolsTable schools={schools} columns={constants.columns.schools} />
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            onMouseOver={handlePreload}
            component="div"
            count={total}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            labelDisplayedRows={(props) => {
              const { from, to, count } = props
              return `${from}-${to} of ${count}`
            }}
            labelRowsPerPage="Rader per side:"
          />
        </CardActions>
      </Card>
    </div>
  )
}

DistrictSchools.propTypes = {
  location: PropTypes.object,
  slug: PropTypes.string,
}

export default DistrictSchools
