import React from 'react'
import { useFormikContext } from 'formik'
import debounce from 'just-debounce-it'

const AutoSave = ({ debounceMs, values }) => {
  const formik = useFormikContext()

  const [lastSaved, setLastSaved] = React.useState(null)
  const debouncedSubmit = React.useCallback(
    debounce(
      () => formik.submitForm().then(() => setLastSaved(new Date().toISOString())),
      debounceMs
    ),
    [debounceMs, formik.submitForm]
  )

  React.useEffect(() => {
    debouncedSubmit()
  }, [debouncedSubmit, values])

  return (
    <>
      {!!formik.isSubmitting
        ? 'saving...'
        : lastSaved !== null
        ? null /*`Last Saved: ${lastSaved}`*/
        : null}
    </>
  )
}

export default AutoSave
