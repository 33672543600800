import React, { useEffect } from 'react'
import {
  Typography,
  Box,
  TextField,
  Paper,
  MenuList,
  MenuItem,
  CircularProgress,
  InputAdornment,
  Link,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { makeStyles } from '@material-ui/styles'
import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'

const useStyles = makeStyles((theme) => ({
  list: {
    position: 'absolute',
    minWidth: '300px',
    minHeight: '100px',
    zIndex: 2,
  },
}))

const GET_SCHOOLS = gql`
  query ($filter: SchoolFilterInput!) {
    schoolsSearch(filter: $filter) {
      items {
        id
        name
        orgNr
        email
        nsrData {
          Besoksadresse {
            Adress
            Postnr
            Poststed
          }
          Postadresse {
            Adress
            Postnr
            Poststed
          }
          Fylke {
            Navn
          }
        }
        latestContactAt
      }
      total
    }
  }
`

const SchoolSearch = (props) => {
  const classes = useStyles()
  const [searchString, setSearchString] = React.useState('')
  const [keyword, setKeyword] = React.useState('')

  const { touched, errors, setFieldValue } = props

  const { data, loading, error } = useQuery(GET_SCHOOLS, {
    variables: { filter: { rowsPerPage: 20, searchString: keyword } },
    skip: !keyword,
  })

  useEffect(() => {
    const timeoutId = setTimeout(() => setKeyword(searchString), 1000)
    return () => clearTimeout(timeoutId)
  }, [searchString])

  const handleClick = (school) => {
    setFieldValue('school', school)
  }

  const allSchools = data && data.schoolsSearch.items.map((item) => ({ ...item, title: item.name }))

  return (
    <>
      <Box mb={4}>
        <Typography align="center" component="p" gutterBottom variant="h5">
          Ungdom vet best hva som engasjerer ungdom. Og vi er ungdommenes egen aksjon. Meld på deres
          skole og motta gratis og engasjerende undervisningsmateriell som inspirerer elevene til å
          gjøre en forskjell i verden!
        </Typography>
      </Box>
      <TextField
        autoFocus
        fullWidth
        label="Søk etter din skole"
        error={touched.school && !!errors.school}
        helperText={touched.school && errors.school}
        onChange={(event) => setSearchString(event.target.value)}
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {loading ? <CircularProgress size={24} /> : <SearchIcon />}
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <ArrowDropDownIcon />
            </InputAdornment>
          ),
        }}
      />

      {!loading && keyword && (
        <Paper className={classes.list}>
          {allSchools && allSchools.length === 0 && (
            <Typography color="error">Ingen treff</Typography>
          )}
          {error && <Typography color="error">Noe gikk galt. Prøv igjen.</Typography>}
          {allSchools && (
            <MenuList>
              {allSchools.map((school) => {
                return (
                  <MenuItem key={school.id} onClick={() => handleClick(school)}>
                    {school.name}
                    {', '}
                    {school?.nsrData?.Besoksadresse?.Poststed}
                  </MenuItem>
                )
              })}
            </MenuList>
          )}
        </Paper>
      )}

      <Box mt={2}>
        <Typography color="textSecondary" align="center">
          <Link underline="always" color="textSecondary" href="mailto:od@od.no">
            Kontakt OD hvis du ikke finner skolen din
          </Link>
        </Typography>
      </Box>
    </>
  )
}

export default SchoolSearch
