import React from 'react'
import PropTypes from 'prop-types'
import constants from '../../utils/constants'

const EnrollmentStatus = ({ children }) => {
  if (children === constants.enrollmentStatus.unknown) return <>Ikke påmeldt</>

  if (children === constants.enrollmentStatus.pending) return <>Avventer</>

  if (children === constants.enrollmentStatus.approved) return <>Bekreftet</>

  if (children === constants.enrollmentStatus.rejected) return <>Arkivert</>

  if (children === constants.enrollmentStatus.material_sent) return <>Sendt materiale</>

  if (children === constants.enrollmentStatus.accounting_provided) return <>Regnskap innsendt</>

  return <>Ukjent status</>
}

EnrollmentStatus.propTypes = {
  children: PropTypes.string.isRequired,
}

export default EnrollmentStatus
