import { useQuery } from '@apollo/react-hooks'
import { Card, CardActions, CardContent, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { gql } from 'apollo-boost'
import ErrorMessage from 'components/ErrorMessage'
import ResultsTablePagination from 'components/ResultsTablePagination'
import LabelsTable from 'components/LabelsTable'
import SkeletonTable from 'components/SkeletonTable'
import PropTypes from 'prop-types'
import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import constants from 'utils/constants'
import { useQueryParams } from 'utils/hooks'
import { useMutation } from '@apollo/react-hooks'

const GET_LABELS = gql`
  query ($filter: LabelFilterInput!) {
    labelsSearch(filter: $filter) {
      items {
        id
        title
        slug
        createdAt
        updatedAt
      }
      total
    }
  }
`

const CREATE_LABEL = gql`
  mutation ($title: String!) {
    createOrUpdateLabel(title: $title) {
      id
    }
  }
`

const UPDATE_LABEL = gql`
  mutation ($title: String!, $id: ID) {
    createOrUpdateLabel(title: $title, id: $id) {
      title
      id
    }
  }
`

const DELETE_LABEL = gql`
  mutation deleteLabel($id: ID!) {
    deleteLabel(id: $id) {
      id
      message
    }
  }
`

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 700,
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: 'flex-end',
  },
  box: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
}))

const Results = (props) => {
  const { location, addNew, toggleAddNew } = props
  const classes = useStyles()
  const { queryVariables, page, searchString, rowsPerPage } = useQueryParams(location)

  // Request data
  const { data, loading, error, client, refetch } = useQuery(GET_LABELS, {
    variables: { filter: queryVariables },
    fetchPolicy: 'no-cache',
  })

  const [createLabel] = useMutation(CREATE_LABEL)
  const [updateLabel] = useMutation(UPDATE_LABEL)
  const [deleteLabel] = useMutation(DELETE_LABEL)

  const handleLabelAdd = async (title) => {
    try {
      await createLabel({
        variables: { title },
      })

      toggleAddNew()
    } catch {}

    await refetch()
  }

  const handleLabelAddCancel = async () => {
    toggleAddNew()
  }

  const handleLabelUpdate = async (label) => {
    try {
      await updateLabel({
        variables: { id: label.id, title: label.title },
      })
    } catch {}

    await refetch()
  }

  const handleLabelDelete = async (label) => {
    try {
      await deleteLabel({
        variables: { id: label.id },
      })
    } catch {}

    await refetch()
  }

  // SkeletonView
  if (loading)
    return (
      <SkeletonTable
        searchString={searchString}
        rowsPerPage={rowsPerPage}
        columns={constants.columns.labels}
        cardHeader={'Alle etiketter'}
      />
    )

  if (error) return <ErrorMessage>{error.message}</ErrorMessage>

  const labels = data.labelsSearch.items
  const { total } = data.labelsSearch

  return (
    <div className={classes.root}>
      <Typography color="textSecondary" gutterBottom variant="body2">
        {total} resultater. Side {page + 1} av {Math.ceil(total / rowsPerPage)}
      </Typography>
      <Card>
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <LabelsTable
                labels={labels}
                columns={constants.columns.labels}
                addNew={addNew}
                handleAdd={handleLabelAdd}
                handleAddCancel={handleLabelAddCancel}
                handleUpdate={handleLabelUpdate}
                handleDelete={handleLabelDelete}
              />
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <ResultsTablePagination
            location={location}
            page={page}
            total={total}
            rowsPerPage={rowsPerPage}
            queryVariables={queryVariables}
            client={client}
            query={GET_LABELS}
          />
        </CardActions>
      </Card>
    </div>
  )
}

Results.propTypes = {
  location: PropTypes.object,
  addNew: PropTypes.bool,
  toggleAddNew: PropTypes.func,
}

export default Results
