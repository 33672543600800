import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { Grid, Button } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import qs from 'qs'
import Search from 'components/Search'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  search: {
    flexGrow: 1,
    maxWidth: 480,
    flexBasis: 480,
  },
  addButton: {
    marginLeft: 'auto',
  },
  addIcon: {
    marginRight: theme.spacing(1),
  },
}))

const SearchBar = (props) => {
  const { onSearch, className, location, handleReset, handleAddNew, ...rest } = props

  const searchQuery = qs.parse(location.search, { ignoreQueryPrefix: true })

  const { search } = searchQuery
  const classes = useStyles()

  return (
    <Grid {...rest} className={clsx(classes.root, className)} container spacing={3}>
      <Grid item>
        <Search
          className={classes.search}
          onSearch={onSearch}
          searchString={search}
          handleReset={handleReset}
        />
      </Grid>
      <Grid item>
        <Button
          className={classes.addButton}
          color="primary"
          onClick={handleAddNew}
          size="small"
          variant="outlined"
        >
          <AddIcon className={classes.addIcon} /> Legg til etikett
        </Button>
      </Grid>
    </Grid>
  )
}

SearchBar.propTypes = {
  className: PropTypes.string,
  onSearch: PropTypes.func,
  handleReset: PropTypes.func,
  handleAddNew: PropTypes.func,
  location: PropTypes.shape(),
}

export default SearchBar
