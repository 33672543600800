import React from 'react'
import PropTypes from 'prop-types'
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Tooltip,
  IconButton,
  CardActions,
  Grid,
  Box,
  Link,
  LinearProgress,
} from '@material-ui/core'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
import { makeStyles } from '@material-ui/styles'
import { Field } from 'formik'
import SelectedSchool from './SelectedSchool'
import ErrorMessage from '../../components/ErrorMessage'
import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'

const useStyles = makeStyles((theme) => ({
  innerCard: {
    position: 'relative',
    border: `2px solid ${theme.palette.primary.main}`,
    marginBottom: theme.spacing(3),
  },
  cardActions: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  label: {
    marginBottom: theme.spacing(2),
  },
  inputField: {
    position: 'relative',
    width: '100%',
  },
  error: {
    color: theme.palette.error.main,
  },
}))

const GET_ACTIVE_ENROLLMENTS = gql`
  query($filter: ActiveEnrollmentFilterInput!) {
    activeEnrollments(filter: $filter) {
      id
      status
    }
  }
`

const SchoolAndParticipants = (props) => {
  const classes = useStyles()
  const { setFieldValue, values, touched, errors, handleActiveEnrollments } = props
  const { data, loading, error } = useQuery(GET_ACTIVE_ENROLLMENTS, {
    variables: { filter: { school: values.school.id } },
    skip: !values.school || !values.school.id,
  })

  if (error) return <ErrorMessage>{error.message}</ErrorMessage>
  if (data) {
    handleActiveEnrollments(data.activeEnrollments)
  }

  return (
    <>
      <Box mb={4}>
        <Typography align="center" component="p" gutterBottom variant="h5">
          Ungdom vet best hva som engasjerer ungdom. Og vi er ungdommenes egen aksjon. Meld på deres
          skole og motta gratis og engasjerende undervisningsmateriell som inspirerer elevene til å
          gjøre en forskjell i verden!
        </Typography>
      </Box>
      <Card elevation={0} className={classes.innerCard}>
        <CardActions className={classes.cardActions}>
          <Tooltip title="Fjern og søk på nytt">
            <IconButton
              onClick={() => {
                setFieldValue('school', null)
              }}
              // color="primary"
            >
              <RemoveCircleOutlineIcon />
            </IconButton>
          </Tooltip>
        </CardActions>
        <CardContent>
          <SelectedSchool school={values.school}></SelectedSchool>
        </CardContent>
      </Card>
      {loading && <LinearProgress color="secondary" />}
      {data && (
        <div>
          {data.activeEnrollments.length > 0 && (
            <div>
              <Box mb={4}>
                <Typography
                  align="center"
                  component="p"
                  gutterBottom
                  variant="h5"
                  className={classes.error}
                >
                  Denne skolen er allerede påmeldt.
                </Typography>
                <Typography align="center" component="p" gutterBottom variant="h5">
                  Om du ønsker å endre påmeldingen, kontakt OD på{' '}
                  <Link href="mailto:od@od.no" target="_blank" rel="noopener noreferrer">
                    od@od.no.
                  </Link>
                </Typography>
              </Box>
            </div>
          )}
          {data.activeEnrollments.length === 0 && (
            <div>
              <Typography className={classes.label} variant="h5" gutterBottom>
                Hvor mange tror dere vil delta?
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs>
                  <Field
                    className={classes.inputField}
                    name={`numberOfStudents`}
                    as={TextField}
                    variant="outlined"
                    required
                    label="Antall elever"
                    type="number"
                    error={touched.numberOfStudents && !!errors.numberOfStudents}
                    helperText={
                      touched.numberOfStudents && errors.numberOfStudents
                        ? errors.numberOfStudents
                        : ' '
                    }
                  />
                </Grid>
                <Grid item xs>
                  <Field
                    className={classes.inputField}
                    name={`numberOfTeachers`}
                    as={TextField}
                    variant="outlined"
                    required
                    label="Antall lærere"
                    type="number"
                    error={touched.numberOfTeachers && !!errors.numberOfTeachers}
                    helperText={
                      touched.numberOfTeachers && errors.numberOfTeachers
                        ? errors.numberOfTeachers
                        : ' '
                    }
                  />
                </Grid>
              </Grid>
            </div>
          )}
        </div>
      )}
    </>
  )
}

SchoolAndParticipants.propTypes = {
  handleActiveEnrollments: PropTypes.func.isRequired,
}

export default SchoolAndParticipants
