import { useQuery } from '@apollo/react-hooks'
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  TablePagination,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { navigate } from '@reach/router'
import { gql } from 'apollo-boost'
import EnrollmentsTable from 'components/EnrollmentsTable'
import ErrorMessage from 'components/ErrorMessage'
import SkeletonTable from 'components/SkeletonTable'
import PropTypes from 'prop-types'
import qs from 'qs'
import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import constants from 'utils/constants'
import getIdFromSlug from 'utils/getIDfromSlug'

const GET_ENROLLMENTS = gql`
  query($filter: EnrollmentFilterInput!) {
    enrollmentsSearch(filter: $filter) {
      items {
        id
        slug
        status
        createdAt
        district {
          id
        }
        school {
          id
          name
          orgNr
          NSRId
          district {
            id
            title
          }
          email
          types
          status
          slug
        }
        project {
          id
          title
        }
        payment {
          id
          amount
          paymentMethod
        }
        contacts {
          id
          firstName
          lastName
          email
          phoneNumber
        }
      }
      total
    }
  }
`

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 700,
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: 'flex-end',
  },
}))

const Enrollments = (props) => {
  const { location, slug } = props
  const classes = useStyles()

  const contactID = getIdFromSlug(slug)
  // Create query variables from location.search
  let queryVariables = {}
  const searchQuery = qs.parse(location.search, { ignoreQueryPrefix: true })
  const currentPage = searchQuery.page || ''
  const page = currentPage ? parseInt(currentPage, 10) : 0
  const searchString = ''
  const rowsPerPage = searchQuery.rowsPerPage ? parseInt(searchQuery.rowsPerPage, 10) : 25

  queryVariables = {
    sort: {
      order: 'desc',
      property: 'createdAt',
    },
    page,
    searchString,
    rowsPerPage,
    contact: contactID,
  }

  // Request data
  const { data, loading, error, client, refetch } = useQuery(GET_ENROLLMENTS, {
    variables: { filter: queryVariables },
  })

  // SkeletonView
  if (loading)
    return (
      <SkeletonTable
        searchString={searchString}
        rowsPerPage={rowsPerPage}
        columns={constants.columns.enrollments}
      />
    )

  if (error) return <ErrorMessage>{error.message}</ErrorMessage>

  const { items: enrollments, total } = data.enrollmentsSearch

  const handleChangePage = (event, page) => {
    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true }) || {}
    const newParams = {
      ...queryParams,
      page: page,
    }
    const newSearchString = qs.stringify(newParams)
    navigate(`enrollments?${newSearchString}`)
  }

  const handleChangeRowsPerPage = (event) => {
    const rowsPerPage = event.target.value
    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true }) || {}
    const newParams = {
      ...queryParams,
      rowsPerPage,
    }
    const newSearchString = qs.stringify(newParams)
    navigate(`enrollments?${newSearchString}`)
  }

  const handlePreload = () => {
    if (page + 1 !== Math.ceil(total / rowsPerPage)) {
      client.query({
        query: GET_ENROLLMENTS,
        variables: { filter: { ...queryVariables, page: page + 1 } },
      })
    }
  }

  return (
    <div className={classes.root}>
      <Typography color="textSecondary" gutterBottom variant="body2">
        {total} resultater. Side {page + 1} av {Math.ceil(total / rowsPerPage)}
      </Typography>
      <Card>
        <CardHeader title={searchString === '' ? 'Alle påmeldinger' : `Søk på ${searchString}`} />
        <Divider />
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <EnrollmentsTable
                enrollments={enrollments}
                multiSelectable={true}
                refetch={refetch}
              />
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            onMouseOver={handlePreload}
            component="div"
            count={total}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            labelDisplayedRows={(props) => {
              const { from, to, count } = props
              return `${from}-${to} of ${count}`
            }}
            labelRowsPerPage="Rader per side:"
          />
        </CardActions>
      </Card>
    </div>
  )
}

Enrollments.propTypes = {
  location: PropTypes.object,
  slug: PropTypes.string,
}

Enrollments.defaultProps = {}

export default Enrollments
