import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import Downshift from 'downshift'
import { Button, Chip, TextField, Paper, MenuItem } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import AddIcon from '@material-ui/icons/Add'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },

  formGroup: {
    position: 'relative',
  },

  paper: {
    position: 'absolute',
    zIndex: 2,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
    background: '#fff',
  },

  buttonIcon: {
    marginRight: theme.spacing(1),
  },

  fieldGroup: {
    display: 'flex',
    alignItems: 'center',
  },
  field: {
    marginTop: 0,
    marginBottom: 0,
  },
  flexGrow: {
    flexGrow: 1,
  },
  addButton: {
    marginLeft: theme.spacing(1),
  },
  tags: {
    marginTop: theme.spacing(1),
  },
  chip: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}))

const LabelsInput = (props) => {
  const { tags, arrayHelpers, handleNewTag, allTags, label } = props
  const classes = useStyles()

  let tagsByid = {}
  let filteredAllTags = []
  allTags.map((item) => (tagsByid[item.id] = item))

  filteredAllTags = allTags.filter((item) => tags && tags.indexOf(item.id) === -1)

  const handleTagSelect = (selection, stateAndHelpers) => {
    if (selection) {
      // Add it to formik state and clear the input
      arrayHelpers.push(selection.id)
      stateAndHelpers.clearSelection()
    }
  }

  const checkDisabledStatus = (inputValue) => {
    return !inputValue || !inputValue.trim() ? true : false
  }

  return (
    <div className={classes.formGroup}>
      <Downshift onChange={handleTagSelect} itemToString={(item) => (item ? item.title : '')}>
        {({
          getInputProps,
          getItemProps,
          getLabelProps,
          getMenuProps,
          isOpen,
          inputValue,
          highlightedIndex,
          selectedItem,
          openMenu,
        }) => (
          <div>
            <div className={classes.fieldGroup}>
              <TextField
                {...getInputProps({ onFocus: openMenu })}
                className={clsx(classes.field, classes.flexGrow)}
                label={label}
                margin="dense"
                name="tag"
                variant="outlined"
                onBlur={(e) => {
                  e.preventDefault()
                }}
              />

              {handleNewTag && (
                <Button
                  className={classes.addButton}
                  onClick={() => handleNewTag(inputValue)}
                  disabled={checkDisabledStatus(inputValue)}
                  size="small"
                  tabIndex="0"
                >
                  <AddIcon className={classes.addIcon} />
                  Legg til
                </Button>
              )}
            </div>
            <div {...getMenuProps()}>
              {isOpen ? (
                <Paper className={classes.paper} square>
                  {filteredAllTags
                    .filter(
                      (item) =>
                        !inputValue ||
                        inputValue === '' ||
                        item.title.toLowerCase().includes(inputValue.toLowerCase())
                    )
                    .map((item, index) => {
                      return (
                        <MenuItem
                          tabIndex={index}
                          as="button"
                          {...getItemProps({
                            key: item.id,
                            index,
                            item,
                            style: {
                              backgroundColor: highlightedIndex === index ? 'lightgray' : 'white',
                              fontWeight: selectedItem === item ? 'bold' : 'normal',
                            },
                          })}
                        >
                          {item.title}
                        </MenuItem>
                      )
                    })}
                </Paper>
              ) : null}
            </div>
          </div>
        )}
      </Downshift>
      <div className={classes.tags}>
        <div>
          {tags &&
            tags.length > 0 &&
            tags.map((tag, index) => {
              return (
                <Chip
                  className={classes.chip}
                  deleteIcon={<CloseIcon />}
                  key={tag}
                  label={tagsByid[tag] && tagsByid[tag].title}
                  onDelete={() => arrayHelpers.remove(index)}
                />
              )
            })}
        </div>
      </div>
    </div>
  )
}

LabelsInput.propTypes = {
  tags: PropTypes.array.isRequired,
  handleNewTag: PropTypes.func,
  arrayHelpers: PropTypes.object.isRequired,
}

LabelsInput.defaultProps = {
  label: 'Søk',
}

export default LabelsInput
