import React from 'react'
import PropTypes from 'prop-types'
import { navigate } from '@reach/router'
import { Card, CardHeader, CardContent, Divider } from '@material-ui/core'
import ContactForm from '../../../components/Forms/ContactForm'

const ContactEdit = props => {
  const { contact, refetch } = props

  const handleCancel = () => {
    navigate('./')
  }

  const onSubmitSuccess = async () => {
    await refetch()
    navigate('./')
  }

  return (
    <Card>
      <CardHeader title="Kontaktinformasjon" />
      <Divider />
      <CardContent>
        <ContactForm
          contact={contact}
          handleCancel={handleCancel}
          onSubmitSuccess={onSubmitSuccess}
        />
      </CardContent>
    </Card>
  )
}

ContactEdit.propTypes = {
  contact: PropTypes.object.isRequired,
}

export default ContactEdit
