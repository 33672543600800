import React from 'react'
import { Link as RouterLink } from '@reach/router'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardHeader,
  CardContent,
  List,
  ListItem,
  Link,
  Typography,
  Divider,
  CardActions,
  Button,
} from '@material-ui/core'
import Label from 'components/Label'
import EditIcon from '@material-ui/icons/Edit'
import EnrollmentStatusLabel from 'components/EnrollmentStatusLabel'
import EnrollmentType from 'components/EnrollmentType'

const useStyles = makeStyles((theme) => ({
  content: {
    paddingTop: 0,
  },
  listItem: {
    padding: theme.spacing(2, 0),
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
}))

const Info = (props) => {
  const { enrollment } = props

  const { estStudentCount, estTeacherCount, comments, school, status, types } = enrollment

  const classes = useStyles()

  return (
    <Card>
      <CardHeader title="Påmeldingsinfo" />
      <Divider />
      <CardContent className={classes.content}>
        <List>
          <ListItem className={classes.listItem} disableGutters divider>
            <Typography variant="subtitle2">Skole</Typography>
            <Link
              color="textPrimary"
              variant="h6"
              underline="always"
              to={`/schools/${school.slug}`}
              component={RouterLink}
            >
              {school.name}
            </Link>
          </ListItem>

          {status && (
            <ListItem className={classes.listItem} disableGutters divider>
              <Typography variant="subtitle2">Status</Typography>
              <EnrollmentStatusLabel>{status}</EnrollmentStatusLabel>
            </ListItem>
          )}
          {types.length !== 0 && (
            <ListItem className={classes.listItem} disableGutters divider>
              <Typography variant="subtitle2">Type</Typography>
              <Typography variant="h6">
                {types.map((item) => (
                  <Label key={item} variant="outline">
                    <EnrollmentType>{item}</EnrollmentType>
                  </Label>
                ))}
              </Typography>
            </ListItem>
          )}

          {estStudentCount && (
            <ListItem className={classes.listItem} disableGutters divider>
              <Typography variant="subtitle2">Antall elever</Typography>
              <Typography variant="h6">{estStudentCount}</Typography>
            </ListItem>
          )}

          {!!estTeacherCount && (
            <ListItem className={classes.listItem} disableGutters divider>
              <Typography variant="subtitle2">Antall lærere</Typography>
              <Typography variant="h6">{estTeacherCount}</Typography>
            </ListItem>
          )}

          {comments && (
            <ListItem className={classes.listItem} disableGutters divider>
              <Typography variant="subtitle2">Skolens kommentarer</Typography>
              <Typography variant="h6">{comments}</Typography>
            </ListItem>
          )}
        </List>
      </CardContent>

      <CardActions>
        <Button component={RouterLink} to="./edit" startIcon={<EditIcon />} color="primary">
          Rediger
        </Button>
      </CardActions>
    </Card>
  )
}

Info.propss = {
  refetch: PropTypes.func,
  enrollment: PropTypes.object.isRequired,
}

export default Info
