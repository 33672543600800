import { format, parseISO } from 'date-fns'

export function getLabelsUpdatedAtString(school) {
  if (school) {
    if (school.latestLabelUpdateAt) {
      const isoDate = parseISO(school.latestLabelUpdateAt)
      const formattedDate = format(isoDate, 'dd.MM.yyyy HH:mm')

      return `Etiketter oppdatert ${formattedDate}`
    }
  }
  return ''
}
