import React from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { Grid, CircularProgress } from '@material-ui/core'
import getIdFromSlug from 'utils/getIDfromSlug'
import UserStatisticsSummary from './UserStatisticsSummary'
import ErrorMessage from 'components/ErrorMessage'
import constants from 'utils/constants'

const GET_LOGS = gql`
  query logsSearch($filter: LogFilterInput!) {
    logsSearch(filter: $filter) {
      items {
        id
        type
        contentRaw
        createdAt
        user {
          id
        }
        school {
          id
          slug
          name
          latestContactAt
          latestLabelUpdateAt
        }
      }
    }
  }
`

const Activity = (props) => {
  const { slug } = props
  const id = getIdFromSlug(slug)

  const { data, loading, error } = useQuery(GET_LOGS, {
    variables: {
      filter: {
        users: [id],
        types: [constants.logTypes.call, constants.logTypes.message, constants.logTypes.system],
        dateMin: new Date(new Date().getYear(), 0, 1),
        rowsPerPage: 100000 /* all matches to fit on one page */,
      },
    },
  })

  if (loading) return <CircularProgress color="secondary" />
  if (error) return <ErrorMessage>{error.message}</ErrorMessage>

  const { items } = data?.logsSearch
  return (
    <Grid container spacing={3}>
      <Grid item lg={12} md={12} xl={12} xs={12}>
        <UserStatisticsSummary logs={items} />
      </Grid>
    </Grid>
  )
}

Activity.propTypes = {
  slug: PropTypes.string,
}

export default Activity
