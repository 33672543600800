import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardHeader,
  CardContent,
  List,
  ListItem,
  Typography,
  Divider,
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  content: {
    paddingTop: 0,
  },
  listItem: {
    padding: theme.spacing(2, 0),
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
}))

const Info = props => {
  const { district } = props
  const { title, email } = district

  const classes = useStyles()

  return (
    <Card>
      <CardHeader title="Distriktinfo" />
      <Divider />
      <CardContent className={classes.content}>
        <List>
          {title && (
            <ListItem className={classes.listItem} disableGutters divider>
              <Typography variant="subtitle2">Distrikt</Typography>
              <Typography variant="h6">{title}</Typography>
            </ListItem>
          )}
          {email && (
            <ListItem className={classes.listItem} disableGutters divider>
              <Typography variant="subtitle2">E-post</Typography>
              <Typography variant="h6">{email}</Typography>
            </ListItem>
          )}
        </List>
      </CardContent>
    </Card>
  )
}

Info.propTypes = {
  refetch: PropTypes.func,
  district: PropTypes.object.isRequired,
}

export default Info
