import React from 'react'
import PropTypes from 'prop-types'
import constants from '../../utils/constants'

const SchoolType = ({ children }) => {
  if (children === constants.schoolTypes.barneskole) return <>Barneskole</>
  if (children === constants.schoolTypes.ungdomsskole) return <>Ungdomsskole</>
  if (children === constants.schoolTypes.videregaaende) return <>Videregående</>
  if (children === constants.schoolTypes.folkehoyskole) return <>Folkehøyskole</>
  if (children === constants.schoolTypes.spesialskole) return <>Spesialskole</>
  return <>Ukjent skoletype</>
}

SchoolType.propTypes = {
  children: PropTypes.string.isRequired,
}

export default SchoolType
