import { Card, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'
import React from 'react'
import Amount from 'components/Amount'
import Pupils from 'components/Pupils'

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
  },
  content: {
    padding: 0,
  },
  item: {
    padding: theme.spacing(3),
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      '&:not(:last-of-type)': {
        borderRight: `1px solid ${theme.palette.divider}`,
      },
    },
    [theme.breakpoints.down('sm')]: {
      '&:not(:last-of-type)': {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
  },
  valueContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  label: {
    marginLeft: theme.spacing(1),
  },
}))

const Payments = (props) => {
  const { enrollment } = props
  const { payment } = enrollment

  const classes = useStyles()

  return (
    <Card className={classes.root}>
      <Grid alignItems="center" container justify="space-between">
        <Grid className={classes.item} item sm={6} xs={12}>
          <Typography component="h2" gutterBottom variant="overline">
            Totalt innbetalt
          </Typography>
          <div className={classes.valueContainer}>
            <Typography variant="h3">
              <Amount>{payment?.reduce((acc, cur) => acc + cur.amount, 0)}</Amount>
            </Typography>
          </div>
        </Grid>
        <Grid className={classes.item} item sm={6} xs={12}>
          <Typography component="h2" gutterBottom variant="overline">
            Elever deltatt
          </Typography>
          <div className={classes.valueContainer}>
            <Typography variant="h3">
              <Pupils>{payment?.reduce((acc, cur) => acc + cur.pupils, 0)}</Pupils>
            </Typography>
          </div>
        </Grid>
      </Grid>
    </Card>
  )
}

Payments.propTypes = {
  refetch: PropTypes.func,
  enrollment: PropTypes.object.isRequired,
}

export default Payments
