import constants from './constants'
import { nb } from 'date-fns/locale'
import { format, parseISO } from 'date-fns'
import { getLatestEnrolledProjects } from 'components/SchoolsTable'

export function getEnrollmentStatuses() {
  return [
    {
      id: constants.enrollmentStatus.unknown,
      title: 'Ikke påmeldt',
    },
    {
      id: constants.enrollmentStatus.pending,
      title: 'Avventer',
    },
    {
      id: constants.enrollmentStatus.approved,
      title: 'Bekreftet',
    },
    {
      id: constants.enrollmentStatus.rejected,
      title: 'Arkivert',
    },
    {
      id: constants.enrollmentStatus.material_sent,
      title: 'Sendt materiale',
    },
    {
      id: constants.enrollmentStatus.accounting_provided,
      title: 'Regnskap innsendt',
    },
  ]
}

export function getEnrollmentInputStatuses() {
  return [
    {
      id: constants.enrollmentStatus.pending,
      title: 'Avventer',
    },
    {
      id: constants.enrollmentStatus.approved,
      title: 'Bekreftet',
    },
    {
      id: constants.enrollmentStatus.rejected,
      title: 'Arkivert',
    },
    {
      id: constants.enrollmentStatus.material_sent,
      title: 'Sendt materiale',
    },
    {
      id: constants.enrollmentStatus.accounting_provided,
      title: 'Regnskap innsendt',
    },
  ]
}

export function getEnrollmentTypes() {
  return [
    {
      id: constants.enrollmentTypes.odDay,
      title: 'OD-dagen',
    },
    {
      id: constants.enrollmentTypes.iu,
      title: 'Internasjonal uke',
    },
    {
      id: constants.enrollmentTypes.odSchool,
      title: 'OD-skole',
    },
  ]
}

export function getEnrollmentTypeString(types) {
  if (types && types.length > 0) {
    const enrollmentTypes = getEnrollmentTypes()

    let typeStrings = types.map((t) => {
      const enrollmentType = enrollmentTypes.find((et) => et.id === t)
      return enrollmentType ? enrollmentType.title : undefined
    })

    typeStrings = typeStrings.filter((ts) => {
      return ts !== undefined
    })

    return typeStrings && typeStrings.length > 0
      ? typeStrings.length === 1
        ? typeStrings[0]
        : typeStrings.join(', ')
      : ''
  }
  return ''
}

export function getLatestEnrollment(enrollments) {
  if (enrollments) {
    const activeEnrollments =
      enrollments && enrollments.length > 0
        ? enrollments.filter(
            (item) =>
              item.project.status === 'open' &&
              item.status !== constants.enrollmentStatus.rejected &&
              item.status !== constants.enrollmentStatus.unknown
          )
        : []

    if (activeEnrollments && activeEnrollments.length > 1) {
      activeEnrollments.sort(function (a, b) {
        return new Date(b.updatedAt) - new Date(a.updatedAt)
      })
    }
    return activeEnrollments && activeEnrollments.length > 0 ? activeEnrollments[0] : null
  }
}

export function getLatestEnrollmentStatus(enrollments) {
  const enrollment = getLatestEnrollment(enrollments)
  return enrollment ? enrollment.status : null
}

export function getMainContactFromEnrollments(enrollments) {
  const lastEnrollment = getLatestEnrollment(enrollments)

  return lastEnrollment && lastEnrollment.contacts && lastEnrollment.contacts.length > 0
    ? lastEnrollment.contacts[0]
    : null
}

export function getOtherContactsEmailStringFromEnrollment(contacts) {
  if (contacts && contacts.length > 1) {
    let emails = contacts.map((c) => {
      return c.email ? c.email : undefined
    })

    emails = emails.filter((e) => {
      return e !== undefined
    })

    return emails && emails.length > 0 ? (emails.length === 1 ? emails[0] : emails.join(', ')) : ''
  }
  return null
}

export function getStudentContactsEmailStringFromEnrollment(contacts) {
  if (contacts && contacts.length > 1) {
    let emails = contacts.map((c) => {
      if (c.type === constants.contactTypes.student) {
        return c.email ? c.email : undefined
      }
    })

    emails = emails.filter((e) => {
      return e !== undefined
    })

    return emails && emails.length > 0 ? (emails.length === 1 ? emails[0] : emails.join(', ')) : ''
  }
  return null
}
export function getTeacherContactsEmailStringFromEnrollment(contacts) {
  if (contacts && contacts.length > 1) {
    let emails = contacts.map((c) => {
      if (c.type === constants.contactTypes.teacher) {
        return c.email ? c.email : undefined
      }
    })

    emails = emails.filter((e) => {
      return e !== undefined
    })

    return emails && emails.length > 0 ? (emails.length === 1 ? emails[0] : emails.join(', ')) : ''
  }
  return null
}

export function assignInfoToItems(enrollments) {
  if (enrollments && enrollments.length > 0) {
    const updatedEnrollments = enrollments.map((enrollment) => {
      const contact =
        enrollment.contacts && enrollment.contacts.length > 0 ? enrollment.contacts[0] : null

      Object.assign(enrollment, {
        type: getEnrollmentTypeString(enrollment.types),
        contact: contact,
        additionalContactEmails: getOtherContactsEmailStringFromEnrollment(enrollment.contacts),
        additionalStudentContactEmails: getStudentContactsEmailStringFromEnrollment(
          enrollment.contacts
        ),
        additionalTeacherContactEmails: getTeacherContactsEmailStringFromEnrollment(
          enrollment.contacts
        ),
      })
      return enrollment
    })

    return updatedEnrollments
  }
  return enrollments
}

export function assignLatestInfoToItems(schools) {
  if (schools && schools.length > 0) {
    const updatedSchools = schools.map((school) => {
      if (school) {
        if (school.enrollments && school.enrollments.length > 0) {
          // const latestEnrollment = getLatestEnrollment(school.enrollments)
          const sortedEnrollments = school.enrollments.sort(function (a, b) {
            return new Date(b.updatedAt) - new Date(a.updatedAt)
          })
          let latestContacts = []
          sortedEnrollments.filter((enrollment) => {
            if (enrollment.contacts && enrollment.contacts.length > 0) {
              return latestContacts.push(enrollment.contacts[0])
            }
          })
          Object.assign(school, {
            // latestEnrollmentYear:
            //   latestEnrollment && latestEnrollment.project && latestEnrollment.project.year
            //     ? latestEnrollment.project.year
            //     : null,
            latestEnrollmentYear: getLatestEnrolledProjects(school.enrollments) || null,
            latestEnrollmentType:
              sortedEnrollments && sortedEnrollments[0] && sortedEnrollments[0].types.length > 0
                ? getEnrollmentTypeString(sortedEnrollments[0].types)
                : null,
            contact: latestContacts[0],
          })
        }

        if (school.latestContactAt) {
          Object.assign(school, {
            latestContactDate: format(parseISO(school.latestContactAt), 'yyyy-MM-dd HH:mm', {
              locale: nb,
            }),
          })
        }
      }

      return school
    })

    return updatedSchools
  }
  return schools
}
