import { useQuery } from '@apollo/react-hooks'
import {
  Breadcrumbs,
  colors,
  Divider,
  LinearProgress,
  Link,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Link as RouterLink } from '@reach/router'
import { gql } from 'apollo-boost'
import PropTypes from 'prop-types'
import React from 'react'
import ErrorMessage from '../../components/ErrorMessage'
import Page from '../../components/Page'
import getIdFromSlug from '../../utils/getIDfromSlug'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  tabs: {
    marginTop: theme.spacing(3),
  },
  divider: {
    backgroundColor: colors.grey[300],
    marginBottom: theme.spacing(3),
  },
  breadcrumb: {
    marginBottom: theme.spacing(2),
  },
}))

const GET_USER = gql`
  query ($id: ID!) {
    user(id: $id) {
      id
      fullName
    }
  }
`

const UserDetails = (props) => {
  const classes = useStyles()
  const { slug, children, location } = props
  const id = getIdFromSlug(slug)
  const { data, loading, error } = useQuery(GET_USER, { variables: { id: id } })

  const tabs = [
    { value: 'summary', label: 'Oversikt' },
    { value: 'schools', label: 'Skoler' },
    { value: 'activity', label: 'Aktivitet' },
  ]

  const pathArray = location.pathname.split('/')
  const lastPartOfPath = pathArray[pathArray.length - 1]

  const currentTab =
    tabs.map((item) => item.value).indexOf(lastPartOfPath) === -1 ? 'summary' : lastPartOfPath

  if (loading) return <LinearProgress color="secondary" />
  if (error) return <ErrorMessage>{error.message}</ErrorMessage>

  const { user } = data

  return (
    <Page className={classes.root} title={`Frivillige - ${user.fullName}`}>
      <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
        <Link component={RouterLink} variant="subtitle1" color="textPrimary" to="/">
          Hjem
        </Link>
        <Link component={RouterLink} variant="subtitle1" color="textPrimary" to="/users">
          Frivillige
        </Link>
      </Breadcrumbs>
      <Typography component="h1" variant="h3">
        {user.fullName}
      </Typography>

      <Tabs className={classes.tabs} scrollButtons="auto" value={currentTab} variant="scrollable">
        {tabs.map((tab) => (
          <Tab
            key={tab.value}
            label={tab.label}
            value={tab.value}
            component={RouterLink}
            to={tab.value}
          />
        ))}
      </Tabs>
      <Divider className={classes.divider} />
      {children}
    </Page>
  )
}

UserDetails.propTypes = {
  slug: PropTypes.string,
}

export default UserDetails
