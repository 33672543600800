import {
  Avatar,
  colors,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Checkbox,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  DialogContentText,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Link as RouterLink } from '@reach/router'
import Amount from 'components/Amount'
import EnrollmentStatusLabel from 'components/EnrollmentStatusLabel'
import FormattedDate from 'components/FormattedDate'
import Label from 'components/Label'
import SchoolType from 'components/SchoolType'
import EnrollmentType from 'components/EnrollmentType'
import React from 'react'
import constants from 'utils/constants'
import getInitials from 'utils/getInitials'
import { getEnrollmentInputStatuses } from 'utils/enrollmentsHelper'
import EnrollmentStatus from 'components/EnrollmentStatus'
import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'

const UPDATE_ENROLLMENTS = gql`
  mutation ($input: UpdateEnrollmentsInput!) {
    updateEnrollments(input: $input) {
      id
    }
  }
`

const CREATE_ENROLLMENTS = gql`
  mutation ($input: EnrollmentInput!) {
    createEnrollment(input: $input) {
      id
    }
  }
`

const useStyles = makeStyles((theme) => ({
  cell: {
    whiteSpace: 'nowrap',
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1),
    backgroundColor: colors.orange[400],
  },
  link: {
    textDecoration: 'none',
    color: colors.black,
    '&:hover': {
      textDecoration: 'none',
    },
  },
}))

const initialContextMenuState = {
  mouseX: null,
  mouseY: null,
}

const EnrollmentsTable = ({ enrollments, multiSelectable, refetch }) => {
  const classes = useStyles()
  const [contextMenuState, setContextMenuState] = React.useState(initialContextMenuState)
  const [editDialogOpen, setEditDialogOpen] = React.useState(false)
  const [selectedEnrollmentIds, setSelectedEnrollmentIds] = React.useState([])
  const [selectedStatus, setSelectedStatus] = React.useState(null)
  const [openConfirm, setOpenConfirm] = React.useState(false)
  const [updateEnrollments] = useMutation(UPDATE_ENROLLMENTS)
  const [createEnrollment] = useMutation(CREATE_ENROLLMENTS)

  const handleClick = (event) => {
    event.preventDefault()
    setContextMenuState({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    })
  }

  const handleConfirmOpen = () => {
    setOpenConfirm(true)
  }

  const handleConfirmClose = () => {
    setOpenConfirm(false)
  }

  const handleClose = () => {
    setContextMenuState(initialContextMenuState)
  }

  const handleContextMenuEdit = () => {
    setContextMenuState(initialContextMenuState)
    setEditDialogOpen(true)
  }

  const handleEditDialogClose = () => {
    setEditDialogOpen(false)
  }

  const isEnrollmentSelected = (id) => {
    return selectedEnrollmentIds.includes(id)
  }

  const handleSelectAll = () => {
    let allEnrollmentIds
    if (enrollments && enrollments.length > 0) {
      allEnrollmentIds = enrollments.map((e) => {
        return e.id
      })
    }

    setSelectedEnrollmentIds(
      selectedEnrollmentIds && selectedEnrollmentIds.length > 0
        ? []
        : allEnrollmentIds && allEnrollmentIds.length > 0
        ? allEnrollmentIds
        : []
    )
  }

  const handleSingleSelect = (e) => {
    let selected = []
    Object.assign(selected, selectedEnrollmentIds)

    if (e.target.checked) {
      selected.push(e.target.value)
    } else {
      selected = selected.filter((s) => {
        return s !== e.target.value
      })
    }

    setSelectedEnrollmentIds(selected)
  }

  const handleStatusChange = function (e) {
    setSelectedStatus(e.target.value)
  }

  const handleSaveChanges = async function () {
    try {
      await updateEnrollments({
        variables: {
          input: {
            ids: selectedEnrollmentIds,
            status: selectedStatus,
          },
        },
      })

      if (refetch) refetch()

      setEditDialogOpen(false)
    } catch (err) {
      console.log(err)
    }
  }

  const handleCreateEnrollments = async function () {
    try {
      await Promise.all(
        selectedEnrollmentIds?.map(async (e) => {
          const a = enrollments?.find((item) => item.id === e)
          console.log(a)
          const contacts = a.contacts?.map((c) => {
            delete c.__typename
            return c
          })
          return await createEnrollment({
            variables: {
              input: {
                id: e.id,
                status: 'pending',
                types: a.types,
                contacts: contacts,
                schoolName: a.school.name,
                school: a.school.id,
                estStudentCount: a.estStudentCount,
                estTeacherCount: a.estTeacherCount,
                comments: '',
                odSchool: true,
              },
            },
          })
        })
      )

      if (refetch) refetch()

      handleConfirmClose()
      setContextMenuState(initialContextMenuState)
      setEditDialogOpen(false)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div onContextMenu={handleClick}>
      <Table>
        <TableHead>
          <TableRow>
            {multiSelectable && (
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={
                    selectedEnrollmentIds &&
                    selectedEnrollmentIds.length > 0 &&
                    selectedEnrollmentIds.length !== enrollments?.length
                  }
                  checked={
                    selectedEnrollmentIds && selectedEnrollmentIds.length === enrollments?.length
                  }
                  onChange={handleSelectAll}
                />
              </TableCell>
            )}
            {constants.columns.enrollments.map((column) => (
              <TableCell key={column}>{column}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {enrollments.map((enrollment) => {
            return (
              <TableRow hover key={enrollment.id}>
                {multiSelectable && (
                  <TableCell padding="checkbox">
                    <Checkbox
                      value={enrollment.id}
                      checked={isEnrollmentSelected(enrollment.id)}
                      onChange={handleSingleSelect}
                    />
                  </TableCell>
                )}
                <TableCell className={classes.cell}>
                  <div className={classes.nameCell}>
                    <Avatar className={classes.avatar} src={enrollment.avatar}>
                      {getInitials(enrollment.school.name)}
                    </Avatar>
                    <div>
                      <Link
                        color="inherit"
                        component={RouterLink}
                        className={classes.link}
                        to={`/enrollments/${enrollment.slug}`}
                      >
                        <Typography variant="h6">{enrollment.school.name}</Typography>
                      </Link>
                      <div>{enrollment.school.email}</div>
                    </div>
                  </div>
                </TableCell>
                <TableCell className={classes.cell}>{enrollment.school.district.title}</TableCell>
                <TableCell className={classes.cell}>
                  {enrollment.school.types?.map((item, index) => (
                    <Label key={item} variant="outlined">
                      <SchoolType>{item}</SchoolType>
                    </Label>
                  ))}
                </TableCell>
                <TableCell className={classes.cell}>{enrollment.project.title}</TableCell>
                <TableCell className={classes.cell}>
                  <EnrollmentStatusLabel>{enrollment.status}</EnrollmentStatusLabel>
                </TableCell>
                <TableCell className={classes.cell}>
                  {enrollment.types?.map((item, index) => (
                    <Label key={item} variant="outlined">
                      <EnrollmentType>{item}</EnrollmentType>
                    </Label>
                  ))}
                </TableCell>
                <TableCell className={classes.cell}>
                  <FormattedDate>{enrollment.createdAt}</FormattedDate>
                </TableCell>
                <TableCell className={classes.cell}>
                  {enrollment.payment.length !== 0 && (
                    <Amount>{enrollment.payment?.reduce((acc, cur) => acc + cur.amount, 0)}</Amount>
                  )}
                </TableCell>
                <TableCell className={classes.cell}>
                  <Typography variant="h6">
                    {enrollment.contacts[0]?.firstName} {enrollment.contacts[0]?.lastName}
                  </Typography>
                  <span>{enrollment.contacts[0]?.email}</span>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      <Menu
        open={
          contextMenuState.mouseY !== null &&
          selectedEnrollmentIds &&
          selectedEnrollmentIds.length > 0
        }
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenuState.mouseY !== null && contextMenuState.mouseX !== null
            ? { top: contextMenuState.mouseY, left: contextMenuState.mouseX }
            : undefined
        }
      >
        <MenuItem onClick={handleContextMenuEdit}>Rediger</MenuItem>
        <MenuItem onClick={handleConfirmOpen}>Kopier påmelding</MenuItem>
      </Menu>
      <Dialog onClose={handleEditDialogClose} open={editDialogOpen}>
        <DialogTitle onClose={handleEditDialogClose}>Rediger påmeldinger</DialogTitle>
        <DialogContent dividers>
          <TextField
            margin="dense"
            fullWidth
            label="Status"
            className={classes.input}
            variant="outlined"
            name="status"
            select
            value={selectedStatus}
            onChange={handleStatusChange}
          >
            {getEnrollmentInputStatuses().map((s) => {
              return (
                <MenuItem value={s.id}>
                  <EnrollmentStatus>{s.id}</EnrollmentStatus>
                </MenuItem>
              )
            })}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleSaveChanges} color="primary">
            Lagre
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openConfirm}
        onClose={handleConfirmClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Kopier påmelding{selectedEnrollmentIds.length > 1 && 'er'}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmClose} color="secondary" autoFocus>
            Avbryt
          </Button>
          <Button
            onClick={() => {
              handleCreateEnrollments()
            }}
            color="primary"
          >
            Kopier
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default EnrollmentsTable
