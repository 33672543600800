import { json2csvAsync } from 'json-2-csv'
import xlsx from 'json-as-xlsx'

export async function jsonToCsv(arr, keys) {
  if (Array.isArray(arr)) {
    const options = {
      delimiter: {
        wrap: '"', // Double Quote (") character
        field: ',', // Comma field delimiter
        eol: '\n', // Newline delimiter
      },
      prependHeader: true,
      sortHeader: false,
      excelBOM: true,
      trimHeaderValues: true,
      trimFieldValues: true,
      expandArrayObjects: true,
      keys: keys || null,
    }
    return await json2csvAsync(arr, options)
  }
}

export async function jsonToXlsx(columns, content, settings) {
  if (Array.isArray(columns)) {
    xlsx(columns, content, settings, true)
  }
}
