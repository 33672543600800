import React from 'react'
import PropTypes from 'prop-types'
import constants from '../../utils/constants'

const EnrollmentType = ({ children }) => {
  if (children === constants.enrollmentTypes.odDay) return <>OD-dagen</>
  if (children === constants.enrollmentTypes.iu) return <>Internasjonal uke</>
  if (children === constants.enrollmentTypes.odSchool) return <>OD-skole</>

  return <>Ukjent rolle</>
}

EnrollmentType.propTypes = {
  children: PropTypes.string.isRequired,
}

export default EnrollmentType
