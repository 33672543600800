import React from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { Grid, CircularProgress } from '@material-ui/core'
import getIdFromSlug from '../../../utils/getIDfromSlug'
import ContactInfo from './ContactInfo'
import ErrorMessage from '../../../components/ErrorMessage'

const GET_CONTACT_SUMMARY = gql`
  query ($id: ID!) {
    contact(id: $id) {
      id
      firstName
      lastName
      fullName
      phoneNumber
      email
      type
      title
      enrollment {
        id
        slug
        school {
          id
          slug
        }
        schoolName
      }
    }
  }
`

const Summary = (props) => {
  const { slug } = props
  const id = getIdFromSlug(slug)
  const { data, loading, error } = useQuery(GET_CONTACT_SUMMARY, {
    variables: { id: id },
    fetchPolicy: 'no-cache',
  })

  if (loading) return <CircularProgress color="secondary" />
  if (error) return <ErrorMessage>{error.message}</ErrorMessage>

  return (
    <Grid container spacing={3}>
      <Grid item lg={7} md={6} xl={3} xs={12}>
        <ContactInfo contact={data.contact} />
      </Grid>
    </Grid>
  )
}

Summary.propTypes = {
  slug: PropTypes.string,
}

export default Summary
