import { useQuery } from '@apollo/react-hooks'
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Chip,
  Checkbox,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Link as RouterLink } from '@reach/router'
import { gql } from 'apollo-boost'
import ErrorMessage from 'components/ErrorMessage'
import Label from 'components/Label'
import ResultsTablePagination from 'components/ResultsTablePagination'
import SkeletonTable from 'components/SkeletonTable'
import PropTypes from 'prop-types'
import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import constants from 'utils/constants'
import { useQueryParams } from 'utils/hooks'
import getInitials from 'utils/getInitials'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { useMutation } from '@apollo/react-hooks'

const GET_USERS = gql`
  query ($filter: UserFilterInput!) {
    usersSearch(filter: $filter) {
      items {
        id
        slug
        fullName
        firstName
        lastName
        email
        mobile
        profileImage
        role
        status
        additionalUserInfo {
          school
          grade
          line
          title
          description
          birthDate
          comments
        }
        districts {
          id
          title
        }
        schools {
          id
          name
          slug
        }
      }
      total
    }
  }
`

const DEACTIVATE_USER = gql`
  mutation ($input: UserInput!) {
    deactivateUser(input: $input) {
      id
      status
    }
  }
`
const UPDATE_USER = gql`
  mutation ($input: UserInput!) {
    updateUser(input: $input) {
      id
      status
    }
  }
`

const useStyles = makeStyles((theme) => ({
  root: { marginTop: theme.spacing(3) },
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 700,
  },
  cell: {
    whiteSpace: 'nowrap',
  },
  link: {
    display: 'block',
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1),
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: 'flex-end',
  },
  menu: {
    border: '0px',
    boxShadow: 'none',
  },
}))

const initialContextMenuState = {
  mouseX: null,
  mouseY: null,
}

const Results = (props) => {
  const { location, multiSelectable } = props
  const classes = useStyles()
  const { queryVariables, page, searchString, rowsPerPage } = useQueryParams(location)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [contextMenuState, setContextMenuState] = React.useState(initialContextMenuState)
  const [selectedUserIds, setSelectedUserIds] = React.useState([])
  const [selectedStatus, setSelectedStatus] = React.useState(null)

  const { data, loading, error, client, refetch } = useQuery(GET_USERS, {
    variables: { filter: queryVariables },
  })

  const [deactivateUser] = useMutation(DEACTIVATE_USER)
  const [updateUser] = useMutation(UPDATE_USER)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleContextClick = (event) => {
    event.preventDefault()
    setContextMenuState({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    })
  }

  const onClickUpdate = async (item) => {
    setAnchorEl(null)
    try {
      await updateUser({
        variables: {
          input: { id: item.id, status: item.status === 'active' ? 'inactive' : 'active' },
        },
      })
      if (item.status === 'active') {
        return await deactivateUser({
          variables: {
            input: { id: item.id },
          },
        })
      }
    } catch {
      console.log('error')
    }

    await refetch()
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleContextMenu = async () => {
    setContextMenuState(initialContextMenuState)

    selectedUserIds.forEach(async (item) => {
      const userToUpdate = items.find((user) => user.id === item)

      try {
        await updateUser({
          variables: {
            input: {
              id: userToUpdate.id,
              status: userToUpdate.status === 'active' ? 'inactive' : 'active',
            },
          },
        })
        if (userToUpdate.status === 'active') {
          await deactivateUser({
            variables: {
              input: { id: userToUpdate.id },
            },
          })
        }
      } catch (error) {
        console.log('error', error)
      }
    })

    await refetch()
    setSelectedUserIds([])
  }

  const handleSelectAll = () => {
    let allUsersIds = []
    if (items && items.length > 0) {
      allUsersIds = items.map((e) => {
        return e.id
      })
    }

    setSelectedUserIds(
      selectedUserIds && selectedUserIds.length > 0
        ? []
        : allUsersIds && allUsersIds.length > 0
        ? allUsersIds
        : []
    )
  }

  const handleSingleSelect = (e) => {
    let selected = []
    Object.assign(selected, selectedUserIds)

    if (e.target.checked) {
      selected.push(e.target.value)
    } else {
      selected = selected.filter((s) => {
        return s !== e.target.value
      })
    }

    setSelectedUserIds(selected)
  }

  const isUserSelected = (id) => {
    return selectedUserIds.includes(id)
  }

  // SkeletonView
  if (loading)
    return (
      <SkeletonTable
        cardHeader={'Alle frivillige'}
        searchString={searchString}
        rowsPerPage={rowsPerPage}
        columns={constants.columns.users}
      />
    )

  if (error) return <ErrorMessage>{error.message}</ErrorMessage>

  const { items, total } = data.usersSearch

  return (
    <div className={classes.root} onContextMenu={handleContextClick}>
      <Typography color="textSecondary" gutterBottom variant="body2">
        {total} Resultater. Side {page + 1} av {Math.ceil(total / rowsPerPage)}
      </Typography>
      <Card>
        <CardHeader title={searchString === '' ? 'Alle frivillige' : `Søk på ${searchString}`} />
        <Divider />
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    {multiSelectable && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          indeterminate={
                            selectedUserIds &&
                            selectedUserIds.length > 0 &&
                            selectedUserIds.length !== items?.length
                          }
                          checked={selectedUserIds && selectedUserIds.length === items?.length}
                          onChange={handleSelectAll}
                        />
                      </TableCell>
                    )}
                    {constants.columns.users.map((column) => (
                      <TableCell key={column}>{column}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items.map((item) => {
                    return (
                      <TableRow hover key={item.id}>
                        {multiSelectable && (
                          <TableCell padding="checkbox">
                            <Checkbox
                              value={item.id}
                              checked={isUserSelected(item.id)}
                              onChange={handleSingleSelect}
                            />
                          </TableCell>
                        )}
                        <TableCell className={classes.cell}>
                          <div className={classes.nameCell}>
                            <Avatar className={classes.avatar} src={item.profileImage}>
                              {getInitials(item.fullName)}
                            </Avatar>
                            <div>
                              <Link
                                color="inherit"
                                component={RouterLink}
                                to={`/users/${item.slug}`}
                                variant="h6"
                              >
                                {item.fullName}
                              </Link>
                              <Link
                                color="primary"
                                className={classes.link}
                                href={`mailto:${item.email}`}
                              >
                                {item.email}
                              </Link>
                              {item.status === 'inactive' && (
                                <Chip size="small" label="Deaktivert" />
                              )}
                            </div>
                          </div>
                        </TableCell>
                        <TableCell className={classes.cell}>{item.mobile}</TableCell>
                        <TableCell className={classes.cell}>
                          {item.districts.map((district) => (
                            <Label key={district.id} variant="outlined">
                              {district.title}
                            </Label>
                          ))}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {item.additionalUserInfo?.title}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {item.schools?.map((school) => (
                            <div className={classes.nameCell} key={school.slug}>
                              <Link
                                color="inherit"
                                component={RouterLink}
                                to={`/schools/${school.slug}`}
                              >
                                {school.name}
                              </Link>
                            </div>
                          ))}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          <IconButton
                            id={`users-menu-button-${item.id}`}
                            onClick={handleClick}
                            aria-controls={`users-menu-${item.id}`}
                            aria-haspopup="true"
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <Menu
                            className={classes.menu}
                            id={`users-menu-${item.id}`}
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl?.id === `users-menu-button-${item.id}`)}
                            onClose={handleClose}
                            getContentAnchorEl={null}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            transformOrigin={{ horizontal: 'center', vertical: 'center' }}
                            elevation={1}
                            PaperProps={{
                              style: {
                                width: '8em',
                              },
                            }}
                          >
                            <MenuItem
                              onClick={() => {
                                onClickUpdate(item)
                              }}
                            >
                              {item.status === 'active' ? 'Deaktiver' : 'Aktiver'}
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
              <Menu
                open={
                  contextMenuState.mouseY !== null && selectedUserIds && selectedUserIds.length > 0
                }
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={
                  contextMenuState.mouseY !== null && contextMenuState.mouseX !== null
                    ? { top: contextMenuState.mouseY, left: contextMenuState.mouseX }
                    : undefined
                }
              >
                <MenuItem onClick={handleContextMenu}>Deaktiver eller aktiver</MenuItem>
              </Menu>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <ResultsTablePagination
            location={location}
            page={page}
            total={total}
            rowsPerPage={rowsPerPage}
            queryVariables={queryVariables}
            client={client}
            query={GET_USERS}
          />
        </CardActions>
      </Card>
    </div>
  )
}

Results.propTypes = {
  location: PropTypes.object,
}

Results.defaultProps = {}

export default Results
