import React from 'react'
import {
  Typography,
  Button,
  TextField,
  Tooltip,
  IconButton,
  MenuItem,
  Box,
} from '@material-ui/core'
import RemoveIcon from '@material-ui/icons/Remove'
import AddIcon from '@material-ui/icons/Add'
import { makeStyles } from '@material-ui/styles'
import { Field, FieldArray } from 'formik'
import constants from 'utils/constants'

const useStyles = makeStyles((theme) => ({
  label: {
    marginBottom: theme.spacing(2),
  },
  inputField: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  contactHeader: {
    marginTop: theme.spacing(3),
  },
}))

const SchollEnrollmentContacts = (props) => {
  const { values, touched, errors } = props
  const classes = useStyles()

  const inputProps = {
    className: classes.inputField,
    as: TextField,
    variant: 'outlined',
    required: true,
    fullWidth: true,
  }

  const checkErrors = (name, index) =>
    touched.contacts &&
    touched.contacts[index] &&
    touched.contacts[index][`${name}`] &&
    errors.contacts &&
    errors.contacts[index] &&
    !!errors.contacts[index][`${name}`]

  return (
    <>
      <Box mb={4}>
        <Typography align="center" component="p" gutterBottom variant="h5">
          Fyll inn hvem som er kontaktpersoner for OD på deres skole. Dere må fylle inn en ansvarlig
          lærer, men det er også lurt å fylle inn en eller flere elever med OD-ansvar, og flere
          lærere. Om ikke alle kontaktpersonene er bestemt kan dere gi oss beskjed om dette på et
          senere tidspunkt!
        </Typography>
      </Box>
      <FieldArray
        name="contacts"
        render={(arrayHelpers) => (
          <>
            <Typography variant="h5" className={classes.contactHeader}>
              Hovedkontakt (ansvarlig lærer)
            </Typography>
            {values.contacts &&
              values.contacts.length > 0 &&
              values.contacts.map((contact, index) => (
                <div key={index}>
                  {index !== 0 && (
                    <Typography variant="h5" className={classes.contactHeader}>
                      Kontaktperson {index + 1}{' '}
                      <Tooltip title="Fjern">
                        <IconButton onClick={() => arrayHelpers.remove(index)} color="primary">
                          <RemoveIcon />
                        </IconButton>
                      </Tooltip>
                    </Typography>
                  )}
                  <Field
                    {...inputProps}
                    autoFocus
                    name={`contacts[${index}].firstName`}
                    as={TextField}
                    label="Fornavn"
                    error={checkErrors('firstName', index)}
                    helperText={
                      checkErrors('firstName', index) ? errors.contacts[index].firstName : ' '
                    }
                  />
                  <Field
                    {...inputProps}
                    name={`contacts[${index}].lastName`}
                    label="Etternavn"
                    error={checkErrors('lastName', index)}
                    helperText={
                      checkErrors('lastName', index) ? errors.contacts[index].lastName : ' '
                    }
                  />
                  <Field
                    {...inputProps}
                    name={`contacts[${index}].email`}
                    label="E-post"
                    error={checkErrors('email', index)}
                    helperText={checkErrors('email', index) ? errors.contacts[index].email : ' '}
                  />
                  <Field
                    {...inputProps}
                    name={`contacts[${index}].phoneNumber`}
                    label="Telefon"
                    type="phone"
                    error={checkErrors('phoneNumber', index)}
                    helperText={
                      checkErrors('phoneNumber', index) ? errors.contacts[index].phoneNumber : ' '
                    }
                  />
                  {index !== 0 && (
                    <Field
                      {...inputProps}
                      name={`contacts[${index}].type`}
                      select
                      error={checkErrors('type', index)}
                      helperText={checkErrors('type', index) ? errors.contacts[index].type : ' '}
                    >
                      <MenuItem value="disabled" disabled>
                        Velg rolle *
                      </MenuItem>
                      <MenuItem value={constants.contactTypes.teacher}>Lærer</MenuItem>
                      <MenuItem value={constants.contactTypes.student}>Elev</MenuItem>
                    </Field>
                  )}
                  <Field
                    {...inputProps}
                    required={false}
                    name={`contacts[${index}].title`}
                    label="Rolle på skolen"
                    error={checkErrors('title', index)}
                    helperText={checkErrors('title', index) ? errors.contacts[index].title : ' '}
                  />
                </div>
              ))}
            <Box mt={2}>
              <Button
                startIcon={<AddIcon />}
                type="button"
                color="primary"
                onClick={() => arrayHelpers.push({})}
              >
                Legg til kontakt
              </Button>
            </Box>
          </>
        )}
      />
    </>
  )
}

export default SchollEnrollmentContacts
