import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 700,
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: 'flex-end',
  },
}))

const SkeletonTable = ({ searchString, rowsPerPage, columns, cardHeader }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Box mb={2}>
        <Skeleton height={8} width={150} />
      </Box>
      <Card>
        <CardHeader
          title={searchString === '' ? cardHeader || 'Alle skoler' : `Søk på ${searchString}`}
        />
        <Divider />
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell key={column}>{column}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array(rowsPerPage)
                    .fill(rowsPerPage)
                    .map((item, index) => (
                      <TableRow hover key={index}>
                        {columns.map((column) => (
                          <TableCell key={column}>
                            <Skeleton height={10} width={80} />
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}></CardActions>
      </Card>
    </div>
  )
}

export default SkeletonTable
