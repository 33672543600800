import qs from 'qs'

export const useQueryParams = (location) => {
  let queryVariables = {}
  const searchQuery = qs.parse(location.search, { ignoreQueryPrefix: true })
  const currentPage = searchQuery.page || ''
  const page = currentPage ? parseInt(currentPage, 10) : 0
  const searchString = searchQuery.search || ''
  const rowsPerPage = searchQuery.rowsPerPage ? parseInt(searchQuery.rowsPerPage, 10) : 25

  queryVariables = {
    page,
    searchString,
    rowsPerPage,
  }

  if (searchQuery.types) {
    queryVariables.types = searchQuery.types
  }

  if (searchQuery.statuses) {
    queryVariables.statuses = searchQuery.statuses
  }

  if (searchQuery.type) {
    queryVariables.type = searchQuery.type
  }

  if (searchQuery.districts) {
    queryVariables.districts = searchQuery.districts
  }

  if (searchQuery.labels) {
    queryVariables.labels = searchQuery.labels
  }

  if (searchQuery.enrollmentStatuses) {
    queryVariables.enrollmentStatuses = searchQuery.enrollmentStatuses
  }

  if (searchQuery.enrollmentTypes) {
    queryVariables.enrollmentTypes = searchQuery.enrollmentTypes
  }

  if (searchQuery.projects) {
    queryVariables.projects = searchQuery.projects
  }

  if (searchQuery.status) {
    queryVariables.status = searchQuery.status
  }
  if (searchQuery.schoolTypes) {
    queryVariables.schoolTypes = searchQuery.schoolTypes
  }

  return { queryVariables, page, searchString, rowsPerPage }
}
