import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useQuery } from '@apollo/react-hooks'
import { makeStyles } from '@material-ui/styles'
import { navigate, useParams, useLocation } from '@reach/router'
import {
  Button,
  Drawer,
  FormControlLabel,
  Radio,
  Typography,
  FormHelperText,
  CircularProgress,
  TextField,
  Link,
} from '@material-ui/core'
import { Formik, Field, FieldArray } from 'formik'
import { RadioGroup } from 'formik-material-ui'
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import LabelsInput from 'components/LabelsInput'
import { gql } from 'apollo-boost'
import ErrorMessage from 'components/ErrorMessage'
import { getDateMinAndMaxFromPeriod } from '../../Results'
import qs from 'qs'
const moment = require('moment')

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  drawer: {
    width: 420,
    maxWidth: '100%',
  },
  header: {
    padding: theme.spacing(2, 1),
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
  content: {
    padding: theme.spacing(0, 3),
    flexGrow: 1,
  },
  contentSection: {
    padding: theme.spacing(2, 0),
  },
  contentSectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  contentSectionContent: {},
  formGroup: {
    padding: theme.spacing(2, 0),
  },
  fieldGroup: {
    display: 'flex',
    alignItems: 'center',
  },
  field: {
    marginTop: 0,
    marginBottom: 0,
  },
  flexGrow: {
    flexGrow: 1,
  },
  addButton: {
    marginLeft: theme.spacing(1),
  },
  tags: {
    marginTop: theme.spacing(1),
  },
  minAmount: {
    marginRight: theme.spacing(3),
  },
  maxAmount: {
    marginLeft: theme.spacing(3),
  },
  textField: {
    // marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  radioGroup: {},
  actions: {
    padding: theme.spacing(3),
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}))

const GET_DISTRICTS = gql`
  query {
    districts {
      id
      title
      slug
    }
  }
`

const Filter = (props) => {
  const location = useLocation()
  const params = qs.parse(location.search, { ignoreQueryPrefix: true })
  const { open, onClose, onFilter, className, initialValues: propValues, ...rest } = props
  const lastYearLabel = `I fjor (${new Date().getFullYear() - 1})`
  const classes = useStyles()

  const {
    data: districtsData,
    loading: districtsLoading,
    error: districtsError,
  } = useQuery(GET_DISTRICTS)

  const handleClear = () => {
    navigate(`/activities`)
    onClose()
  }

  if (districtsLoading) return <CircularProgress size={20} />
  if (districtsError) return <ErrorMessage>{districtsError?.message}</ErrorMessage>

  const allDistricts = districtsData.districts

  const initialValues = {
    labels: [],
    districts: [],
    dateMin: moment(new Date()).subtract(1, 'month').toDate().toISOString().slice(0, 10),
    dateMax: new Date().toISOString().slice(0, 10),
    ...propValues,
  }

  const handlePeriodClick = (period) => {
    const limits = getDateMinAndMaxFromPeriod(period)
    const newParams = {
      ...params,
      dateMin: limits.dateMin,
      dateMax: limits.dateMax,
    }
    const paramsString = qs.stringify(newParams)
    navigate(`activities?${paramsString}`)
    onClose()
  }

  return (
    <Drawer
      anchor="right"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant="temporary"
    >
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, actions) => {
          // Manipulate the labels to just be a set of ids
          const labels = values.labels.map((item) => item.id)
          onFilter && onFilter({ ...values, labels })
          actions.setSubmitting(false)
          onClose()
        }}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit} {...rest} className={clsx(classes.root, className)}>
            <div className={classes.header}>
              <Button onClick={onClose} size="small">
                <CloseIcon className={classes.buttonIcon} />
                Lukk
              </Button>
            </div>
            <div className={classes.content}>
              <div className={classes.contentSection}>
                <div className={classes.contentSectionContent}>
                  <div className={classes.formGroup}>
                    <Typography component="p" gutterBottom variant="overline">
                      Dato
                    </Typography>
                    <div className={classes.fieldGroup}>
                      <Field
                        id="dateMin"
                        name="dateMin"
                        component={TextField}
                        label="Fra"
                        type="date"
                        // value={props.values.dateMin}
                        defaultValue={initialValues.dateMin}
                        onChange={props.handleChange}
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <Field
                        id="dateMax"
                        name="dateMax"
                        label="Til"
                        type="date"
                        component={TextField}
                        // value={props.values.dateMax}
                        defaultValue={initialValues.dateMax}
                        onChange={props.handleChange}
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className={classes.formGroup}
                    style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}
                  >
                    {/* <Typography component="p" gutterBottom variant="overline">
                      Periode
                    </Typography> */}
                    <Button
                      type="button"
                      variant="outlined"
                      size="small"
                      onClick={() => handlePeriodClick(0)}
                    >
                      Denne måneden
                    </Button>
                    <Button
                      type="button"
                      variant="outlined"
                      size="small"
                      onClick={() => handlePeriodClick(1)}
                    >
                      Forrige måned
                    </Button>
                    <Button
                      type="button"
                      variant="outlined"
                      size="small"
                      onClick={() => handlePeriodClick(2)}
                    >
                      Hittil i år
                    </Button>
                    <Button
                      type="button"
                      variant="outlined"
                      size="small"
                      onClick={() => handlePeriodClick(3)}
                    >
                      {lastYearLabel}
                    </Button>
                  </div>
                </div>
                <div className={classes.formGroup}>
                  <Typography component="p" gutterBottom variant="overline">
                    Filtrer på distrikt
                  </Typography>

                  <FieldArray
                    name="districts"
                    render={(arrayHelpers) => (
                      <LabelsInput
                        label="Søk etter distrikt"
                        tags={props.values.districts}
                        allTags={allDistricts}
                        arrayHelpers={arrayHelpers}
                        onAdd={(tag) => arrayHelpers.push(tag)}
                      />
                    )}
                  />
                  <FormHelperText>
                    Begynn å søk setter distrikt og velg fra nedtrekksmenyen.
                  </FormHelperText>
                </div>
              </div>
            </div>
            <div className={classes.actions}>
              <Button fullWidth onClick={handleClear} variant="contained">
                <DeleteIcon className={classes.buttonIcon} />
                Tilbakestill
              </Button>
              <Button color="primary" fullWidth type="submit" variant="contained">
                Bruk filtre
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </Drawer>
  )
}

Filter.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  onFilter: PropTypes.func,
  open: PropTypes.bool.isRequired,
}

export default Filter
