import { useQuery } from '@apollo/react-hooks'
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  Box,
  Grid,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { gql } from 'apollo-boost'
import ErrorMessage from 'components/ErrorMessage'
import ResultsTablePagination from 'components/ResultsTablePagination'
import SchoolsTable from 'components/SchoolsTable'
import SkeletonTable from 'components/SkeletonTable'
import fileDownload from 'js-file-download'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import constants from 'utils/constants'
import { jsonToCsv, jsonToXlsx } from 'utils/exportUtils'
import { useQueryParams } from 'utils/hooks'
import { assignLatestInfoToItems } from 'utils/enrollmentsHelper'
import ExportMenuList from 'components/ExportMenuList'
import { getLatestEnrolledProjects } from 'components/SchoolsTable'

const GET_SCHOOLS = gql`
  query ($filter: SchoolFilterInput!) {
    schoolsSearch(filter: $filter) {
      items {
        id
        name
        orgNr
        NSRId
        details {
          visitAddress {
            address
            zipCode
            place
          }
          postAddress {
            address
            zipCode
            place
          }
        }
        district {
          id
          title
        }
        email
        types
        status
        slug
        labels {
          id
          title
        }
        responsible {
          id
          slug
          fullName
          email
          role
          profileImage
          additionalUserInfo {
            title
          }
        }
        enrollments {
          id
          status
          types
          project {
            id
            title
            year
            status
          }
          contacts {
            id
            firstName
            lastName
            fullName
            phoneNumber
            email
            type
          }
          createdAt
          updatedAt
        }
        latestContactAt
        latestLabelUpdateAt
      }
      total
    }
  }
`

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 700,
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: 'flex-end',
  },
  box: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
}))

const Results = (props) => {
  const { location } = props
  const classes = useStyles()
  const [loadingExport, setLoadingExport] = useState(false)
  const [exportPage, setExportPage] = useState(1)
  const { queryVariables, page, searchString, rowsPerPage } = useQueryParams(location)

  // Request data
  const { data, loading, error, client, refetch } = useQuery(GET_SCHOOLS, {
    variables: { filter: queryVariables },
  })

  // SkeletonView
  if (loading)
    return (
      <SkeletonTable
        searchString={searchString}
        rowsPerPage={rowsPerPage}
        columns={constants.columns.schools}
      />
    )

  if (error) return <ErrorMessage>{error.message}</ErrorMessage>

  const schools = data.schoolsSearch.items
  const { total } = data.schoolsSearch

  const handleExport = async (exportType) => {
    setLoadingExport(true)

    let items = []
    for (let i = 0; i < Math.ceil(total / rowsPerPage); i++) {
      const response = await client.query({
        query: GET_SCHOOLS,
        variables: {
          filter: {
            ...queryVariables,
            page: i,
            rowsPerPage,
            sort: {
              order: 'asc',
              property: 'name',
            },
          },
        },
        fetchPolicy: 'network-only',
      })

      if (response?.data?.schoolsSearch?.items) {
        items = items.concat(response.data.schoolsSearch.items)
      }

      setExportPage(i + 1)
    }

    const updatedItems = assignLatestInfoToItems(items)

    if (exportType === constants.exportTypes.csv) {
      const columns = [
        'NSRId',
        'name',
        'types',
        'details.visitAddress.address',
        'details.visitAddress.zipCode',
        'details.visitAddress.place',
        'details.postAddress.address',
        'details.postAddress.zipCode',
        'details.postAddress.place',
        'district.title',
        'email',
        'latestEnrollmentYear',
        'latestEnrollmentType',
        'contact.firstName',
        'contact.lastName',
        'contact.email',
        'latestContactDate',
      ]

      const csv = await jsonToCsv(updatedItems, columns)
      fileDownload(csv, 'schools.csv', 'text/csv')
    } else if (exportType === constants.exportTypes.xls) {
      const columns = [
        { label: 'NSRId', value: 'NSRId' }, // Top level  data
        { label: 'name', value: 'name' },
        { label: 'type', value: 'types' },
        {
          label: 'details.visitAddress.address',
          value: (row) =>
            row.details && row.details.visitAddress && row.details.visitAddress.address
              ? row.details.visitAddress.address || ''
              : '',
        },
        {
          label: 'details.visitAddress.zipCode',
          value: (row) =>
            row.details && row.details.visitAddress && row.details.visitAddress.zipCode
              ? row.details.visitAddress.zipCode || ''
              : '',
        },
        {
          label: 'details.visitAddress.place',
          value: (row) =>
            row.details && row.details.visitAddress && row.details.visitAddress.place
              ? row.details.visitAddress.place || ''
              : '',
        },
        {
          label: 'details.postAddress.address',
          value: (row) =>
            row.details && row.details.postAddress && row.details.postAddress.address
              ? row.details.postAddress.address || ''
              : '',
        },
        {
          label: 'details.postAddress.zipCode',
          value: (row) =>
            row.details && row.details.postAddress && row.details.postAddress.zipCode
              ? row.details.postAddress.zipCode || ''
              : '',
        },
        {
          label: 'details.postAddress.place',
          value: (row) =>
            row.details && row.details.postAddress && row.details.postAddress.place
              ? row.details.postAddress.place || ''
              : '',
        },
        {
          label: 'district.title',
          value: (row) => (row.district && row.district.title ? row.district.title || '' : ''),
        },
        { label: 'email', value: 'email' },
        // { label: 'latestEnrollmentYear', value: 'latestEnrollmentYear' },
        {
          label: 'latestEnrollmentYear',
          value: (row) => getLatestEnrolledProjects(row.enrollments),
        },
        { label: 'latestEnrollmentType', value: 'latestEnrollmentType' },
        {
          label: 'contact.firstName',
          value: (row) => (row.contact && row.contact.firstName ? row.contact.firstName || '' : ''),
        },
        {
          label: 'contact.lastName',
          value: (row) => (row.contact && row.contact.lastName ? row.contact.lastName || '' : ''),
        },
        {
          label: 'contact.email',
          value: (row) => (row.contact && row.contact.email ? row.contact.email || '' : ''),
        },
        {
          label: 'latestContactDate',
          value: (row) => row.latestContactDate || '',
        },
      ]

      const settings = {
        sheetName: 'schools', // The name of the sheet
        fileName: 'schools', // The name of the spreadsheet
        extraLength: 3, // A bigger number means that columns should be wider
        writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
      }

      jsonToXlsx(columns, updatedItems, settings, true)
    }

    setLoadingExport(false)
    setExportPage(1)
  }

  return (
    <div className={classes.root}>
      <Typography color="textSecondary" gutterBottom variant="body2">
        {total} resultater. Side {page + 1} av {Math.ceil(total / rowsPerPage)}
      </Typography>
      <Card>
        <CardHeader
          title={searchString === '' ? 'Alle skoler' : `Søk på ${searchString}`}
          action={
            <Grid container direction="row" justify="flex-end" alignItems="stretch" spacing={2}>
              <Grid item>
                <Box visibility={loadingExport ? 'visible' : 'hidden'} className={classes.box}>
                  <Typography color="textSecondary" variant="body2">
                    Lastet ned {exportPage} av {Math.ceil(total / rowsPerPage)} side
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <ExportMenuList
                  onSelect={handleExport}
                  loadingExport={loadingExport}
                ></ExportMenuList>
              </Grid>
            </Grid>
          }
        />
        <Divider />
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <SchoolsTable
                schools={schools}
                columns={constants.columns.schools}
                refetch={refetch}
              />
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <ResultsTablePagination
            location={location}
            page={page}
            total={total}
            rowsPerPage={rowsPerPage}
            queryVariables={queryVariables}
            client={client}
            query={GET_SCHOOLS}
          />
        </CardActions>
      </Card>
    </div>
  )
}

Results.propTypes = {
  location: PropTypes.object,
}

export default Results
