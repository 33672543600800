import React from 'react'
import { Typography } from '@material-ui/core'

const SelectedSchool = props => {
  const { school } = props

  return (
    <>
      <Typography gutterBottom component="p" variant="h4">
        {school.orgNr && school.name}
      </Typography>
      <Typography component="p" variant="body1">
        {`${school?.nsrData?.Besoksadresse?.Adress}
        
        ${school?.nsrData?.Besoksadresse?.Postnr}
        ${school?.nsrData?.Besoksadresse?.Poststed}`}
      </Typography>
      <Typography component="p" variant="body1">
        Org. nr: {school.orgNr && school.orgNr}
      </Typography>
      <Typography component="p" variant="body1">
        E-post: {school.email && school.email}
      </Typography>
    </>
  )
}

export default SelectedSchool
