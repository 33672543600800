import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { Typography, colors, TextField, Button } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/CheckRounded'
import CancelIcon from '@material-ui/icons/CancelRounded'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 0,
    flexShrink: 0,
    borderRadius: theme.shape.borderRadius,
    lineHeight: '10px',
    fontSize: '10px',
    height: 20,
    minWidth: 20,
    whiteSpace: 'nowrap',
    padding: theme.spacing(0.5, 1),
    margin: theme.spacing(0.5, 0.5),
  },
  rounded: {
    borderRadius: 10,
    padding: theme.spacing(0.5),
  },
  editorContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  actionButtons: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
}))

const Label = (props) => {
  const {
    className,
    variant,
    color,
    shape,
    children,
    style,
    model,
    editMode,
    onConfirmUpdate,
    onCancelUpdate,
    ...rest
  } = props
  const classes = useStyles()
  const rootClassName = clsx(
    {
      [classes.root]: true,
      [classes.rounded]: shape === 'rounded',
    },
    className
  )
  const finalStyle = { ...style }
  const [editableLabel, setEditableLabel] = useState(model?.title || null)

  if (variant === 'contained') {
    finalStyle.backgroundColor = color
    finalStyle.color = '#FFF'
  } else {
    finalStyle.border = `1px solid ${color}`
    finalStyle.color = color
  }

  if (!editMode) {
    return (
      <Typography {...rest} className={rootClassName} style={finalStyle} variant="overline">
        {children}
      </Typography>
    )
  }

  return (
    <div>
      {editMode && (
        <div className={classes.editorContainer}>
          <TextField
            autoFocus
            fullWidth
            value={editableLabel}
            onChange={(event) => setEditableLabel(event.target.value)}
          />
          <div className={classes.actionButtons}>
            <Button
              startIcon={<CheckIcon />}
              onClick={async () => {
                await onConfirmUpdate({ ...model, title: editableLabel })
              }}
              disabled={
                !editableLabel || editableLabel.length === 0 || editableLabel === model.title
              }
              color="primary"
            />
            <Button
              startIcon={<CancelIcon />}
              onClick={() => {
                onCancelUpdate()
                setEditableLabel(model.title) // restore the original title on cancel
              }}
              color="secondary"
            />
          </div>
        </div>
      )}
    </div>
  )
}

Label.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string,
  shape: PropTypes.oneOf(['square', 'rounded']),
  style: PropTypes.object,
  variant: PropTypes.oneOf(['contained', 'outlined']),
  model: PropTypes.object,
  editMode: PropTypes.bool,
  onConfirmUpdate: PropTypes.func,
  onCancelUpdate: PropTypes.func,
}

Label.defaultProps = {
  style: {},
  color: colors.grey[600],
  variant: 'contained',
  shape: 'square',
  model: null,
  editMode: false,
}

export default Label
