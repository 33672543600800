import React from 'react'
import { Link as RouterLink } from '@reach/router'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { AppBar, IconButton, Toolbar, Hidden } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import logo from '../../../../images/logo--white.svg'

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: 'none',
  },
  flexGrow: {
    flexGrow: 1,
  },
}))

const TopBar = (props) => {
  const { onOpenNavBarMobile, className, ...rest } = props

  const classes = useStyles()

  return (
    <AppBar {...rest} className={clsx(classes.root, className)} color="primary">
      <Toolbar>
        <RouterLink to="/">
          <img alt="Logo" src={logo} />
        </RouterLink>
        <div className={classes.flexGrow} />

        <Hidden lgUp>
          <IconButton color="inherit" onClick={onOpenNavBarMobile}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  )
}

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func,
}

export default TopBar
