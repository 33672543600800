import { useQuery } from '@apollo/react-hooks'
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Box,
  Grid,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Link as RouterLink } from '@reach/router'
import { gql } from 'apollo-boost'
import ErrorMessage from 'components/ErrorMessage'
import ResultsTablePagination from 'components/ResultsTablePagination'
import SkeletonTable from 'components/SkeletonTable'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import constants from 'utils/constants'
import { useQueryParams } from 'utils/hooks'
import getInitials from 'utils/getInitials'
import qs from 'qs'
import { jsonToCsv, jsonToXlsx } from 'utils/exportUtils'
import ExportMenuList from 'components/ExportMenuList'
import fileDownload from 'js-file-download'
const moment = require('moment')

const GET_USERS = gql`
  query ($filter: UserFilterInput!) {
    usersSearch(filter: $filter) {
      items {
        id
        slug
        fullName
        email
        profileImage
      }
      total
    }
  }
`

const GET_LOGS = gql`
  query logsSearch($filter: LogFilterInput!) {
    logsSearch(filter: $filter) {
      items {
        id
        type
        contentRaw
        createdAt
        user {
          id
        }
        school {
          id
        }
      }
    }
  }
`

const useStyles = makeStyles((theme) => ({
  root: { marginTop: theme.spacing(3) },
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 700,
  },
  cell: {
    whiteSpace: 'nowrap',
  },
  link: {
    display: 'block',
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1),
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: 'flex-end',
  },
}))

export const getDateMinAndMaxFromPeriod = (period) => {
  const today = new Date()
  let limits = {
    dateMin: moment(new Date(today.getFullYear(), today.getMonth(), 1))
      .toDate()
      .toISOString()
      .slice(0, 10),
    dateMax: moment(new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59))
      .toDate()
      .toISOString()
      .slice(0, 10),
  }

  switch (period.toString()) {
    case '1': // 'Forrige måned'
      limits = {
        dateMin: moment(new Date(today.getFullYear(), today.getMonth(), 1))
          .subtract(1, 'month')
          .toDate()
          .toISOString()
          .slice(0, 10),
        dateMax: moment(new Date(today.getFullYear(), today.getMonth(), 1))
          .subtract(1, 'seconds')
          .toDate()
          .toISOString()
          .slice(0, 10),
      }

      break
    case '2':
      // "Hittil i år"
      limits = {
        dateMin: moment(new Date(today.getFullYear(), 0, 1)).toDate().toISOString().slice(0, 10),
        dateMax: moment(
          new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59)
        )
          .toDate()
          .toISOString()
          .slice(0, 10),
      }

      break
    case '3':
      // "I fjor"
      limits = {
        dateMin: moment(new Date(today.getFullYear(), 0, 1))
          .subtract(1, 'year')
          .toDate()
          .toISOString()
          .slice(0, 10),
        dateMax: moment(new Date(today.getFullYear(), 0, 1))
          .subtract(1, 'seconds')
          .toDate()
          .toISOString()
          .slice(0, 10),
      }

      break
    case '0':
    default: {
      // using the default limits
    }
  }

  return limits
}

const Results = (props) => {
  const { location } = props
  const { period, dateMin, dateMax } = qs.parse(location.search, { ignoreQueryPrefix: true }) || {}
  const classes = useStyles()
  const { queryVariables, page, searchString, rowsPerPage } = useQueryParams(location)
  const [userIds, setUserIds] = useState(false)
  const [queryPeriod, setQueryPeriod] = useState(0)
  const [limits, setLimits] = useState(null)
  const [loadingExport, setLoadingExport] = useState(false)
  const [exportPage, setExportPage] = useState(1)

  const {
    data: usersData,
    loading: usersLoading,
    error: usersError,
    client,
  } = useQuery(GET_USERS, {
    variables: { filter: { ...queryVariables, status: 'active' } },
  })

  useEffect(() => {
    if (!usersLoading && !usersError && usersData?.usersSearch?.items) {
      const ids = usersData?.usersSearch?.items
        .map((u) => {
          return u.id
        })
        .filter((id, i, ids) => ids.indexOf(id) === i)

      if (ids !== userIds) {
        setUserIds(ids)
      }
    } else {
      setUserIds(null)
    }
    if (period !== queryPeriod) {
      setQueryPeriod(period || 0)
      setLimits(getDateMinAndMaxFromPeriod(period || 0))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersLoading, usersError, usersData, period])

  const {
    data: logsData,
    loading: logsLoading,
    error: logsError,
  } = useQuery(GET_LOGS, {
    variables: {
      filter: {
        users: userIds,
        types: [constants.logTypes.call, constants.logTypes.message, constants.logTypes.system],
        dateMin: dateMin || limits?.dateMin,
        dateMax: dateMax || limits?.dateMax,
        rowsPerPage: 100000 /* all matches to fit on one page */,
      },
    },
    skip: !userIds,
    fetchPolicy: 'no-cache',
  })

  if (usersError || logsError)
    return <ErrorMessage>{(usersError && usersError.message) || logsError.message}</ErrorMessage>

  // SkeletonView
  if (usersLoading || logsLoading || !usersData?.usersSearch || !logsData?.logsSearch)
    return (
      <SkeletonTable
        cardHeader={'Alle frivillige'}
        searchString={searchString}
        rowsPerPage={rowsPerPage}
        columns={constants.columns.activities}
      />
    )

  const { items: users, total } = usersData?.usersSearch
  const { items: logs } = logsData?.logsSearch

  const items = users.map((u) => {
    const userLogs = logs.filter((l) => {
      return l.user.id === u.id
    })
    let data = { ...u }
    Object.assign(data, {
      numberOfContactedSchools: userLogs
        .filter((l) => {
          return l.type !== constants.logTypes.system
        })
        .map((l) => {
          return l.school.id
        })
        .filter((id, i, ids) => ids.indexOf(id) === i).length,
      numberOfMessages: userLogs.filter((l) => {
        return l.type !== constants.logTypes.system
      }).length,
      numberOfLabelUpdates: userLogs.filter((l) => {
        return (
          l.type === constants.logTypes.system && l.contentRaw.includes('oppdaterte etikettene')
        )
      }).length,
    })

    return data
  })

  const cardTitle = `${
    searchString === '' ? 'Alle frivillige' : `Søk på ${searchString}`
  }, periode: ${new Date(dateMin || limits?.dateMin)?.toLocaleDateString()} - ${new Date(
    dateMax || limits?.dateMax
  )?.toLocaleDateString()}`

  const handleExport = async (exportType) => {
    setLoadingExport(true)

    let items = []
    for (let i = 0; i < Math.ceil(total / rowsPerPage); i++) {
      const usersResponse = await client.query({
        query: GET_USERS,
        variables: {
          filter: {
            ...queryVariables,
            page: i,
            rowsPerPage,
            sort: {
              order: 'asc',
              property: 'fullName',
            },
          },
        },
        fetchPolicy: 'no-cache',
      })

      const users = usersResponse?.data?.usersSearch?.items
      const userIds = users
        .map((u) => {
          return u.id
        })
        .filter((id, i, userIds) => userIds.indexOf(id) === i)

      const logsResponse = await client.query({
        query: GET_LOGS,
        variables: {
          filter: {
            users: userIds,
            types: [constants.logTypes.call, constants.logTypes.message, constants.logTypes.system],
            dateMin: limits?.dateMin,
            dateMax: limits?.dateMax,
            rowsPerPage: 100000 /* all matches to fit on one page */,
          },
        },
        fetchPolicy: 'no-cache',
      })

      const logs = logsResponse?.data?.logsSearch?.items
      const userItems = users.map((u) => {
        const userLogs = logs.filter((l) => {
          return l.user.id === u.id
        })
        let data = { ...u }
        Object.assign(data, {
          numberOfContactedSchools: userLogs
            .filter((l) => {
              return l.type !== constants.logTypes.system
            })
            .map((l) => {
              return l.school.id
            })
            .filter((id, i, ids) => ids.indexOf(id) === i).length,
          numberOfMessages: userLogs.filter((l) => {
            return l.type !== constants.logTypes.system
          }).length,
          numberOfLabelUpdates: userLogs.filter((l) => {
            return (
              l.type === constants.logTypes.system && l.contentRaw.includes('oppdaterte etikettene')
            )
          }).length,
        })

        return data
      })
      if (userItems) {
        items = items.concat(userItems)
      }

      setExportPage(i + 1)
    }

    if (exportType === constants.exportTypes.csv) {
      const columns = [
        'fullName',
        'email',
        'numberOfContactedSchools',
        'numberOfMessages',
        'numberOfLabelUpdates',
      ]

      const csv = await jsonToCsv(items, columns)
      fileDownload(csv, 'aktivitet.csv', 'text/csv')
    } else if (exportType === constants.exportTypes.xls) {
      const columns = [
        { label: 'fullName', value: 'fullName' },
        { label: 'email', value: 'email' },
        { label: 'numberOfContactedSchools', value: 'numberOfContactedSchools' },
        { label: 'numberOfMessages', value: 'numberOfMessages' },
        { label: 'numberOfLabelUpdates', value: 'numberOfLabelUpdates' },
      ]

      const settings = {
        sheetName: 'aktivitet', // The name of the sheet
        fileName: 'aktivitet', // The name of the spreadsheet
        extraLength: 3, // A bigger number means that columns should be wider
        writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
      }

      jsonToXlsx(columns, items, settings, true)
    }

    setLoadingExport(false)
    setExportPage(1)
  }

  return (
    <div className={classes.root}>
      <Typography color="textSecondary" gutterBottom variant="body2">
        {total} Resultater. Side {page + 1} av {Math.ceil(total / rowsPerPage)}
      </Typography>
      <Card>
        <CardHeader
          title={cardTitle}
          action={
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="stretch"
              spacing={2}
            >
              <Grid item>
                <Box visibility={loadingExport ? 'visible' : 'hidden'} className={classes.box}>
                  <Typography color="textSecondary" variant="body2">
                    Lastet ned {exportPage} av {Math.ceil(total / rowsPerPage)} side
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <ExportMenuList
                  onSelect={handleExport}
                  loadingExport={loadingExport}
                ></ExportMenuList>
              </Grid>
            </Grid>
          }
        />
        <Divider />
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    {constants.columns.activities.map((column) => (
                      <TableCell key={column}>{column}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items.map((item) => {
                    return (
                      <TableRow hover key={item.id}>
                        <TableCell className={classes.cell}>
                          <div className={classes.nameCell}>
                            <Avatar className={classes.avatar} src={item.profileImage}>
                              {getInitials(item.fullName)}
                            </Avatar>
                            <div>
                              <Link
                                color="inherit"
                                component={RouterLink}
                                to={`/users/${item.slug}`}
                                variant="h6"
                              >
                                {item.fullName}
                              </Link>
                              <Link
                                color="primary"
                                className={classes.link}
                                href={`mailto:${item.email}`}
                              >
                                {item.email}
                              </Link>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {item.numberOfContactedSchools}
                        </TableCell>
                        <TableCell className={classes.cell}>{item.numberOfMessages}</TableCell>
                        <TableCell className={classes.cell}>{item.numberOfLabelUpdates}</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <ResultsTablePagination
            location={location}
            page={page}
            total={total}
            rowsPerPage={rowsPerPage}
            queryVariables={queryVariables}
            client={client}
            query={GET_USERS}
          />
        </CardActions>
      </Card>
    </div>
  )
}

Results.propTypes = {
  location: PropTypes.object,
}

Results.defaultProps = {}

export default Results
