import React, { useEffect } from 'react'
import { useAuth } from 'react-use-auth'
import { makeStyles } from '@material-ui/styles'
import Page from '../../components/Page'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(6, 2),
  },
}))

const Logout = () => {
  const { logout } = useAuth()
  const classes = useStyles()

  useEffect(() => {
    return logout()
  })

  return (
    <Page className={classes.root} title="Logg ut">
      <CircularProgress />
    </Page>
  )
}

export default Logout
