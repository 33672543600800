import PeopleIcon from '@material-ui/icons/PeopleOutlined'
import ApartmentIcon from '@material-ui/icons/Apartment'
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'
import PublicIcon from '@material-ui/icons/Public'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import DashboardIcon from '@material-ui/icons/Dashboard'
import TrackChangesIcon from '@material-ui/icons/TrackChanges'
import LabelImportantIcon from '@material-ui/icons/LabelImportant'

export default [
  {
    title: 'Sider',
    pages: [
      {
        title: 'Dashboard',
        href: '/dashboard',
        icon: DashboardIcon,
      },
      {
        title: 'Påmeldinger',
        href: '/enrollments',
        icon: AssignmentTurnedInIcon,
      },
      {
        title: 'Skoler',
        href: '/schools',
        icon: ApartmentIcon,
      },
      {
        title: 'Elever og lærere',
        href: '/contacts',
        icon: PeopleIcon,
      },
      {
        title: 'Frivillige i OD',
        href: '/users',
        icon: AssignmentIndIcon,
      },
      {
        title: 'Distrikter',
        href: '/districts',
        icon: PublicIcon,
      },
      {
        title: 'Aktivitet',
        href: '/activities?page=0&period=0&search=',
        icon: TrackChangesIcon,
      },
    ],
  },
  {
    title: 'Verktøy',
    pages: [
      {
        title: 'Etiketter',
        href: '/labels',
        icon: LabelImportantIcon,
      },
    ],
  },
  {
    title: 'Bruker',
    pages: [
      {
        title: 'Profil',
        href: '/profile',
        icon: AccountCircleIcon,
      },
      {
        title: 'Mine skoler',
        href: '/profile/schools',
        icon: ApartmentIcon,
      },
      {
        title: 'Logg ut',
        href: '/logout',
        icon: ExitToAppIcon,
      },
    ],
  },
]
