import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { gql } from 'apollo-boost'
import { makeStyles } from '@material-ui/styles'
import { useMutation } from '@apollo/react-hooks'
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  colors,
  ListItemIcon,
  MenuItem,
  Divider,
  Typography,
  Link,
} from '@material-ui/core'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import getInitials from 'utils/getInitials'
import MemberForm from 'components/Forms/MemberForm'
import GenericMoreButton from 'components/GenericMoreButton'
import { Link as RouterLink } from '@reach/router'

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'visible',
    marginBottom: theme.spacing(3),
  },
  header: {
    paddingBottom: 0,
  },
  content: {
    paddingTop: 0,
  },
  actions: {
    backgroundColor: colors.grey[50],
  },
  manageButton: {
    width: '100%',
  },
}))

const REMOVE_LEADER_FROM_DISTRICT = gql`
  mutation ($districtID: ID!) {
    removeLeaderFromDistrict(districtID: $districtID) {
      message
    }
  }
`

const UPDATE_MEMBERS = gql`
  mutation ($id: ID!, $users: [ID]!) {
    addLeaderToDistrict(id: $id, users: $users) {
      message
    }
  }
`

const Leader = (props) => {
  const { members, className, addMember, districtID, refetch, ...rest } = props
  const classes = useStyles()
  const [removeLeaderFromDistrict] = useMutation(REMOVE_LEADER_FROM_DISTRICT)
  const [addLeaderToDistrict] = useMutation(UPDATE_MEMBERS)
  const [openEdit, setOpenEdit] = useState(false)

  const handleEditClose = () => {
    setOpenEdit(false)
    refetch()
  }

  const handleRemove = async (id) => {
    try {
      await removeLeaderFromDistrict({
        variables: { districtID: districtID, userID: id },
      })
    } catch {}

    refetch()
  }

  const handleMemberSelect = (users) => {
    return addLeaderToDistrict({ variables: { id: districtID, users: users } })
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title="HK-ansvarlig" />
      <Divider />
      <CardContent className={classes.content}>
        <List>
          {members.map((member) => (
            <ListItem disableGutters key={member.id}>
              <ListItemAvatar>
                <Avatar alt="Author" className={classes.avatar} src={member.profileImage}>
                  {getInitials(member.fullName)}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Link color="inherit" component={RouterLink} to={`/users/${member.slug}`}>
                    {member.fullName}
                  </Link>
                }
                primaryTypographyProps={{ variant: 'h6' }}
                secondary={`${member.email} - ${member.mobile ? member.mobile : ''}`}
              />
              <GenericMoreButton>
                <MenuItem
                  onClick={() => {
                    handleRemove(member.id)
                  }}
                >
                  <ListItemIcon>
                    <RemoveCircleIcon />
                  </ListItemIcon>
                  <ListItemText primary="Fjern" />
                </MenuItem>
              </GenericMoreButton>
            </ListItem>
          ))}
        </List>
      </CardContent>

      {members.length === 0 && (
        <CardActions className={classes.actions}>
          <div className={classes.manageButton}>
            <Typography>Legg til HK-ansvarlig</Typography>
            <MemberForm
              onSubmitSuccess={handleEditClose}
              handleCancel={handleEditClose}
              currentUsers={members}
              handleUserSelect={handleMemberSelect}
            ></MemberForm>
          </div>
        </CardActions>
      )}

      {/* <Dialog
        fullScreen={fullScreen}
        open={openEdit}
        onClose={handleEditClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle disableTypography id="form-dialog-title">
          <Typography variant="h4">Legg til medlem</Typography>
        </DialogTitle>
        <DialogContent dividers>
          <MemberForm
            districtID={districtID}
            onSubmitSuccess={handleEditClose}
            handleCancel={handleEditClose}
          ></MemberForm>
        </DialogContent>
      </Dialog> */}
    </Card>
  )
}

Leader.propTypes = {
  className: PropTypes.string,
  members: PropTypes.array.isRequired,
  districtID: PropTypes.string.isRequired,
  refetch: PropTypes.func,
}

export default Leader
