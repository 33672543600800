import { useQuery } from '@apollo/react-hooks'
import {
  Avatar,
  Box,
  Breadcrumbs,
  colors,
  Divider,
  LinearProgress,
  Link,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Link as RouterLink } from '@reach/router'
import { gql } from 'apollo-boost'
import ErrorMessage from 'components/ErrorMessage'
import Page from 'components/Page'
import React from 'react'
import getInitials from 'utils/getInitials'

const GET_USER = gql`
  query {
    me {
      id
      fullName
      profileImage
    }
  }
`

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  tabs: {
    marginTop: theme.spacing(3),
  },
  divider: {
    backgroundColor: colors.grey[300],
    marginBottom: theme.spacing(3),
  },
  breadcrumb: {
    marginBottom: theme.spacing(2),
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
}))

const Profile = (props) => {
  const classes = useStyles()
  const { children, location } = props
  const { data, loading, error } = useQuery(GET_USER)

  const tabs = [
    { value: 'summary', label: 'Oversikt' },
    { value: 'schools', label: 'Mine skoler' },
  ]

  const pathArray = location.pathname.split('/')
  const lastPartOfPath = pathArray[pathArray.length - 1]

  const currentTab =
    tabs.map((item) => item.value).indexOf(lastPartOfPath) === -1 ? 'summary' : lastPartOfPath

  if (loading) return <LinearProgress color="primary" />
  if (error) return <ErrorMessage>{error.message}</ErrorMessage>

  return (
    <Page className={classes.root} title={`Min profil`}>
      <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
        <Link component={RouterLink} variant="subtitle1" color="textPrimary" to="/">
          Hjem
        </Link>
        <Link component={RouterLink} variant="subtitle1" color="textPrimary" to="/profile">
          Min profil
        </Link>
      </Breadcrumbs>
      <Box display="flex" alignItems="center">
        <Avatar className={classes.avatar} src={data?.me?.profileImage}>
          {getInitials(data?.me?.fullName)}
        </Avatar>
        <Typography component="h1" variant="h3">
          {data?.me?.fullName}
        </Typography>
      </Box>
      <Tabs className={classes.tabs} scrollButtons="auto" value={currentTab} variant="scrollable">
        {tabs.map((tab) => (
          <Tab
            key={tab.value}
            label={tab.label}
            value={tab.value}
            component={RouterLink}
            to={tab.value}
          />
        ))}
      </Tabs>
      <Divider className={classes.divider} />
      {children}
    </Page>
  )
}

export default Profile
