import { useMutation, useQuery } from '@apollo/react-hooks'
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { gql } from 'apollo-boost'
import ContactRole from 'components/ContactRole'
import ErrorMessage from 'components/ErrorMessage'
import PropTypes from 'prop-types'
import { getMainContactFromEnrollments } from 'utils/enrollmentsHelper'
import getIdFromSlug from 'utils/getIDfromSlug'
import getInitials from 'utils/getInitials'
import Description from './Description'
import Responsible from './Responsible'
import SchoolEdit from './SchoolEdit'
import React from 'react'
import SchoolInfo from './SchoolInfo'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
  },
  mainContact: {
    marginBottom: theme.spacing(3),
  },
  header: {
    paddingBottom: 0,
  },
  content: {
    paddingTop: theme.spacing(3),
  },
  cardHeader: {
    alignItems: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    // paddingRight: theme.spacing(3),
    // paddingLeft: theme.spacing(3),
  },
  cardHeaderButton: {
    margin: 0,
  },
}))

export const REMOVE_RESPONSIBLE_FROM_SCHOOL = gql`
  mutation ($id: ID!) {
    removeSchoolResponsible(id: $id) {
      message
    }
  }
`

export const UPDATE_RESPONSIBLE = gql`
  mutation ($id: ID!, $responsible: [ID!]) {
    setSchoolResponsible(id: $id, responsible: $responsible) {
      message
    }
  }
`
const GET_SCHOOL = gql`
  query ($id: ID!) {
    school(id: $id) {
      id
      slug
      status
      NSRId
      name
      types
      email
      orgNr
      active
      kid
      labels {
        id
        title
        slug
      }
      district {
        id
        slug
        title
      }
      responsible {
        id
        slug
        fullName
        email
        role
        profileImage
        additionalUserInfo {
          title
        }
      }
      enrollments {
        id
        status
        project {
          id
          title
          status
        }
        contacts {
          id
          fullName
          email
          phoneNumber
          type
        }
        createdAt
        updatedAt
      }
      details {
        url
        leader
        phone
        mobile
        pupils
        employeesMin
        employeesMax
        visitAddress {
          address
          zipCode
          place
          country
        }
        postAddress {
          address
          zipCode
          place
          country
        }
        county {
          name
        }
      }
      latestContactAt
      latestLabelUpdateAt
      description
      descriptionUpdatedAt
      descriptionUpdatedBy {
        id
        slug
        fullName
      }
    }
  }
`

const Summary = (props) => {
  const { slug } = props

  const id = getIdFromSlug(slug)
  const classes = useStyles()

  const { data, loading, error, refetch } = useQuery(GET_SCHOOL, { variables: { id: id } })

  const [removeSchoolResponsible] = useMutation(REMOVE_RESPONSIBLE_FROM_SCHOOL)
  const [setSchoolResponsible] = useMutation(UPDATE_RESPONSIBLE)

  if (loading) return <CircularProgress color="secondary" />
  if (error) return <ErrorMessage>{error.message}</ErrorMessage>

  const { responsible, id: schoolID, enrollments } = data.school
  const mainContact = getMainContactFromEnrollments(enrollments)

  const handleRemove = async (id) => {
    try {
      await removeSchoolResponsible({
        variables: { id: schoolID },
      })
    } catch {}

    refetch()
  }

  const handleMemberSelect = (users) => {
    return setSchoolResponsible({ variables: { id: schoolID, responsible: users } })
  }

  const handleSuccess = () => {
    refetch()
  }

  return (
    <Grid className={classes.root} container spacing={3}>
      <Grid item lg={7} md={6} xl={3} xs={12}>
        <SchoolInfo refetch={refetch} school={data.school} />
      </Grid>

      <Grid item lg={5} md={6} xl={6} xs={12}>
        <Description school={data.school} refetch={refetch} />
        {mainContact && (
          <Card className={classes.mainContact}>
            <CardHeader
              className={classes.header}
              title="Hovedkontakt fra siste påmelding"
              titleTypographyProps={{
                variant: 'overline',
              }}
            />
            <CardContent className={classes.content}>
              <List>
                <ListItem disableGutters>
                  <ListItemAvatar>
                    <Avatar alt="Author" className={classes.avatar} src={mainContact.profileImage}>
                      {getInitials(mainContact.fullName)}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${mainContact.fullName}`}
                    primaryTypographyProps={{ variant: 'h6' }}
                    secondary={<ContactRole>{mainContact.type}</ContactRole>}
                  />
                  <ListItemText
                    primary={`${mainContact.phoneNumber}`}
                    // primaryTypographyProps={{ variant: 'h6' }}
                    // secondaryTypographyProps={{ variant: 'h6' }}
                    secondary={`${mainContact.email}`}
                  />
                </ListItem>
              </List>
            </CardContent>
          </Card>
        )}
        <Responsible
          members={responsible}
          handleRemove={handleRemove}
          handleMemberSelect={handleMemberSelect}
          handleSuccess={handleSuccess}
        ></Responsible>
        <SchoolEdit school={data.school} />
      </Grid>
      <Grid item lg={5} md={6} xl={3} xs={12}>
        {/* <People people={people} school={id} refetch={refetch}></People> */}
      </Grid>
      <Grid item lg={4} md={6} xl={3} xs={12}></Grid>
      <Grid item lg={4} md={6} xl={3} xs={12}></Grid>
    </Grid>
  )
}

Summary.propTypes = {
  className: PropTypes.string,
}

export default Summary
