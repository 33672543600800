import React from 'react'
import { Card, CardContent, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Page } from 'components'

const useStyles = makeStyles(theme => ({
  root: {
    // height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(6, 2),
  },
  heading: {
    marginBottom: theme.spacing(2),
  },
  intro: {
    marginBottom: theme.spacing(4),
  },
  content: {
    padding: theme.spacing(4),
  },
  card: {
    maxWidth: theme.breakpoints.values.sm,
  },
}))

const EnrollmentSuccess = () => {
  const classes = useStyles()

  return (
    <Page className={classes.root} title="Påmelding">
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <Typography align="center" component="h1" variant="h2" className={classes.heading}>
            Takk!
          </Typography>
          <Typography
            align="center"
            component="p"
            gutterBottom
            variant="h5"
            className={classes.intro}
          >
            Vi har mottatt deres påmelding til Operasjon Dagsverk. Dere vil straks motta en e-post
            fra oss med informasjon for å gjennomføre OD på best mulig måte på deres skole!
          </Typography>
        </CardContent>
      </Card>
    </Page>
  )
}

export default EnrollmentSuccess
