import React from 'react'
import {
  colors,
  TableCell,
  TableRow,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { makeStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'
import FormattedDate from 'components/FormattedDate'
import Label from 'components/Label'

const useStyles = makeStyles((theme) => ({
  cell: {
    whiteSpace: 'nowrap',
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1),
    backgroundColor: colors.purple[400],
  },
  link: {
    textDecoration: 'none',
    color: colors.black,
    '&:hover': {
      textDecoration: 'none',
    },
  },
  menu: {
    border: '0px',
    boxShadow: 'none',
  },
}))

const LabelRow = (props) => {
  const { label, handleUpdate, handleDelete } = props
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [editMode, setEditMode] = React.useState(false)
  const [openConfirm, setOpenConfirm] = React.useState(false)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const onClickUpdate = () => {
    setAnchorEl(null)
    setEditMode(true)
  }

  const onClickDelete = (label) => {
    setOpenConfirm(false)
    setAnchorEl(null)
    handleDelete(label)
  }

  const onConfirmUpdate = async (label) => {
    setEditMode(false)
    await handleUpdate(label)
  }

  const onCancelUpdate = () => {
    setEditMode(false)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <TableRow key={label.slug}>
      <TableCell className={classes.cell}>
        <Label
          key={label.id}
          color={colors.purple[600]}
          model={label}
          editMode={editMode}
          onConfirmUpdate={onConfirmUpdate}
          onCancelUpdate={onCancelUpdate}
        >
          {label.title}
        </Label>
      </TableCell>
      <TableCell className={classes.cell}>
        <FormattedDate showTime={false}>{label.createdAt}</FormattedDate>
      </TableCell>
      <TableCell className={classes.cell}>
        <FormattedDate showTime={false}>{label.updatedAt}</FormattedDate>
      </TableCell>
      <TableCell className={classes.cell}>
        <IconButton onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          className={classes.menu}
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ horizontal: 'center', vertical: 'center' }}
          elevation={1}
          PaperProps={{
            style: {
              width: '8em',
            },
          }}
        >
          <MenuItem
            onClick={() => {
              onClickUpdate()
            }}
          >
            Rediger
          </MenuItem>
          <MenuItem
            onClick={() => {
              setOpenConfirm(true)
            }}
          >
            Slett
          </MenuItem>
        </Menu>
      </TableCell>

      <Dialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Slett etikett?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirm(false)} color="secondary" autoFocus>
            Avbryt
          </Button>
          <Button
            onClick={() => {
              onClickDelete(label)
            }}
            color="primary"
          >
            Fjern
          </Button>
        </DialogActions>
      </Dialog>
    </TableRow>
  )
}

LabelRow.propTypes = {
  label: PropTypes.object.isRequired,
  handleUpdate: PropTypes.func,
  handleDelete: PropTypes.func,
}

export default LabelRow
