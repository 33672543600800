import { useQuery } from '@apollo/react-hooks'
import { Button, Drawer, Typography, CircularProgress } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import { makeStyles } from '@material-ui/styles'
import { gql } from 'apollo-boost'
import clsx from 'clsx'
import EnrollmentStatus from 'components/EnrollmentStatus'
import LabelsInput from 'components/LabelsInput'
import { FieldArray, Formik } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import ErrorMessage from 'components/ErrorMessage'
import { getEnrollmentInputStatuses, getEnrollmentTypes } from 'utils/enrollmentsHelper'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  drawer: {
    width: 420,
    maxWidth: '100%',
  },
  header: {
    padding: theme.spacing(2, 1),
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
  content: {
    padding: theme.spacing(0, 3),
    flexGrow: 1,
  },
  contentSection: {
    padding: theme.spacing(2, 0),
  },
  contentSectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  contentSectionContent: {},
  formGroup: {
    padding: theme.spacing(2, 0),
  },
  field: {
    marginTop: 0,
    marginBottom: 0,
  },
  flexGrow: {
    flexGrow: 1,
  },
  addButton: {
    marginLeft: theme.spacing(1),
  },
  tags: {
    marginTop: theme.spacing(1),
  },
  minAmount: {
    marginRight: theme.spacing(3),
  },
  maxAmount: {
    marginLeft: theme.spacing(3),
  },
  radioGroup: {},
  actions: {
    padding: theme.spacing(3),
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}))

const GET_PROJECTS = gql`
  query {
    projects {
      id
      title
      slug
    }
  }
`

const Filter = (props) => {
  const { open, onClose, onFilter, className, initialValues, onReset, ...rest } = props

  const classes = useStyles()

  const handleClear = () => {
    // setValues({ ...initialValues })
    // onFilter && onFilter(values)
    onReset()
    onClose()
  }

  const { data, loading, error } = useQuery(GET_PROJECTS)

  if (loading) return <CircularProgress />
  if (error) return <ErrorMessage>{error?.message}</ErrorMessage>

  const allProjects = data.projects
  const allStatuses = getEnrollmentInputStatuses()
  const allTypes = getEnrollmentTypes()

  return (
    <Drawer
      anchor="right"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant="temporary"
    >
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, actions) => {
          onFilter && onFilter({ ...values })
          actions.setSubmitting(false)
          onClose()
        }}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit} {...rest} className={clsx(classes.root, className)}>
            <div className={classes.header}>
              <Button onClick={onClose} size="small">
                <CloseIcon className={classes.buttonIcon} />
                Lukk
              </Button>
            </div>
            <div className={classes.content}>
              <div className={classes.contentSection}>
                <div className={classes.contentSectionContent}>
                  <div className={classes.formGroup}>
                    <Typography component="p" gutterBottom variant="overline">
                      Filtrer på prosjekt
                    </Typography>
                    <FieldArray
                      name="projects"
                      render={(arrayHelpers) => (
                        <LabelsInput
                          label="Søk etter prosjekt"
                          tags={props.values.projects}
                          allTags={allProjects}
                          arrayHelpers={arrayHelpers}
                          onAdd={(tag) => arrayHelpers.push(tag)}
                        />
                      )}
                    />
                  </div>
                  <div className={classes.formGroup}>
                    <Typography component="p" gutterBottom variant="overline">
                      Filtrer på status
                    </Typography>

                    <FieldArray
                      name="statuses"
                      render={(arrayHelpers) => (
                        <LabelsInput
                          label="Søk etter status"
                          tags={props.values.statuses}
                          allTags={allStatuses}
                          arrayHelpers={arrayHelpers}
                          onAdd={(tag) => {
                            arrayHelpers.push(tag)
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className={classes.formGroup}>
                    <Typography component="p" gutterBottom variant="overline">
                      Filtrer på type
                    </Typography>

                    <FieldArray
                      name="types"
                      render={(arrayHelpers) => (
                        <LabelsInput
                          label="Søk etter type"
                          tags={props.values.types}
                          allTags={allTypes}
                          arrayHelpers={arrayHelpers}
                          onAdd={(tag) => arrayHelpers.push(tag)}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.actions}>
              <Button fullWidth onClick={handleClear} variant="contained">
                <DeleteIcon className={classes.buttonIcon} />
                Tilbakestill
              </Button>
              <Button color="primary" fullWidth type="submit" variant="contained">
                Bruk filtre
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </Drawer>
  )
}

Filter.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  onFilter: PropTypes.func,
  open: PropTypes.bool.isRequired,
}

export default Filter
