import React from 'react'
import { Router, Redirect } from '@reach/router'
import { ThemeProvider } from '@material-ui/styles'
import { StateProvider } from 'components/State'
import theme from './theme'
import DashboardLayout from './layouts/Dashboard'
// Views
import Authenticated from './components/Authenticated'
import AuthCallback from './views/AuthCallback'
import Login from './views/Login'
import Logout from './views/Logout'
import Schools from './views/Schools'
import School from './views/School'
import SchoolSummary from './views/School/Summary'
import SchoolEnrollments from './views/School/Enrollments'
import SchoolLog from './views/School/Log'
import Contacts from './views/Contacts'
import Contact from './views/Contact'
import ContactSummary from './views/Contact/Summary'
import ContactEdit from './views/Contact/Edit'
import ContactEnrollments from './views/Contact/Enrollments'
import Enrollment from './views/Enrollment'
import EnrollmentSummary from './views/Enrollment/Summary'
import EnrollmentEdit from './views/Enrollment/Edit'
import Enrollments from './views/Enrollments'
import District from './views/District'
import DistrictSummary from './views/District/Summary'
import Districts from './views/Districts'
import DistrictSchools from './views/District/DistrictSchools'
import DistrictEnrollments from './views/District/DistrictEnrollments'
import EnrollmentSignup from './views/EnrollmentSignup'
import EnrollmentSuccess from './views/EnrollmentSuccess'
import Users from './views/Users'
import User from './views/User'
import UserSummary from './views/User/Summary'
import UserSchools from './views/User/Schools'
import UserActivity from './views/User/Activity'
import Profile from './views/Profile'
import ProfileSummary from './views/Profile/Summary'
import ProfileSchools from './views/Profile/Schools'
import Dashboard from './views/Dashboard'
import Activities from './views/Activities'
import Labels from './views/Labels'

function App() {
  const initialState = {
    searchString: '',
    rowsPerPage: 25,
    page: 0,
  }

  const reducer = (state, action) => {
    switch (action.type) {
      case 'SET_ROWS_PER_PAGE':
        return {
          ...state,
          rowsPerPage: action.value,
        }

      default:
        return state
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <StateProvider initialState={initialState} reducer={reducer}>
        <Router>
          <Authenticated as={DashboardLayout} path="/">
            <Redirect from="/" to="/dashboard" noThrow />
            <Dashboard path="/dashboard" />
            <Enrollments path="/enrollments" />
            <Enrollment path="/enrollments/:slug">
              <EnrollmentSummary path="/" />
              <EnrollmentEdit path="/edit" />
            </Enrollment>
            <Schools path="/schools" />
            <School path="/schools/:slug">
              <SchoolSummary path="/"></SchoolSummary>
              <SchoolSummary path="summary"></SchoolSummary>
              <SchoolEnrollments path="enrollments"></SchoolEnrollments>
              <SchoolLog path="logs"></SchoolLog>
            </School>
            <Contacts path="/contacts" />
            <Contact path="/contacts/:slug">
              <ContactSummary path="/" />
              <ContactSummary path="/summary" />
              <ContactEnrollments path="/enrollments" />
              <ContactEdit path="/edit" />
            </Contact>
            <Users path="/users" />
            <User path="/users/:slug">
              <UserSummary path="/" />
              <UserSummary path="/summary" />
              <UserSchools path="/schools" />
              <UserActivity path="/activity" />
            </User>
            <Districts path="/districts" />
            <District path="/districts/:slug">
              <DistrictSummary path="/" />
              <DistrictSummary path="/summary" />
              <DistrictSchools path="schools" />
              <DistrictEnrollments path="enrollments" />
            </District>
            <Activities path="/activities" />
            <Labels path="/labels" />
            <Profile path="/profile">
              <ProfileSummary path="/" />
              <ProfileSummary path="/summary" />
              <ProfileSchools path="/schools" />
            </Profile>
          </Authenticated>
          <AuthCallback path="/auth0_callback" />
          <Login path="/login" />
          <Logout path="/logout" />
          <Redirect from="/signup" to="/delta" noThrow />
          <EnrollmentSignup path="/delta" />
          <EnrollmentSuccess path="/success" />
        </Router>
      </StateProvider>
    </ThemeProvider>
  )
}

export default App
