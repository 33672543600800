import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardHeader,
  CardContent,
  List,
  ListItem,
  ListSubheader,
  Link,
  Typography,
  Divider,
} from '@material-ui/core'
import { Link as RouterLink } from '@reach/router'
import SchoolType from 'components/SchoolType'

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    paddingTop: 0,
  },
  actions: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& > * + *': {
      marginLeft: 0,
    },
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
  listItem: {
    padding: theme.spacing(2, 0),
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  subHeader: {
    marginTop: theme.spacing(1),
  },
}))

const SchoolInfo = (props) => {
  const { school, className, refetch, ...rest } = props

  const classes = useStyles()

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title="Skoleinfo" />
      <Divider />
      <CardContent className={classes.content}>
        <List>
          <ListItem className={classes.listItem} disableGutters divider>
            <Typography variant="subtitle2">E-post</Typography>
            <Link
              color="textPrimary"
              variant="h6"
              underline="always"
              href={`mailto:${school.email}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {school && school.email}
            </Link>
          </ListItem>

          {school.details.url && (
            <ListItem className={classes.listItem} disableGutters divider>
              <Typography variant="subtitle2">Nettside</Typography>
              <Link
                variant="h6"
                color="textPrimary"
                underline="always"
                href={`https://${school.details.url}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {school.details.url}
              </Link>
            </ListItem>
          )}

          {school.details.leader && (
            <ListItem className={classes.listItem} disableGutters divider>
              <Typography variant="subtitle2">Skolens Leder</Typography>
              <Typography variant="h6">{school.details.leader}</Typography>
            </ListItem>
          )}

          {school.details.mobile && (
            <ListItem className={classes.listItem} disableGutters divider>
              <Typography variant="subtitle2">Mobilnummer</Typography>
              <Link
                variant="h6"
                color="textPrimary"
                underline="always"
                href={`tel:${school.details.mobile}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {school.details.mobile}
              </Link>
            </ListItem>
          )}

          {school.details.phone && (
            <ListItem className={classes.listItem} disableGutters divider>
              <Typography variant="subtitle2">Telefonnummer</Typography>
              <Link
                variant="h6"
                color="textPrimary"
                underline="always"
                href={`tel:${school.details.phone}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {school.details.phone}
              </Link>
            </ListItem>
          )}

          {school.details.pupils && (
            <ListItem className={classes.listItem} disableGutters divider>
              <Typography variant="subtitle2">Antall elever</Typography>
              <Typography variant="h6">{school.details.pupils}</Typography>
            </ListItem>
          )}

          {school.details.employeesMin && school.details.employeesMax && (
            <ListItem className={classes.listItem} disableGutters divider>
              <Typography variant="subtitle2">Antall ansatte</Typography>
              <Typography variant="h6">
                {school.details.employeesMin}-{school.details.employeesMax}
              </Typography>
            </ListItem>
          )}

          {school.orgNr && (
            <ListItem className={classes.listItem} disableGutters divider>
              <Typography variant="subtitle2">Org. nummer</Typography>
              <Link
                variant="h6"
                color="textPrimary"
                underline="always"
                href={`https://w2.brreg.no/enhet/sok/detalj.jsp?orgnr=${school.orgNr}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {school.orgNr}
              </Link>
            </ListItem>
          )}

          {school.types.length !== 0 && (
            <>
              <ListItem className={classes.listItem} disableGutters divider>
                <Typography variant="subtitle2">Skolenivå</Typography>
                {school.types.map((type) => (
                  <Typography variant="h6">
                    <SchoolType>{type}</SchoolType>
                  </Typography>
                ))}
              </ListItem>
            </>
          )}

          {school.details.county && (
            <>
              <ListItem className={classes.listItem} disableGutters divider>
                <Typography variant="subtitle2">Fylke</Typography>
                <Typography variant="h6">{school.details.county.name}</Typography>
              </ListItem>
            </>
          )}

          {school.district && (
            <>
              <ListItem className={classes.listItem} disableGutters divider>
                <Typography variant="subtitle2">Distrikt</Typography>
                <Link
                  variant="h6"
                  color="textPrimary"
                  underline="always"
                  component={RouterLink}
                  className={classes.link}
                  to={`/districts/${school?.district?.slug}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {school?.district?.title}
                </Link>
              </ListItem>
            </>
          )}

          {school.kid && (
            <>
              <ListItem className={classes.listItem} disableGutters divider>
                <Typography variant="subtitle2">KID-nummer</Typography>
                <Typography variant="h6">{school.kid}</Typography>
              </ListItem>
            </>
          )}

          {school.details.visitAddress && (
            <>
              <ListSubheader className={classes.subHeader} disableSticky disableGutters>
                Besøksadresse
              </ListSubheader>
              <ListItem className={classes.listItem} disableGutters divider>
                <Typography variant="subtitle2">Gate</Typography>
                <Link
                  variant="h6"
                  color="textPrimary"
                  underline="always"
                  href={`https://www.google.com/maps/search/?api=1&query=${encodeURI(
                    `${school.details.visitAddress.address},${school.details.visitAddress.zipCode},${school.details.visitAddress.place}`
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {school.details.visitAddress.address}
                </Link>
              </ListItem>
              <ListItem className={classes.listItem} disableGutters divider>
                <Typography variant="subtitle2">Postnummer</Typography>
                <Typography variant="h6">{school.details.visitAddress.zipCode}</Typography>
              </ListItem>
              <ListItem className={classes.listItem} disableGutters divider>
                <Typography variant="subtitle2">Sted</Typography>
                <Typography variant="h6">{school.details.visitAddress.place}</Typography>
              </ListItem>
              <ListItem className={classes.listItem} disableGutters divider>
                <Typography variant="subtitle2">Land</Typography>
                <Typography variant="h6">{school.details.visitAddress.country}</Typography>
              </ListItem>
            </>
          )}

          {school.details.postAddress && (
            <>
              <ListSubheader className={classes.subHeader} disableSticky disableGutters>
                Postadresse
              </ListSubheader>
              <ListItem className={classes.listItem} disableGutters divider>
                <Typography variant="subtitle2">Gate</Typography>
                <Typography variant="h6">{school.details.postAddress.address}</Typography>
              </ListItem>
              <ListItem className={classes.listItem} disableGutters divider>
                <Typography variant="subtitle2">Postnummer</Typography>
                <Typography variant="h6">{school.details.postAddress.zipCode}</Typography>
              </ListItem>
              <ListItem className={classes.listItem} disableGutters divider>
                <Typography variant="subtitle2">Sted</Typography>
                <Typography variant="h6">{school.details.postAddress.place}</Typography>
              </ListItem>
              <ListItem className={classes.listItem} disableGutters divider>
                <Typography variant="subtitle2">Land</Typography>
                <Typography variant="h6">{school.details.postAddress.country}</Typography>
              </ListItem>
            </>
          )}
        </List>
      </CardContent>
    </Card>
  )
}

SchoolInfo.propTypes = {
  className: PropTypes.string,
  school: PropTypes.object.isRequired,
}

export default SchoolInfo
