import React from 'react'
import PropTypes from 'prop-types'
import constants from '../../utils/constants'

const ContactRole = ({ children }) => {
  if (children === constants.contactTypes.teacher) return <>Lærer</>
  if (children === constants.contactTypes.student) return <>Elev</>
  return <>Ukjent rolle</>
}

ContactRole.propTypes = {
  children: PropTypes.string.isRequired,
}

export default ContactRole
