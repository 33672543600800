import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardHeader,
  CardContent,
  List,
  ListItem,
  Link,
  Typography,
  Divider,
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(0),
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(0),
      marginLeft: theme.spacing(-1),
      marginRight: theme.spacing(-1),
    },
  },
  content: {
    paddingTop: 0,
  },
  listItem: {
    padding: theme.spacing(2, 0),
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
}))

const LogSchoolInfo = (props) => {
  const { school, className, ...rest } = props

  const classes = useStyles()

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title="Skoleinfo" />
      <Divider />
      <CardContent className={classes.content}>
        <List>
          <ListItem className={classes.listItem} disableGutters divider>
            <Typography variant="subtitle2">E-post</Typography>
            <Link
              color="textPrimary"
              variant="h6"
              underline="always"
              href={`mailto:${school.email}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {school && school.email}
            </Link>
          </ListItem>

          {school.details.mobile && (
            <ListItem className={classes.listItem} disableGutters divider>
              <Typography variant="subtitle2">Mobilnummer</Typography>
              <Link
                variant="h6"
                color="textPrimary"
                underline="always"
                href={`tel:${school.details.mobile}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {school.details.mobile}
              </Link>
            </ListItem>
          )}

          {school.details.phone && (
            <ListItem className={classes.listItem} disableGutters divider>
              <Typography variant="subtitle2">Telefonnummer</Typography>
              <Link
                variant="h6"
                color="textPrimary"
                underline="always"
                href={`tel:${school.details.phone}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {school.details.phone}
              </Link>
            </ListItem>
          )}
        </List>
      </CardContent>
    </Card>
  )
}

LogSchoolInfo.propTypes = {
  className: PropTypes.string,
  school: PropTypes.object.isRequired,
}

export default LogSchoolInfo
