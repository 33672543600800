import React from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { Grid, CircularProgress } from '@material-ui/core'
import Info from './Info'
import Contacts from './Contacts'
import Payments from './Payments'
import getIdFromSlug from '../../../utils/getIDfromSlug'
import ErrorMessage from '../../../components/ErrorMessage'

const GET_ENROLLMENT = gql`
  query ($id: ID!) {
    enrollment(id: $id) {
      id
      status
      types
      project {
        id
        title
      }
      payment {
        id
        amount
        paymentMethod
        pupils
      }
      estStudentCount
      estTeacherCount
      comments
      school {
        id
        name
        slug
      }
      contacts {
        id
        slug
        firstName
        lastName
        fullName
        phoneNumber
        email
        type
      }
    }
  }
`

const Summary = (props) => {
  const { slug } = props
  const id = getIdFromSlug(slug)
  const { data, loading, error, refetch } = useQuery(GET_ENROLLMENT, {
    variables: { id: id },
  })

  if (loading) return <CircularProgress color="secondary" />
  if (error) return <ErrorMessage>{error.message}</ErrorMessage>

  return (
    <Grid container spacing={3}>
      <Grid item lg={7} md={6} xl={3} xs={12}>
        <Payments enrollment={data.enrollment}></Payments>
        <Info enrollment={data.enrollment} refetch={refetch}></Info>
      </Grid>
      <Grid item lg={5} md={6} xl={6} xs={12}>
        <Contacts
          contacts={data.enrollment.contacts}
          enrollmentID={data.enrollment.id}
          refetch={refetch}
        ></Contacts>
      </Grid>
    </Grid>
  )
}

Summary.propTypes = {
  data: PropTypes.object,
  refetch: PropTypes.func,
}

export default Summary
