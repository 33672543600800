import React from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { Grid, CircularProgress } from '@material-ui/core'
import Info from './Info'
import Members from './Members'
import Leader from './Leader'
import getIdFromSlug from '../../../utils/getIDfromSlug'
import ErrorMessage from '../../../components/ErrorMessage'

const GET_DISTRICT = gql`
  query ($id: ID!) {
    district(id: $id) {
      id
      title
      email
      members {
        id
        fullName
        email
        nickName
        mobile
        role
        profileImage
        slug
      }
      leaders {
        id
        fullName
        email
        nickName
        mobile
        role
        profileImage
        slug
      }
    }
  }
`

const Summary = (props) => {
  const { slug } = props
  const id = getIdFromSlug(slug)
  const { data, loading, error, refetch } = useQuery(GET_DISTRICT, { variables: { id: id } })

  if (loading) return <CircularProgress color="secondary" />
  if (error) return <ErrorMessage>{error.message}</ErrorMessage>

  return (
    <Grid container spacing={3}>
      <Grid item lg={7} md={6} xl={3} xs={12}>
        <Info district={data.district} refetch={refetch}></Info>
      </Grid>
      <Grid item lg={5} md={6} xl={6} xs={12}>
        <Leader
          members={data.district.leaders}
          districtID={data.district.id}
          refetch={refetch}
        ></Leader>
        <Members
          members={data.district.members}
          districtID={data.district.id}
          refetch={refetch}
        ></Members>
      </Grid>
    </Grid>
  )
}

Summary.propTypes = {
  data: PropTypes.object,
  refetch: PropTypes.func,
}

export default Summary
